(function () {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('thumbnail', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{aid}/productThumbnail',
                data: {
                    authorities: [],
                    pageTitle: 'product.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/template/template.html',
                        controller: 'TemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                    // entity: ['$stateParams', 'Product', function ($stateParams, Product) {
                    //     return Product.get({id: $stateParams.pid}).$promise;
                    // }]
                }
            })
            .state('thumbnail2', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{aid}/articleDesign?auth&ticket&variableData&returnurl&returnurl2&returnurl2Text',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: '',
                        controller: 'Template2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })
            .state('preview', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{aid}/articlePreview?auth&ticket',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: '',
                        controller: 'PreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })
            .state('preview2', {
                parent: 'app',
                url: '/{storeId}/{orderArticleId}/articlePreview2?auth&ticket',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: '',
                        controller: 'PreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })
            .state('share', {
                parent: 'app',
                url: '/{storeId}/{pid}/{vid}/{aid}/articleShare?auth&ticket',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: '',
                        controller: 'PreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }]
                }
            })
            //手机传图回调功能
            .state('galleryUploadPage0', {
                parent: 'app',
                url: '/{storeId}/{fid}/gallery/{aid}?auth&ticket',
                data: {
                    authorities: [],
                    pageTitle: 'home.title'
                },
                views: {
                    'content@': {
                        templateUrl: '',
                        controller: 'GalleryUpload0PageController',
                        controllerAs: 'vm'
                    }
                }
            })
            //web端调用传照片 传图中
            .state('galleryUploadPage', {
                parent: 'app',
                url: '/{storeId}/{fid}/gallery/uploading/{aid}',
                data: {
                    authorities: [],
                    pageTitle: 'home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/gallery/gallery-upload.html',
                        controller: 'GalleryUploadPageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editor');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('common');
                        return $translate.refresh();
                    }],
                }
            })
            ;
    }

})();
