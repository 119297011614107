(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorPhotoAlbumController', EditorPhotoAlbumController)
        .filter('reverse', function() {
            return function(items) {
                if(items){
                    return items.slice().reverse();
                }
            };
        });
    EditorPhotoAlbumController.$inject = ['$scope', 'FontManagement', 'MessageService', '$stateParams', '$q', 'ModulesService','ListService',
    'ThemeClassify', 'ProductXml', 'Store', 'PlatformWechatSetting','Qinius','Folder','$uibModal', 'Gallery', '$localStorage', 'Material', 'ClipArt', 'FontXmlsManger','Backgrounds','Product',
    'ItemValue', 'Convert', '$http', '$timeout', 'FontXmlsFileServes', 'Article', 'entity', '$state', 'SweetAlert', 'Theme','CommonService', 'OrderArticle', 'StoreWorkEditConfig', 'UidService',
        'FrameConfigs','Upload','$rootScope','Algorithm','$translate', 'Principal', '$cookies', 'translationStorageProvider', 'IMAGE_SUPPORTED_MIME','StoreStorages','$websocket'];

    function EditorPhotoAlbumController( $scope, FontManagement, MessageService, $stateParams, $q, ModulesService,ListService,
        ThemeClassify, ProductXml, Store, PlatformWechatSetting,Qinius,Folder,$uibModal, Gallery, $localStorage, Material, ClipArt, FontXmlsManger,Backgrounds,Product,
      ItemValue, Convert, $http, $timeout, FontXmlsFileServes, Article, entity, $state, SweetAlert, Theme,CommonService,OrderArticle, StoreWorkEditConfig, UidService,
                                         FrameConfigs,Upload,$rootScope,Algorithm,$translate, Principal, $cookies, translationStorageProvider, IMAGE_SUPPORTED_MIME,StoreStorages, $websocket) {
        var vm = this;
        if($stateParams.authorization){
            $localStorage.authenticationToken = $stateParams.authorization;
        };
        $(".navbar").hide();
        $(".save-page").removeClass('hide1');
        $(".wrap").hide();
        var variableDatas = JSON.parse(localStorage.getItem('variableData'));
        $scope.variableTextDatas = vm.variableTextDatas = [];
        $scope.variableBoxDatas=[];
        vm.leftShow = true;//菜单栏隐藏
        vm.rightShow = true;//菜单栏隐藏
        vm.uploadFailedList = [];//上传图片失败的列表
        vm.errorAlertPopup = false;//显示左下角蓝色弹窗
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.articleId = null;//当orderArticle 时有值；
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.watchSure = false;
        vm.general = false;
        vm.supportedMimeTypes = IMAGE_SUPPORTED_MIME;
        if ($localStorage.user != null) {
            vm.userId = $localStorage.user.id
        }
        var arr = localStorage.getItem('save-article');
        if (arr && vm.pid) {
            $state.go("editorPhotoAlbumEdit", {storeId: vm.storeId, aid: arr});
        }
        localStorage.removeItem('save-article');
        $scope.domains = Qinius.getDomain();
        vm.tokenTime = 360000;
        Qinius.get({}, function (res) {
            vm.token = res.token;
            vm.tokenTime = parseInt((res.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.store = Store.get({id: vm.storeId});
        vm.photoSelectAll = [];
        vm.elementOn = {
            index:0,
            pageIndex:0,
            name:'',
            item:null
        };
        vm.levelAll=[];
        vm.articleChanged = true;
        vm.maskType='recommend';

        //测试时使用
        // $localStorage.lagnuages = ['zh-cn', 'en'];
        $scope.showLagnuages = function(){
            vm.showLagnuages = !vm.showLagnuages;
            if (vm.showLagnuages) {
                $(".lang-icn").css({'transform': 'rotate(270deg)'});
            }else{
                $(".lang-icn").css({'transform': 'rotate(90deg)'});
            }
        }
        lagnuageInit();
        function lagnuageInit() {
            vm.lang = $cookies.getObject("NG_TRANSLATE_LANG_KEY");
            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var result = translationStorageProvider.getLanguageType(lang);
                    if (!!result) {
                        if (vm.lang == result.value) {
                            vm.langKey = result.key;
                            result.selected = true;
                        }
                        vm.lagnuages.push(result);
                    }
                }
            }
        }

        $scope.changeLagnuage = function(lang){
            $timeout(function () {
                if (lang == null || lang.key == vm.langKey ) {
                    return;
                }
                angular.forEach(vm.lagnuages, function(item){
                    item.selected =false;
                });
                lang.selected = true;
                vm.langKey = lang.key;
                $translate.use(lang.value).then(function(){
                    vm.showLagnuages =false;
                    lagnuageInit();
                    navSelect(vm.nav);
                    getProductXmlLine();
                },100)
            })
        }

        $scope.getIndex = function(page){
            var _index = 0;
            for(var i=0; i<vm.pageData.length; i++){
                var _page = vm.pageData[i];
                if(page.config.partid == _page.config.partid){
                    _index+=1;
                    if(_page.uuid == page.uuid){
                        break
                    }
                }
            }
            if (_index < 10) {
                return "0"+ _index;
            }
            return _index;
        }
        $scope.getIndex1 = function(index){
            if(vm.pageData && vm.pageData.length>0){
                var _index = 0;
                for(var i=0; i<vm.pageData.length; i++){
                    var _page = vm.pageData[i];
                    if(_page && _page.config && _page.config.partid == 'P'){
                        _index+=1;
                        if(index == i){
                            break
                        }
                    }
                }
                if (_index < 10) {
                    return "0"+ _index;
                }
                return _index;
            }
        }
        //部件名
        $scope.partNamt = function (partid) {
            var partName = "";
            for(var i=0; i<$scope.template.config.parts.list.length; i++){
                if($scope.template.config.parts.list[i].id == partid){
                    partName = $scope.template.config.parts.list[i].name;
                }
            }
            return partName
        }
        /***************页面处理************start*****************/
        $q.all([$scope.domains.$promise, vm.store.$promise, vm.platformSetting.$promise]).then(function () {
            // token过期
            vm.platformSetting = vm.platformSetting.data;
            setTimeout(function fn() {
                Qinius.get(function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            vm.store = vm.store.data;
            $(".navbar").hide();
            $(".save-page").removeClass('hide1');
            $(".wrap").hide();
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.logo = vm.store.menuLogo ? $scope.domain + vm.store.menuLogo : 'content/images/momentsgo.png';

            //获取字体
            FontManagement.getAll({family: ""}, function (res) {
                vm.fontList = angular.copy(res);
                vm.fontList1 = angular.copy(res);
                $scope.item = vm.fontList[0];
                vm.defaultFont = res[0];
                res.forEach(function (item) {
                    if(item.defaultShow){
                        vm.defaultFont = item;
                    }
                })
            });
            vm.windowDpi = js_getDPI();
            //获取数据
            init();
            //获取自定义颜色
            ModulesService.getToneSettings(vm.storeId,function (res) {
                vm.toneSettings = res;
            });
            vm.generalMask=[{
                cancelled: false,
                id: 646,
                resource:{
                    identifier: $scope.domain + "clipArt-d1a8bc1c-53f0-4aa5-cb2b-33ea628866f9",
                },
                identifier: $scope.domain + "clipArt-d1a8bc1c-53f0-4aa5-cb2b-33ea628866f9",
                name: "圆形蒙版",
                opened: true,
                provider: "qiniu",
                themeClassifyId: '',
                themeClassifyName: "",
                type: "masking",
                type1:'round'
            },{
                cancelled: false,
                id: 646,
                resource:{
                    identifier: $scope.domain + "material-9c6fd5de-f1b0-48c5-996e-2eb5758f19cc",
                },
                identifier: $scope.domain + "material-9c6fd5de-f1b0-48c5-996e-2eb5758f19cc",
                name: "方形蒙版",
                opened: true,
                provider: "qiniu",
                themeClassifyId: '',
                themeClassifyName: "",
                type: "masking",
                type1: "square",
            }]
        }).catch(function(error){
            console.log(error,"err")
        });

        /***********************  编辑区域  ****************************/
        var productId = null;
        function init(){
            vm.threeLoading = true;
            if(vm.pid){
                Article.get({id: $stateParams.aid}, function (resp) {
                    if (resp.status == 200) {
                        vm.article = resp.data;
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }

                        websocket();
                        getProduct(function () {
                            CommonService.setDocumentTitle(vm.article.name+'-'+$translate.instant("title"));
                            ItemValue.get({id: vm.vid}, function (res) {
                                vm.bindingtype = res.data.bindingType;
                                $scope.status = localStorage.getItem('Production');
                                vm.productXmlLine = angular.copy(res.data.productXmlLines[0]);
                                angular.forEach(res.data.productXmlLines,function (line) {
                                    if(line.id==vm.xmlId){
                                        vm.productXmlLine = angular.copy(line);
                                    }
                                });
                                vm.article.modelBlendentUuid = vm.productXmlLine.modelBlendentUuid;
                                vm.article.cover = vm.productXmlLine.coverImg;
                                vm.article.themeClassifyId = vm.productXmlLine.productXmlThemeId;
                                vm.article.themeClassifyName = vm.productXmlLine.productXmlThemeName;
                                vm.article.productXmlId = vm.productXmlLine.productXmlId;
                                vm.article.productXmlName = vm.productXmlLine.productXmlName;
                                vm.article.musicIdentify = vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.data.musicIdentify;
                                vm.article.editorType= res.data.productEditorType;
                                vm.article.itemValueId= res.data.itemValueId || vm.vid;
                                if(!res.data.productBokehType){
                                    vm.article.bokehtype='None';
                                }else{
                                    vm.article.bokehtype=res.data.productBokehType;
                                }
                                var result = ListService.inList(vm.article, variableDatas, ["productXmlId"]);
                                if(result&&result.data&&result.data.length){
                                    angular.forEach(result.data,function (va,i) {
                                        if(va.type=='text'){
                                            $scope.variableTextDatas.push(va);
                                            vm.variableTextDatas.push(va);
                                        }
                                        if(va.type=='box'){
                                            $scope.variableBoxDatas.push(va)
                                        }
                                    })
                                }

                                Convert.xmlToDocument2({
                                    identifier: vm.productXmlLine?vm.productXmlLine.productXmlIdentifier:res.productXmlIdentifier,
                                    provider: "qiniu",
                                    editorType:vm.article.editorType,
                                    patternType:vm.product.patternType,
                                }, function (resp) {
                                    setUuid(resp);
                                    vm.counts = 0;
                                    $scope.pages = resp;
                                    if($scope.pages.config.productType == 'CALENDAR'&&$scope.pages.config.dynamic){
                                        vm.isCalendar = true;
                                    }
                                    productId = vm.pid;
                                    vm.pageSize = [];
                                    resp.pages.page.forEach(function(page){
                                        if(page.levels && page.levels.level && page.levels.level.length>0){
                                            page.levels.level.sort(compare('seq', true));
                                        }
                                        var o = {
                                            width:page.mediabox.width,
                                            height:page.mediabox.height
                                        }
                                        vm.pageSize.push(o);
                                    })
                                    angular.forEach(resp.pages.page, function (page, i) {
                                        angular.forEach(page.levels.level,function (level) {
                                            if(level.imagebox&&level.imagebox.geometry){
                                                vm.counts++;
                                            }
                                        })
                                    });
                                    abook(resp,function () {
                                        yearInfo()
                                    });
                                    templateInit();
                                    loadAll('isNull');
                                    //获取照片文件夹
                                    loadFolders();
                                });
                            },function (error) {
                                if(error.data.status === 403){//未授权
                                    $state.go('404',{storeId:vm.storeId});
                                }
                            });
                        })
                    }
                },function(error){
                    if(error.status === 403){//token失效
                        return $state.go('404',{storeId:vm.storeId});
                    }
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                })
            }else {
                vm.counts = 0;
                if($stateParams.aid){
                    Article.getByUser({id: $stateParams.aid}, function (res) {
                        vm.article = res.data;
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }

                        websocket();
                        getProductXmlLine(function () {
                            CommonService.setDocumentTitle(vm.article.name+'-'+$translate.instant("title"));
                            productId = res.data.productId;
                            Convert.xmlToDocument2({
                                identifier: res.data.fileName,
                                provider: 'qiniu',
                                editorType:vm.article.editorType,
                                patternType:vm.product.patternType,
                            }, function (resp) {
                                setUuid(resp);
                                vm.pageSize = [];
                                resp.pages.page.forEach(function(page){
                                    if(page.levels && page.levels.level && page.levels.level.length>0){
                                        page.levels.level.sort(compare('seq', true));
                                    }
                                    var o = {
                                        width:page.mediabox.width,
                                        height:page.mediabox.height
                                    }
                                    vm.pageSize.push(o);
                                })
                                angular.forEach(resp.pages.page, function (page, i) {
                                    angular.forEach(page.levels.level, function (level) {
                                        if (level.imagebox && level.imagebox.geometry) {
                                            vm.counts++;
                                        }
                                    })
                                });
                                abook(resp);
                                loadAll('isNull');
                                //获取照片文件夹
                                loadFolders();
                            },function(error){
                                if(error.data.status === 403){//未授权
                                    $state.go('404',{storeId:vm.storeId});
                                }
                            });
                            templateInit();
                        });
                    },function(error){
                        if(error.status === 403){//token失效
                            return $state.go('404',{storeId:vm.storeId});
                        }
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })
                }else if($stateParams.orderArticleId){
                    OrderArticle.getByUser({id: $stateParams.orderArticleId}, function (res) {
                        vm.article = res.data;
                        if(vm.article.invalid){//作品失效
                            $state.go('articlesFailure',{storeId:vm.storeId});
                            return
                        }

                        websocket();
                        getProductXmlLine(function () {
                            CommonService.setDocumentTitle(vm.article.name+'-'+$translate.instant("title"));
                            productId = res.data.productId;
                            vm.articleId = res.data.articleId;
                            loadAll('isNull');
                            //获取照片文件夹
                            loadFolders();
                            Convert.xmlToDocument2({
                                identifier: res.data.fileName,
                                provider: 'qiniu',
                                editorType:vm.article.editorType,
                                patternType:vm.product.patternType,
                            }, function (resp) {
                                setUuid(resp);
                                vm.pageSize = [];
                                resp.pages.page.forEach(function(page){
                                    if(page.levels && page.levels.level && page.levels.level.length>0){
                                        page.levels.level.sort(compare('seq', true));
                                    }
                                    var o = {
                                        width:page.mediabox.width,
                                        height:page.mediabox.height
                                    }
                                    vm.pageSize.push(o);
                                })
                                angular.forEach(resp.pages.page, function (page, i) {
                                    angular.forEach(page.levels.level, function (level) {
                                        if (level.imagebox && level.imagebox.geometry) {
                                            vm.counts++;
                                        }
                                    })
                                });
                                abook(resp);
                            },function (error) {
                                if(error.data.status === 403){//未授权
                                    $state.go('404',{storeId:vm.storeId});
                                }
                            });
                            templateInit();
                        });
                    },function(error){
                        if(error.status === 403){//token失效
                            return $state.go('404',{storeId:vm.storeId});
                        }
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })
                }
            }
        }
        function setUuid(document) {
            if(document && document.backupPages && document.backupPages.list){
                document.backupPages.list.forEach(function (page) {
                    page.pageUuid = getUuid();
                })
            }
            if(document && document.pages && document.pages.page){
                document.pages.page.forEach(function (page) {
                    page.pageUuid = getUuid();
                })
            }
        }
        function demoPage() {
            $scope.template.config = {
                "innerOffset":1,
                "parts":{
                    "list":[
                        {
                            "counter":1,
                            "id":"B1",
                            "mediabox":{
                                "height":200,
                                "invisible":false,
                                "width":200,
                                "x":0,
                                "y":0
                            },
                            "name":"封面",
                            "required":false,
                            "seq":3,
                            "spread": false,
                            "custom": false
                        },
                        {
                            "counter":10,
                            "id":"P",
                            "name":"内页",
                            "required":false,
                            "seq":5,
                            "spread": false,
                            "custom": false
                        },
                        {
                            "counter":1,
                            "id":"B2",
                            "name":"封底",
                            "required":false,
                            "seq":9,
                            "spread": false,
                            "custom": false
                        }
                    ]
                },
                "productType":"BOOK",
                "lastCustomSeq": 10
            };
            $scope.template.pages.page.forEach(function(page,index){
                page.config={
                    "dynamic":false,
                    "monthIndex":0,
                    "monthMultiple":false,
                    "partid":'P'
                }
                if(index == 0){
                    page.config.partid = 'B1';
                }
                if(index == $scope.template.pages.page.length-1){
                    page.config.partid = 'B2';
                }
            })
        }
        function getProduct(callback) {
            Product.get({id: vm.article.productId, cancelled: false}, function (res) {
                vm.product = res.data;
                console.log(vm.product)
                vm.productTitle = res.data.name;
                if(callback){callback()}
            })
        }
        function getProductXmlLine(callback){
            ItemValue.get({id: vm.article.itemValueId}, function (res) {
                vm.bindingtype = res.data.bindingType;
                angular.forEach(res.data.productXmlLines, function (line) {
                    if (line.productXmlId == vm.article.productXmlId) {
                        vm.productXmlLine = angular.copy(line);
                    }
                });
                getProduct(function () {
                    if(callback){callback()}
                });
            })
            fontuuid();
        }
        vm.windowH = window.innerHeight - 92;
        vm.windowW = window.innerWidth - 460;
        vm.windowW1 = 150;//设置右侧缩略图大小
        vm.cookies=[];
        vm.cookieIndex = 0;
        vm.pageNumber = 0;
        vm.picLayoutXmlsList = [];
        function abook(obj,callback) {
            vm.article.xml = obj;
            console.log(vm.article.xml)
            if(vm.article.xml.piclayouts&&vm.article.xml.piclayouts.piclayout){
                vm.picLayoutXmlsList = ModulesService.setLayoutQuantity(vm.article.xml.piclayouts.piclayout);
                vm.picLayoutXmlGroup = vm.picLayoutXmlsList[0];
            }
            productXmlGet()
            if(vm.isEdit){
                $scope.template = angular.copy(obj);
            }else {
                $scope.template = angular.copy($scope.pages);
            }
            reserveInit($scope.template.backupPages.list);
            var _page=angular.copy($scope.template.pages.page);
            $scope.template.pages.page = [];
            _page.forEach(function (page) {
                if(!page.backup){
                    $scope.template.pages.page.push(page);
                }
            })
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page) {
                ratioInfo(page)
            },function () {
                $timeout(function () {
                    vm.pageData = [];
                    vm.pageData = $scope.template.pages.page;
                    vm.pageSum = $scope.template.pages.page.length - 1;
                    pageDataInit();
                    vm.pageMaxWidth = $scope.template.pages.page[0].custom.width*$scope.template.pages.page[0].custom.ratio;
                    vm.pageMaxHeight = $scope.template.pages.page[0].custom.height*$scope.template.pages.page[0].custom.ratio;
                    $scope.template.pages.page.forEach(function (page) {
                        if(vm.pageMaxWidth<page.custom.width*page.custom.ratio){
                            vm.pageMaxWidth=page.custom.width*page.custom.ratio
                        }
                        if(vm.pageMaxHeight<page.custom.height*page.custom.ratio){
                            vm.pageMaxHeight=page.custom.height*page.custom.ratio
                        }
                    })
                    vm.partid = $scope.template.config.parts.list[0].id;
                    fontSizeInit(vm.pageData[0].trimbox.height);
                    pageListDrag1();
                    reserveQuantity();
                    vm.cookies=[];
                    var _data = angular.copy(vm.pageData[vm.pageNumber]);
                    vm.cookies.push(_data);
                    vm.cookieIndex = 0;
                    vm.threeLoading = false;
                    $timeout(function () {
                        if(!$scope.template.config.dynamic){
                            pageListDrag();
                        }
                    },1000)
                    if(callback){callback()}
                    $scope.$apply();
                },100)
            })
        }
        function recommendInit(template) {
            if(template && template.backupPages && template.backupPages.list && template.backupPages.list.length>0){
                template.backupPages.list.forEach(function (item) {
                    var _page1 = item;
                    getPageType(_page1)
                    _page1.numberOfHoles = 0;
                    backupPagesPartQuantity(item.config.partid);
                    if(_page1.levels && _page1.levels.level && _page1.levels.level.length>0){
                        _page1.levels.level.forEach(function (level){
                            if(level.imagebox && level.imagebox.geometry){
                                _page1.numberOfHoles++;
                            }
                        });
                    }
                })
                template.pages.page.forEach(function (page) {
                    page.numberOfHoles = 0;
                    page.recommendLayout = [];
                    page.levels.level.forEach(function (level){
                        if(level.imagebox && level.imagebox.geometry){
                            page.numberOfHoles++;
                        }
                    });
                    var _page = angular.copy(page);
                    page.recommendLayout.push(_page)
                });
                template.backupPages.list.forEach(function (page) {
                    for(var i=0; i<template.pages.page.length; i++){
                        if(page.uuid != template.pages.page[i].uuid && template.pages.page[i].numberOfHoles === page.numberOfHoles && template.pages.page[i].spread == page.spread && page.config.partid == template.pages.page[i].config.partid){
                            template.pages.page[i].recommendLayout.push(page)
                        }
                    }
                });
            }
        }
        function backupPagesPartQuantity(partid) {
            if($scope.template&&$scope.template.config&&$scope.template.config.parts&&$scope.template.config.parts.list){
                for(var i=0; i<$scope.template.config.parts.list.length; i++){
                    if($scope.template.config.parts.list[i].id == partid){
                        if(!$scope.template.config.parts.list[i].backupPagesQuantity){
                            $scope.template.config.parts.list[i].backupPagesQuantity = 1;
                        }else{
                            $scope.template.config.parts.list[i].backupPagesQuantity++
                        }
                    }
                }
            }
        }
        function pageDataInit() {
            vm.pageIndex = 0;
            vm.templatePages = [];
            var _pageData = vm.pageData;
            var _pageIndex = 0;
            _pageData.forEach(function (page) {
                page.isUse = false;
            })
            _pageData.forEach(function (page, index) {
                if(!page.isUse){
                    _pageIndex++
                    if(page.config.partid == 'P' && vm.product.patternType == "BOOK"){
                        if(page.spread || ($scope.template.config.innerOffset == 1 && $scope.getIndex(page) == 1)){
                            page.isUse = true;
                            page.seq = index;
                            ratioInfo(page,true)
                            vm.templatePages.push({
                                page1:page,
                                page2:null,
                                spread: true,
                                pageIndex: _pageIndex
                            })
                        }else{
                            var _page1 = _pageData[index];
                            var _page2 = index+1 >= _pageData.length ? null : _pageData[index+1];
                            _page1.isUse = true;
                            _page1.seq = index;
                            if(_page2 && !_page2.spread && _page2.config.partid == 'P'){
                                _page2.isUse = true;
                                _page2.seq = index+1;
                                ratioInfo(_page1,false)
                                ratioInfo(_page2,false)
                                vm.templatePages.push({
                                    page1:_page1,
                                    page2:_page2,
                                    spread:false,
                                    pageIndex: _pageIndex
                                })
                            }else{
                                ratioInfo(_page1,true)
                                vm.templatePages.push({
                                    page1:_page1,
                                    page2:null,
                                    spread:true,
                                    pageIndex: _pageIndex
                                })
                            }
                        }
                    }else{
                        page.isUse = true;
                        page.seq = index;
                        ratioInfo(page,true)
                        vm.templatePages.push({
                            page1:page,
                            page2:null,
                            spread: true,
                            pageIndex: _pageIndex
                        })
                    }
                }
            })
            if(vm.pageIndex > vm.templatePages.length){
                vm.pageIndex = vm.templatePages.length-1;
            }
            pageNumberInit()
            magnitudeBox();
        }
        function pageNumberInit() {
            vm.pageNumber1 = -1;
            vm.pageNumber2 = -1;
            vm.pageNumberMax = -1;
            if(vm.templatePages[vm.pageIndex].page1){
                vm.pageNumber1 = vm.pageNumberMax = vm.templatePages[vm.pageIndex].page1.seq;
            }
            if(vm.templatePages[vm.pageIndex].page2){
                vm.pageNumber2 = vm.pageNumberMax = vm.templatePages[vm.pageIndex].page2.seq;
            }
            if (vm.pageData[vm.pageIndex].scene) {
                ratioInfo(vm.pageData[vm.pageIndex])
            }
        }
        $scope.onRatioinfo = function (){
            if(!vm.pageData[vm.pageIndex].isEditboxMagnify){
                vm.pageData[vm.pageIndex].isEditboxMagnify = true;
                vm.pageData[vm.pageIndex].scene = null;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
        }
        $scope.onRatioinfo1 = function (){
            if(vm.pageData[vm.pageIndex].isEditboxMagnify){
                vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                vm.pageData[vm.pageIndex].scene = vm.pageData[vm.pageIndex].sceneCopy;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
        }
        function ratioInfo(page,spread) {
            vm.windowH = $(document).height() - 110;
            vm.windowW = $(document).width() - 600;
            vm.windowW1 = 240;
            if(!spread){
                vm.windowW = vm.windowW/2;
            }
            if (page.scene) {
                page.custom.width = page.scene.geometry.width;
                page.custom.height = page.scene.geometry.height;
            } else {
                page.custom.width = page.mediabox.width;
                page.custom.height = page.mediabox.height;
            }
            if(vm.windowH / page.custom.height >= vm.windowW / page.custom.width ){
                page.custom.ratio = vm.windowW / page.custom.width * 0.8;
            }else {
                page.custom.ratio = vm.windowH / page.custom.height * 0.8;
            }
            page.custom.ratio1 = vm.windowW1 / page.custom.width * 0.8;
            page.custom.ratio2 = Math.round(Number(page.custom.ratio)*100);
            page.custom.ratio3 = angular.copy(page.custom.ratio2);
            page.custom.thumbnailScale = 300 / page.custom.width;
        }
        function reserveInit(page) {
            vm.reservePage = angular.copy(page) || [];
            vm.reservePage.forEach(function (page) {
                getPageType(page)
            })
        }
        function reserveQuantity() {
            vm.reservePage.forEach(function (page) {
                page.count = 0;
                $scope.template.pages.page.forEach(function (page1) {
                    if(page.uuid == page1.uuid){
                        page.count++
                    }
                })
            });
        }
        function productXmlGet(){
            ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                vm.productXmlData = productXml.data;
            })
        }
        $scope.pageInfo = function(item){
            vm.pageNumber = item;
            if(!vm.textFont){
                $scope.editorBodyView();
            }
        };
        $scope.previousPage = function (e) {
            e.target.blur();
            if(vm.pageNumber1 > 0){
                if (vm.pageData[vm.pageIndex].sceneCopy&&vm.pageData[vm.pageIndex].isEditboxMagnify) {
                    vm.pageData[vm.pageIndex].scene = angular.copy(vm.pageData[vm.pageIndex].sceneCopy);
                    vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                    ratioInfo(vm.pageData[vm.pageIndex])
                }
                vm.isCut = true;
                $rootScope.$broadcast('onKeyDownCallback');
                $timeout(function () {
                    vm.moveCanvasObj = {
                        x:0,
                        y:0
                    };
                    $scope.ratioInit();
                    vm.pageIndex--;
                    pageNumberInit();
                    $scope.template.pages.page = vm.pageData;
                    $scope.editorBodyView();
                    vm.pageNumber = vm.pageNumber1;
                    vm.cookies=[];
                    var _data = angular.copy(vm.pageData[vm.pageNumber]);
                    vm.cookies.push(_data);
                    vm.cookieIndex = 0;
                    vm.isCut = false;
                })
            }
        }
        $scope.nextPage = function (e) {
            e.target.blur();
            if(vm.pageNumberMax > -1 && vm.pageNumberMax < vm.pageSum){
                if (vm.pageData[vm.pageIndex].sceneCopy&&vm.pageData[vm.pageIndex].isEditboxMagnify) {
                    vm.pageData[vm.pageIndex].scene = angular.copy(vm.pageData[vm.pageIndex].sceneCopy);
                    vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                    ratioInfo(vm.pageData[vm.pageIndex])
                }
                vm.isCut = true;
                $rootScope.$broadcast('onKeyDownCallback');
                $timeout(function () {
                    vm.moveCanvasObj = {
                        x:0,
                        y:0
                    };
                    $scope.ratioInit();
                    vm.pageIndex++;
                    pageNumberInit()
                    $scope.template.pages.page = vm.pageData;
                    $scope.editorBodyView();
                    vm.pageNumber = vm.pageNumber1;
                    vm.cookies=[];
                    var _data = angular.copy(vm.pageData[vm.pageNumber]);
                    vm.cookies.push(_data);
                    vm.cookieIndex = 0;
                    vm.isCut = false;
                })
            }
        }
        $scope.turning = function (index) {
            if (vm.pageData[vm.pageIndex].sceneCopy&&vm.pageData[vm.pageIndex].isEditboxMagnify) {
                vm.pageData[vm.pageIndex].scene = angular.copy(vm.pageData[vm.pageIndex].sceneCopy);
                vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
            vm.isCut = true;
            $rootScope.$broadcast('onKeyDownCallback');
            $timeout(function () {
                vm.moveCanvasObj = {
                    x:0,
                    y:0
                };
                $scope.ratioInit();
                vm.pageNumber = Number(index);
                vm.templatePages.forEach(function (item, index1) {
                    if(item.page1 && item.page1.seq == index){
                        vm.pageIndex = index1;
                    }
                    if(item.page2 && item.page2.seq == index){
                        vm.pageIndex = index1;
                    }
                })
                pageNumberInit();
                vm.cookies=[];
                var _data = angular.copy(vm.pageData[vm.pageNumber]);
                vm.cookies.push(_data);
                vm.cookieIndex = 0;
                vm.isCut = false;
            })
        }

        //拖拽排序
        var sort = null;
        var sortArray = [];
        vm.isSort = false;
        function pageListDrag() {
            $timeout(function () {
                vm.sortingPage = angular.copy(vm.pageData);
                sort = new Sortable(document.getElementById('pageList'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    scroll:true,
                    onStart: function(evt){
                        vm.sortPartid = vm.pageData[evt.oldIndex].config.partid;
                        vm.pageSpread = vm.pageData[evt.oldIndex].spread;
                    },
                    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                        $timeout(function () {
                            var order = sort.toArray();
                            vm.pageData.forEach(function (page, index) {
                                if (!(!page.unreplace&&$scope.template.replace&&vm.sortPartid == page.config.partid?true:false)||(vm.pageSpread != page.spread)) {
                                    var _index = order.indexOf((index + 1).toString());
                                    order.splice(_index, 1)
                                }
                            })
                            vm.pageData.forEach(function (page, index) {
                                if (!(!page.unreplace&&$scope.template.replace&&vm.sortPartid == page.config.partid?true:false)||(vm.pageSpread != page.spread)) {
                                    order.splice(index, 0, (index + 1).toString())
                                }
                            })
                            sort.sort(order)
                        },150)
                        var _uuid = $(evt.related).attr("data-page");
                        var _page = getPageUuid(_uuid);
                        if(!(!_page.unreplace&&$scope.template.replace&&vm.sortPartid == _page.config.partid?true:false)||(vm.pageSpread != _page.spread)){
                            return false
                        }
                    },
                    onEnd: function (/**Event*/evt) {
                        $timeout(function () {
                            var _top = angular.copy($('.c-editor-page-pageList').scrollTop());
                            vm.pageListLoading = true;
                            $timeout(function () {
                                sortArray = [];
                                angular.forEach($('#pageList').find(".sortingPageView"), function (item, index) {
                                    var uuid = item.getAttribute("data-page");
                                    var page = getPageUuid(uuid);
                                    sortArray.push(page);
                                });
                                if(sortArray && sortArray.length>0){
                                    sortArray.forEach(function (value, index) {
                                        value.seq = index;
                                        // ratioInfo(value);
                                    })
                                    vm.pageData = [];
                                    $timeout(function () {
                                        vm.pageData = angular.copy(sortArray);
                                        sortArray = [];
                                        $timeout(function () {
                                            $('.c-editor-page-pageList').scrollTop(_top)
                                            vm.pageListLoading = false;
                                            pageListDrag();
                                        },200)
                                    })
                                }
                            },500)
                        })
                    }
                });
            })
        }
        function getPageUuid(uuid) {
            var page = null;
            for(var i=0; i<vm.pageData.length; i++){
                if(vm.pageData[i].pageUuid == uuid){
                    page = angular.copy(vm.pageData[i]);
                    break
                }
            }
            return page
        }
        //mm转px
        function transitionPx(item) {
            return item * vm.windowDpi / 25.4;
        }
        //px转mm
        $scope.transitionMm = transitionMm;
        $scope.editorBodyView = function(e){
            vm.isChangePage1 = false;
            if(e){
                fn(e);
            }
            vm.elementOn = {
                index:"",
                pageIndex:"",
                name:"",
                item:""
            };
            vm.copyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: [], calTitleBoxs: [], calMonthBoxs: []};
            levelInif();
            toolbarStatus();
            clearCode();
        }

        //拖拽图片
        $timeout(function () {
            var content = $("#pageCanvas")[0];
            if(content){
                content.ondragenter = ignoreDrag;
                // 拖动文件的鼠标指针位置放置区之上时发生
                content.ondragover = ignoreDrag;
                content.ondrop = drop;
            }
        },1000)
        window.dragStart = function (event) {
            var target = event.target;
            event.dataTransfer.setData("img", target.id);
            if (target.id.indexOf('dragtargetClipArt') != '-1') {
                event.dataTransfer.setData("Art", target.dataset.clipart);
            }
            $timeout(function () {
                vm.dragDom = true;
            })
        };
        window.dragOn = function(event){
            $timeout(function () {
                vm.dragDom = false;
            })
        }
        $timeout(function () {
            document.body.ondrop = function(event) {
                event.preventDefault();
                event.stopPropagation();
            };
        })
        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
            var dataset = e.target.dataset;
            if (vm.dragDom && (e.target.nodeName == 'IMG' || ($(e.target).attr('class') && $(e.target).attr('class').indexOf('image-placeholder-class') > -1))&&dataset.imgindex) {
                var textIndex = dataset.imgindex;
                $(".imageboxView").removeClass('selected-blue')
                var _pageIndex = $(e.target).parents('.pageCanvasItem').attr('data-pageIndex');
                $(".imageboxView"+_pageIndex+textIndex).addClass('selected-blue');
                // console.log($(e.target).attr('class'))
            }else{
                $(".imageboxView").removeClass('selected-blue')
            }

        }
        function drop(e) {
            var data = e.dataTransfer.getData("img");
            $(".imageboxView").removeClass('selected-blue')
            if(!data){
                return
            }
            var dataIndex = $('#' + data)[0].getAttribute('data-index');
            $timeout(function () {
                var _pageIndex = $(e.target).parents('.pageCanvasItem').attr('data-pageIndex1');
                var _pageIndex1 = $(e.target).parents('.pageCanvasItem').attr('data-pageIndex');
                if(!_pageIndex){
                    return
                }
                if(vm.pageNumber != _pageIndex1){
                    vm.pageNumber = _pageIndex1;
                    cookieInfo()
                }
                ignoreDrag(e);
                $(".imageboxView").removeClass('selected-blue');
                var offsetX= e.clientX;
                var offsetY= e.clientY;
                if(_pageIndex == 1){
                    offsetX -= parseFloat($(".pageCanvasItem1").offset().left);
                    offsetY -= parseFloat($(".pageCanvasItem1").offset().top);
                }
                if(_pageIndex == 2){
                    offsetX -= parseFloat($(".pageCanvasItem2").offset().left);
                    offsetY -= parseFloat($(".pageCanvasItem2").offset().top);
                }
                if(vm.pageData[vm.pageNumber].scene){
                    offsetX -= vm.pageData[vm.pageNumber].scene.imagebox.geometry.x * vm.pageData[vm.pageNumber].custom.ratio;
                    offsetY -= vm.pageData[vm.pageNumber].scene.imagebox.geometry.y * vm.pageData[vm.pageNumber].custom.ratio;
                }
                //拖拽图片进入占位框
                if (data.indexOf('dragtargetPhoto') != '-1') {
                    var imgindex = e.target.dataset.imgindex;
                    if(imgindex == null){
                        var item = $('#' + data).attr('data-item');
                        replaceImagebox1(offsetX, offsetY, JSON.parse(item));
                        $timeout(function () {
                            photosNum();

                            cookie();
                        },500)
                    }else {
                        vm.levelIndex = imgindex;
                        var item = JSON.parse($('#' + data).attr('data-item'));
                        var _imagebox = vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox;
                        var identifier = _imagebox.mask.resource.identifier;
                        replaceImagebox(item);
                        $timeout(function () {
                            photosNum();
                            cookie();
                        },500)
                    }
                }
                //边框
                if (data.indexOf('dragtargetFrameConfig') != '-1') {
                    var item = $('#' + data).attr('data-item');
                    var imgindex = e.target.dataset.imgindex;
                    if(imgindex == null){
                        var borderList = vm.borderList[$('#' + data).attr('data-index')];
                        $http({url: borderList.img + '?imageInfo'}).success(function (data) {
                            var imgZoom = 1;
                            if (data.width > data.height) {
                                imgZoom = 200 / data.width
                            } else {
                                imgZoom = 200 / data.height
                            }
                            var _w2 = (imgZoom * data.width) / (1 + borderList.marginLeft / 100 + borderList.marginRight / 100) / vm.pageData[vm.pageNumber].custom.ratio;
                            var _h2 = (imgZoom * data.height) / (1 + borderList.marginBottom / 100 + borderList.marginTop / 100) / vm.pageData[vm.pageNumber].custom.ratio;
                            var _level = {
                                imagebox:{},
                                seq: vm.pageData[vm.pageNumber].levels.level.length + Number(1)
                            }
                            var _imagebox = {
                                blurredrectangle:null,
                                bokehtype:vm.article.bokehtype,
                                bokehtypes:vm.article.bokehtype,
                                geometry:{
                                    width: _w2,
                                    height: _h2,
                                    x:(offsetX/vm.pageData[vm.pageNumber].custom.ratio - _w2 / 2) || 0,
                                    y:(offsetY/vm.pageData[vm.pageNumber].custom.ratio - _h2 / 2) || 0
                                },
                                image: {
                                    resource: {
                                        identifier: item.identifier,
                                        provider: 'qiniu'
                                    },
                                    filterResource: {
                                        identifier: '',
                                        provider: 'qiniu'
                                    },
                                    width: item.width,
                                    height: item.height,
                                    imgSize: item.imgSize,
                                    index: item.seq,
                                    parameter: angular.fromJson(item.parameter),
                                    scale: 1,
                                    angle: 0
                                },
                                mask: {
                                    resource:{
                                        identifier: '',
                                        provider: '',
                                    }
                                },
                                rotation:{
                                    angle: 0
                                },

                                border: {
                                    shape: borderList.shape || 'Rectangle',
                                    radius: {
                                        lt: 0,
                                        rt: 0,
                                        rb: 0,
                                        lb: 0,
                                        all: 0,
                                        filletType: 'Fixed'
                                    },
                                    "edge": {
                                        top: borderList.marginTop,
                                        right: borderList.marginRight,
                                        bottom: borderList.marginBottom,
                                        left: borderList.marginLeft
                                    },
                                    lineWidth: 0,
                                    type: "Pic",
                                    resource: {
                                        "identifier": borderList.img
                                    },
                                    color: '',
                                },
                                transparency:1,
                                shadow:{
                                    color:'#777777',
                                    x:0,
                                    y:0,
                                    blur:0
                                },
                                useradded: true
                            }
                            _level.imagebox = _imagebox;
                            vm.pageData[vm.pageNumber].levels.level.push(_level);
                            magnitudeBox();
                        })
                    }else {
                        vm.levelIndex = imgindex;
                        var borderList = vm.borderList[$('#' + data).attr('data-index')];
                        vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border = {
                            shape: borderList.shape || 'Rectangle',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0,
                                filletType: 'Fixed'
                            },
                            "edge": {
                                top: borderList.marginTop,
                                right: borderList.marginRight,
                                bottom: borderList.marginBottom,
                                left: borderList.marginLeft
                            },
                            lineWidth: 0,
                            type: "Pic",
                            resource: {
                                "identifier": borderList.img
                            },
                            color: '',
                        }
                    }
                    $timeout(function () {
                        cookie();
                    },500)
                }

                // 判读是否是占位框
                if (data.indexOf('dragtargetMask') != '-1') {
                    var item = $('#' + data).attr('data-item');
                    var imgindex = e.target.dataset.imgindex;
                    if(imgindex == null){
                        var border = null;
                        if (data.indexOf('Round') > '-1') {
                            border = {
                                shape: 'Ellipse',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                        } else if (data.indexOf('Rectangle') > '-1') {
                            border = {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                        }else{
                            border = {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                        }
                        replaceImagebox1(offsetX, offsetY, JSON.parse(item), border);
                    }else {
                        vm.levelIndex = imgindex;
                        var item = JSON.parse($('#' + data).attr('data-item'));
                        vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.mask.resource.identifier = item.resource.identifier;
                        if (data.indexOf('Round') > '-1') {
                            if(vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border){
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border.shape = 'Ellipse';
                            }else{
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border= {
                                    shape: 'Ellipse',
                                    radius: {
                                        lt: 0,
                                        rt: 0,
                                        rb: 0,
                                        lb: 0,
                                        all: 0,
                                        filletType: 'Fixed'
                                    },
                                    "edge": {
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                    },
                                    lineWidth: 0,
                                    type: "Color",
                                    lineType:"solid",
                                    resource: {
                                        "identifier": ''
                                    },
                                    color: '#000000',
                                };
                            }

                            vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.shadow= {
                                color:'#777777',
                                x:0,
                                y:0,
                                blur:0
                            }
                        } else if (data.indexOf('Rectangle') > '-1') {
                            if(vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border){
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border.shape = 'Rectangle';
                            }else{
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border= {
                                    shape: 'Rectangle',
                                    radius: {
                                        lt: 0,
                                        rt: 0,
                                        rb: 0,
                                        lb: 0,
                                        all: 0,
                                        filletType: 'Fixed'
                                    },
                                    "edge": {
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                    },
                                    lineWidth: 0,
                                    type: "Color",
                                    lineType:"solid",
                                    resource: {
                                        "identifier": ''
                                    },
                                    color: '#000000',
                                };
                            }

                            vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border= {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                            vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.shadow= {
                                color:'#777777',
                                x:0,
                                y:0,
                                blur:0
                            }
                        }
                    }
                    $timeout(function () {
                        cookie();
                    },500)
                }
                //判读是否是素材
                if (data.indexOf('dragtargetClipArt') != '-1') {
                    $scope.materialCb(vm.clipArtsList[dataIndex], offsetX,offsetY, true);
                    $scope.$digest();
                }
                // 可变数据
                if (data.indexOf('dragtargetVariable') != '-1'){
                    var arr = vm.variableData.titles.title[dataIndex];
                    if(arr.select){
                        var border = {
                            shape: 'Rectangle',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0,
                                filletType: 'Fixed'
                            },
                            "edge": {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                            lineWidth: 0,
                            type: "Color",
                            lineType:"solid",
                            resource: {
                                "identifier": ''
                            },
                            color: '#000000',
                        };
                        replaceImagebox1(offsetX, offsetY, vm.generalMask[1], border,arr.value);
                    }else{
                        var value = '#'+$('#' + data)[0].getAttribute('data-value')+'#';
                        $scope.addText(24,value);
                    }
                }
            })
        }

        $scope.replaceImagebox1=function(e,data,index){
            if(data.type=="masking"){
                return
            }
            vm.levelIndex = index;
            replaceImagebox(data)
        }
        function replaceImagebox(data){
            var item = angular.copy(data);
            var _imagebox = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox);
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                offsetx:0,
                offsety:0,
                resourceid:item.sourceId,
                width: item.width,
                height: item.height,
                imageInfo: item.imageInfo,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0,
            }
            addImagebox($http, _imagebox, function () {
                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox = _imagebox;
                onArticleChange();
                thumbnailInit();
            });
            imageSelectInit();
            magnitudeBox();
        }
        vm.imageSelectInit = imageSelectInit;
        function imageSelectInit(){
            vm.photoSelectAll = [];
            for(var i=0; i<vm.pageData.length; i++){
                for(var j=0; j<vm.pageData[i].levels.level.length; j++){
                    var _level = vm.pageData[i].levels.level[j];
                    if(_level.imagebox && _level.imagebox.image && _level.imagebox.image.resourceid){
                        for(var k=0; k<$scope.photos.length; k++){
                            if(_level.imagebox.image.resourceid == $scope.photos[k].sourceId){
                                vm.photoSelectAll.push($scope.photos[k]);
                                break
                            }
                        }
                    }
                }
            }
        }
        function replaceImagebox1(offsetX, offsetY, data, border, value){
            if (!border) {
                border = {
                    shape: 'Rectangle',
                    radius: {
                        lt: 0,
                        rt: 0,
                        rb: 0,
                        lb: 0,
                        all: 0,
                        filletType: 'Fixed'
                    },
                    "edge": {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    lineWidth: 0,
                    type: "Color",
                    lineType:"solid",
                    resource: {
                        "identifier": ''
                    },
                    color: '#000000',
                };
            }
            var item = angular.copy(data);
            if(item.type=='picture'){
                var _level = {
                    imagebox:{},
                    seq: vm.pageData[vm.pageNumber].levels.level.length + Number(1)
                }
                var _imagebox = {
                    value: value,
                    blurredrectangle:null,
                    bokehtype: angular.copy(vm.article.bokehtype),
                    geometry:{
                        width:0,
                        height:0,
                        x:0,
                        y:0
                    },
                    border: border,
                    shadow:{
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    },
                    image: {
                        resource: {
                            identifier: item.identifier,
                            provider: 'qiniu'
                        },
                        filterResource: {
                            identifier: '',
                            provider: 'qiniu'
                        },
                        resourceid:item.sourceId,
                        width: item.width,
                        height: item.height,
                        imageInfo: item.imageInfo,
                        imgSize: item.imgSize,
                        index: item.seq,
                        parameter: angular.fromJson(item.parameter),
                        scale: 1,
                        angle: 0,
                    },
                    transparency:1,
                    mask: {
                        resource:{
                            identifier: '',
                            provider: '',
                        }
                    },
                    rotation:{
                        angle: 0
                    }
                }

                var w = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                var h = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                var imgR = w/h;
                var imgR1 = item.width/item.height;

                if(imgR >= imgR1){
                    _imagebox.geometry.width= h/item.height*item.width;
                    _imagebox.geometry.height= h;
                }else {
                    _imagebox.geometry.width= w;
                    _imagebox.geometry.height= w/item.width*item.height;
                }

                _imagebox.geometry.x = (offsetX/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.width/2);
                _imagebox.geometry.y = (offsetY/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.height/2);
                _level.imagebox = _imagebox;
                addImagebox($http, _level.imagebox, function () {
                    vm.pageData[vm.pageNumber].levels.level.push(_level);
                    setLayout()
                    magnitudeBox();
                });
            }
            if(item.type=="masking"){
                var newImg = new Image();
                newImg.crossOrigin = "Anonymous";
                newImg.src = item.resource.identifier + '?imageMogr2/strip/rotate';
                newImg.onload = function () {
                    var _level = {
                        imagebox:{},
                        seq: vm.pageData[vm.pageNumber].levels.level.length + Number(1)
                    }
                    var _imagebox = {
                        value: value,
                        blurredrectangle:null,
                        bokehtype: angular.copy(vm.article.bokehtype),
                        geometry:{
                            width:0,
                            height:0,
                            x:0,
                            y:0
                        },
                        border:border,
                        image: {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            scale: 1,
                            angle: 0
                        },
                        mask: {
                            resource:{
                                identifier: item.resource.identifier,
                                provider: 'qiniu',
                            }
                        },
                        rotation:{
                            angle: 0
                        },
                        transparency:1,
                        shadow:{
                            color:'#777777',
                            x:0,
                            y:0,
                            blur:0
                        },
                    };
                    var w = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                    var h = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                    var imgR = w/h;
                    var imgR1 = newImg.width/newImg.height;

                    if(imgR >= imgR1){
                        _imagebox.geometry.width= h/newImg.height*newImg.width;
                        _imagebox.geometry.height= h;
                    }else {
                        _imagebox.geometry.width= w;
                        _imagebox.geometry.height= w/newImg.width*newImg.height;
                    }
                    _imagebox.geometry.x = (offsetX/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.width/2);
                    _imagebox.geometry.y = (offsetY/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.height/2);
                    _level.imagebox = _imagebox;
                    vm.pageData[vm.pageNumber].levels.level.push(_level);
                    // getBackgroundWebSource3($scope,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.geometry.width,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.geometry.height,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.image, vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.bokehtype);
                    magnitudeBox();
                    $scope.$digest();
                };
            }
        }

        vm.magnitudeBox = magnitudeBox;
        function magnitudeBox(){
            vm.counts = 0;
            vm.current = 0;
            angular.forEach(vm.pageData, function (page, i) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imagebox&&level.imagebox.geometry){
                        vm.counts++;
                    }
                    if(level.imagebox && level.imagebox.image && level.imagebox.image.resource &&  level.imagebox.image.resource.identifier){
                        vm.current++;
                    }
                });
            });
            onArticleChange();
            photosNum();
        }
        vm.elementInit = elementInit;
        function elementInit(index,pageIndex,item,name){
            clearCode();
            vm.clickNum = 0;
            vm.elementOn = {
                index:index,
                pageIndex:pageIndex,
                name:name,
                item:item
            };
            // if(vm.elementOn.item.image && vm.elementOn.item.image.resourceid && !vm.elementOn.item.image.resource.primary) {
            //     Gallery.getOne({id:vm.elementOn.item.image.resourceid},function (res){
            //         vm.elementOn.item.image.resource.primary = res.data.identifier
            //     })
            // }
            if(vm.pageNumber != pageIndex){
                $rootScope.$broadcast('onKeyDownCallback');
                $timeout(function () {
                    vm.pageNumber = pageIndex;
                    vm.cookies=[];
                    var _data = angular.copy(vm.pageData[vm.pageNumber]);
                    vm.cookies.push(_data);
                    vm.cookieIndex = 0;
                })
            }
            scrollIntoView();
            scrollIntoViewElement();
            variableNumber();
            toolbarStatus();
        }
        /***********************  素材  ****************************/
        //添加素材
        $scope.materialCb = function(item, x, y, isDrop){
            webAddImageart($scope,$http,item,vm.pageData[vm.pageNumber].trimbox, function (_imageart) {
                if(x && y){
                    _imageart.geometry.x = (x-_imageart.geometry.width/2)/vm.pageData[vm.pageNumber].custom.ratio;
                    _imageart.geometry.y = (y-_imageart.geometry.height/2)/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(!isDrop) {
                    var _num = 0;
                    vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                        if (level.imageart) {
                            _num += 10;
                        }
                    });
                    _imageart.geometry.x += _num / vm.pageData[vm.pageNumber].custom.ratio;
                    _imageart.geometry.y += _num / vm.pageData[vm.pageNumber].custom.ratio;
                }
                _imageart.geometry.width = _imageart.geometry.width/vm.pageData[vm.pageNumber].custom.ratio;
                _imageart.geometry.height = _imageart.geometry.height/vm.pageData[vm.pageNumber].custom.ratio;
                var _level ={
                    seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                    imageart: _imageart
                }
                vm.pageData[vm.pageNumber].levels.level.push(_level);
                elementInit(_level.seq-1,vm.pageNumber,_level.imageart,'imageart');
                cookie();
            })
        }

        /***********************  文字  ****************************/
        fontuuid()
        vm.selectFont = '';
        vm.selectFontBtn = fontuuid;
        vm.selectFontName = selectFontName;
        vm.chineseSupport = true;
        function fontuuid(){
            vm.fontuuid = [];
            FontManagement.getAll({family: vm.selectFont}, function (res) {
                vm.fontuuid = res;
            });
        }
        function selectFontName(item){
            if(item){
                for(var i=0;i<vm.fontuuid.length; i++){
                    if(vm.fontuuid[i].uuid == item){
                        return vm.fontuuid[i].family
                    }
                }
            }
        }
        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                MessageService.error($translate.instant("editorMessage.failedAddText"));
                return
            }
            if (!data.enable) {
                MessageService.error($translate.instant("editorMessage.fontChange"));
                return
            }
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            if (text.lock) {
                return
            }
            vm.fontStyle = [];
            for(var i=0; i<vm.fontuuid.length; i++){
                if(vm.fontuuid[i].uuid == data.uuid){
                    for(var j=0; j<vm.fontuuid[i].fontStyles.length; j++){
                        vm.fontStyle.push(vm.fontuuid[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if(vm.fontStyle.indexOf('NORMAL') != '-1'){
                text.style.bold = false;
                text.style.italic = false;
            }else {
                if(vm.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                    text.style.bold = true;
                    text.style.italic = true;
                }else {
                    if(vm.fontStyle.indexOf('ITALIC') != '-1'){
                        text.style.italic = true;
                        text.style.bold = false;
                    }
                    if(vm.fontStyle.indexOf('BOLD') != '-1'){
                        text.style.bold = true;
                        text.style.italic = false;
                    }
                }
            }
            text.style.font = data.family;
            text.fontuuid = data.uuid;
            text.identifier = $scope.domain + data.fontStyles[0].identifier;
            text.loading = true;
            FontSupport2(FontManagement, text, function (res) {
                var msg = res.data;
                if (res.status == 200) {
                    if (!msg.success) {
                        $timeout(function () {
                            vm.editFailedFont = false;
                            vm.failedFontArr = null;
                            MessageService.confirm({
                                title:$translate.instant("editorMessage.notDelete"),
                                cancel:$translate.instant("button.cancel"),
                                confirm:$translate.instant("button.returnCorrection"),
                                msg:$translate.instant("editorMessage.pleaseChangeFonts")
                            })
                            text.loading = false;
                        })
                    }else{
                        $timeout(function () {
                            text.text = (!msg.passed || msg.passed == "") ? $translate.instant("editorMessage.pleaseEnterText") : msg.passed;
                            if (msg.success && !msg.supported) {
                                $timeout(function () {
                                    vm.editFailedFont = true;
                                    vm.failedFontArr = {
                                        failed: msg.failed
                                    }
                                    $('.selected').find('pre span').html(text.text);
                                    text.loading = false;
                                    $timeout(function () {
                                        sizeHeight()
                                        fontStyle(data.uuid);
                                        cookie();
                                    },200)
                                })
                            } else {
                                vm.editFailedFont = false;
                                vm.failedFontArr = null;
                                ModulesService.getFontCSS(text);
                                cookie();
                                $timeout(function () {
                                    sizeHeight()
                                    fontStyle(data.uuid);
                                },200)
                            }
                        })
                    }
                }
            })
        };
        //添加文字
        $scope.addText = function (size, value) {
            textboxWebAdd(vm.defaultFont, vm.defaultFont.fontStyles, size, function (_font) {
                if(value){
                    _font.text = value;
                    _font.value = value;
                }else{
                    _font.text = $translate.instant("editorCommon.doubleClickType");
                    _font.value = $translate.instant("editorCommon.doubleClickType");
                }
                var _level ={
                    seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                    textbox: _font
                }
                vm.pageData[vm.pageNumber].levels.level.push(_level);
                elementInit(_level.seq-1,vm.pageNumber,_level.textbox,'textbox');
                $timeout(function () {
                    // vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio)) + Number(5));
                    // vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio) + Number(10);
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.x = (vm.pageData[vm.pageNumber].trimbox.width - vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.width) / 2 + vm.pageData[vm.pageNumber].trimbox.x;
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.y = (vm.pageData[vm.pageNumber].trimbox.height - vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.height) / 2 + vm.pageData[vm.pageNumber].trimbox.y;

                    var _num = 0;
                    vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                        if(level.textbox){
                            _num+=10;
                        }
                    });
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.x += _num/vm.pageData[vm.pageNumber].custom.ratio;
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.y += _num/vm.pageData[vm.pageNumber].custom.ratio;
                    getFontCSS(FontManagement, vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox, $scope)
                    // sizeHeight();
                    cookie();
                    fontStyle(vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.fontuuid)
                })
            })
        }
        vm.fontStyleCb = fontStyle;
        function fontStyle(fontuuid){
            if(vm.elementOn.name != "textbox"){
                return
            }
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            vm.fontStyle = [];
            for(var i=0; i<vm.fontuuid.length; i++){
                if(vm.fontuuid[i].uuid == fontuuid){
                    for(var j=0; j<vm.fontuuid[i].fontStyles.length; j++){
                        vm.fontStyle.push(vm.fontuuid[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold1 = false;
            text.style.italic1= false;
            if(vm.fontStyle.indexOf('NORMAL') == '-1'){
                text.style.normal = false;
            }else {
                text.style.normal = true;
            }
            if(vm.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                text.style.bold1 = true;
                text.style.italic1= true;
            }else {
                if(vm.fontStyle.indexOf('ITALIC') != '-1'){
                    text.style.italic1= true;
                }
                if(vm.fontStyle.indexOf('BOLD') != '-1'){
                    text.style.bold1 = true;
                }
            }
        }
        vm.sizeHeight = sizeHeight;
        function sizeHeight(){
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1").height());
                var _w = Number($("#c-xml-page-material-fontId1").width());
                var _h1 = Number($("#c-xml-page-material-fontId2").height());
                var _w1 = Number($("#c-xml-page-material-fontId2").width());
                if(_h<_h1){_h=_h1};
                if(_w<_w1){_w=_w1};
                if(vm.elementOn.name == 'textbox') {
                    if(!writingmodeInit(vm.elementOn.item.style.writingmode)){
                        vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.geometry.height = _h;
                    }
                    if(writingmodeInit(vm.elementOn.item.style.writingmode)){
                        vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.geometry.width = _w;
                    }
                    $scope.$digest();
                }
            },200)
        }
        function sizeHeight2(textbox){
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1").width()) + Number(10);
                    if(!writingmodeInit(vm.elementOn.item.style.writingmode)){
                        textbox.geometry.height = _h;
                    }
                    if(writingmodeInit(vm.elementOn.item.style.writingmode)){
                        textbox.geometry.width = _w;
                    }
            },200)
        }
        /***********************  文字模板  ****************************/
        $scope.addTextDoc = function (text,e) {
            FontXmlsFileServes.get({id: text.id}, function (res) {
                var level = {
                    seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                    textdocument: {}
                }
                textDocWebAdd($scope,$http,level, res.data.textDocument, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].mediabox, function (level) {
                    vm.pageData[vm.pageNumber].levels.level.push(level);
                    elementInit(level.seq-1,vm.pageNumber,level.textdocument,'textdocument');
                    cookie();
                }, function (text) {
                    getFontCSS(FontManagement, text, $scope)
                })
            })
        };
        //拆分组合
        $scope.slipGroup = function () {
            var textdocument = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textdocument);
            if(textdocument.lock){
                return
            }
            $scope.deleteBox();
            var textGeo = textdocument.geometry;
            if (textdocument.background && textdocument.background.resource && textdocument.background.resource.identifier) {
                $http({url: textdocument.background.resource.identifier + '?imageInfo'}).success(function (data) {
                    var resource = textdocument.background.resource;
                    var angle = 0;
                    if (textdocument.rotation.angle) {
                        angle = textdocument.rotation.angle;
                    }
                    var scale = 1;
                    if (textdocument.background.resource.adaptation == "Height") {
                        // scale = data.width / textdocument.geometry.width;
                        // scale = textdocument.geometry.width/data.width*data.height / textdocument.geometry.height;
                        scale = textdocument.geometry.height/data.height * data.width / textdocument.geometry.width;
                    }
                    var _imageart = {
                        movable: true,
                        editable: true,
                        onlyshow: false,
                        offsetx: textdocument.background.offsetx,
                        offsety: textdocument.background.offsety,
                        transparency: '1',
                        geometry: {
                            width: textdocument.geometry.width,
                            height: textdocument.geometry.height,
                            x: textdocument.geometry.x,
                            y: textdocument.geometry.y
                        },
                        rotation: {
                            angle: angle
                        },
                        resource: {
                            identifier: resource.identifier,
                            provider: 'qiniu',
                        },
                        width: data.width,
                        height: data.height,
                        imageInfo: data,
                        scale: scale,
                        useradded: true
                    }
                    var _level ={
                        seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                        imageart: _imageart
                    }
                    vm.pageData[vm.pageNumber].levels.level.push(_level);
                    elementInit(_level.seq-1,vm.pageNumber,_level.imageart,'imageart');
                }).error(function (error) {});
            }
            angular.forEach(textdocument.levels.level, function (level, i) {
                if (level.imageart) {
                    $http({url: level.imageart.resource.identifier + '?imageInfo'}).success(function (data) {
                        var resource = level.imageart.resource;
                        var angle = 0;
                        if (textdocument.rotation.angle) {
                            angle = textdocument.rotation.angle;
                        }
                        var _imageart = {
                            movable: true,
                            editable: true,
                            onlyshow: false,
                            offsetx: level.imageart.offsetx,
                            offsety: level.imageart.offsety,
                            transparency: '1',
                            geometry: {
                                width: level.imageart.geometry.width,
                                height: level.imageart.geometry.height,
                                x: level.imageart.geometry.x + textGeo.x,
                                y: level.imageart.geometry.y + textGeo.y
                            },
                            rotation: {
                                angle: angle
                            },
                            resource: {
                                identifier: resource.identifier,
                                provider: 'qiniu',
                                width: data.width,
                                height: data.height
                            },
                            scale: level.imageart.scale,
                            useradded: true
                        }
                        var _level ={
                            seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                            imageart: _imageart
                        }
                        vm.pageData[vm.pageNumber].levels.level.push(_level);
                        elementInit(_level.seq-1,vm.pageNumber,_level.imageart,'imageart');
                    }).error(function (error) {});
                }
                if (level.textbox) {
                    var currentLevel ={
                        seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                        textbox: angular.copy(level.textbox)
                    }
                    currentLevel.textbox.style.size = Math.trunc(currentLevel.textbox.style.size);
                    currentLevel.textbox.movable = textdocument.movable;
                    currentLevel.textbox.useradded = true;
                    currentLevel.textbox.editable = textdocument.editable;
                    currentLevel.textbox.levelI = $scope.template.levelI;
                    currentLevel.textbox.pageI = $scope.pageI;
                    currentLevel.textbox.current = 'textbox';
                    currentLevel.textbox.parentCurrent = null;
                    currentLevel.textbox.lock = false;
                    currentLevel.textbox.geometry.x = level.textbox.geometry.x + textGeo.x;
                    currentLevel.textbox.geometry.y = level.textbox.geometry.y + textGeo.y;
                    currentLevel.textbox.rotation.angle = textdocument.rotation.angle ? textdocument.rotation.angle : 0;

                    vm.pageData[vm.pageNumber].levels.level.push(currentLevel);
                    elementInit(currentLevel.seq-1,vm.pageNumber,currentLevel.textbox,'textbox');
                    $timeout(function () {
                        // vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio)) + Number(5));
                        // vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio);

                        vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                        vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                        // ModulesService.getFontCSS(vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox);
                    })
                }
                $timeout(function () {
                    cookie();
                },500)
            })

        }

        /***********************  背景  ****************************/
        $scope.chooseBgColor = function(color){
            vm.pageData[vm.pageNumber].background.type = "Color";
            vm.pageData[vm.pageNumber].background.color = color;
            vm.pageData[vm.pageNumber].background.resource.identifier = "";
            vm.pageData[vm.pageNumber].background.offsetx = 0;
            vm.pageData[vm.pageNumber].background.offsety = 0;
            cookie();
        }
        $scope.chooseBgColor1 = function(color){
            vm.pageData[vm.pageNumber].background.type = "Color";
            vm.pageData[vm.pageNumber].background.color = "";
            vm.pageData[vm.pageNumber].background.resource.identifier = "";
            vm.pageData[vm.pageNumber].background.resource.provider = "";
            vm.pageData[vm.pageNumber].background.offsetx = 0;
            vm.pageData[vm.pageNumber].background.offsety = 0;
            cookie();
        }
        $scope.chooseColor = function () {
            $scope.chooseBgColor(vm.bgColor);
            vm.bgColor = null;
        }
        $scope.chooseBgimg = function(data){
            vm.pageData[vm.pageNumber].background.type = "Pic";
            vm.pageData[vm.pageNumber].background.resource.identifier = data.identifier;
            vm.pageData[vm.pageNumber].background.resource.provider = 'qiniu';
            vm.pageData[vm.pageNumber].background.offsetx = 0;
            vm.pageData[vm.pageNumber].background.offsety = 0;
            bgImg(data);
        };
        function bgImg(data){
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = data.identifier;
            newImg.onload = function () {
                var bgImgW = vm.pageData[vm.pageNumber].trimbox.width * vm.pageData[vm.pageNumber].custom.ratio;
                var bgImgH = vm.pageData[vm.pageNumber].trimbox.height * vm.pageData[vm.pageNumber].custom.ratio;
                var bgImgR = bgImgW/bgImgH;
                var bgImgR1 = newImg.width/newImg.height;
                vm.pageData[vm.pageNumber].background.width = newImg.width;
                vm.pageData[vm.pageNumber].background.height = newImg.height;
                if(bgImgR >= bgImgR1){
                    vm.pageData[vm.pageNumber].background.resource.adaptation = 'Width';
                    vm.pageData[vm.pageNumber].background.scale = 1;
                }else {
                    vm.pageData[vm.pageNumber].background.resource.adaptation = 'Height';
                    vm.pageData[vm.pageNumber].background.scale = 1;
                }
                $scope.$digest();
                cookie();
            };
        }

        /***********************  左侧菜单  ****************************/
        vm.nav = "picture";
        vm.navSelect = navSelect;
        function navSelect(item) {
            vm.nav = item;
            vm.leftShow = true;
            $scope.editorBodyView();
            if(item == 'rahmen'){
                classifyInit('Material')
            }
            if(item == 'material'){
                classifyInit('ClipArt')
            }
            if(item == 'text'){
                classifyInit('FONT')
            }
            if(item == 'bgImg'){
                classifyInit('Background')
            }
            if(item == 'template'){
                templateInit()
            }
            if(item == 'border'){
                classifyInit('BORDER')
            }
            if(item == 'qrcode'){
                clearCode();
            }
            if(item == 'reserve'){
                vm.partid = vm.pageData[vm.pageNumber].config.partid;
            }
        }
        /***********************  照片  ****************************/
        vm.folderShow = false;
        vm.indexNum = 0;
        vm.maxId = null;
        vm.minId = null;
        vm.totalPhotoItems = null;
        vm.itemsPerPage = 40;
        $scope.photos = [];
        $scope.moveGalleryIds = [];
        vm.picLoading = false;
        vm.deleteSure = false;
        vm.folderIds = "";
        vm.folderId = "";
        vm.photosTab='all';
        function loadFolders() {
            var articleId = vm.article ? (vm.article.articleId || vm.article.id) : vm.articleId;
            Folder.storeGetAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"},{key: "articleId", op: "=", value: articleId}],
                sort: ["id,asc"]
            }, function (resp) {
                if(resp.status == 200){
                    $scope.Folders = resp.data;
                }else {
                    MessageService.error(resp.message);
                }
            })
        }
        $scope.folderOn = function () {
            vm.folderShow = !vm.folderShow;
        };
        // 增加文件夹
        $scope.addFolder = function () {
            $uibModal.open({
                templateUrl: "app/entities/page/pagesmySuffix-create.html",
                controller: "PageMySuffixCreateController",
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: [function() {
                        return {
                            type: "NEW"
                        };
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (backData) {
                Folder.save({
                    name: backData,
                    type: 'Gallery',
                    articleId:vm.article.id
                }, function (resp) {
                    $scope.Folders.push(resp.data);
                })
            });
        }

        $scope.updateFloders = function(folder,event){
            event.stopPropagation();
            $uibModal.open({
                templateUrl: 'app/entities/page/pagesmySuffix-create.html',
                controller: 'PageMySuffixCreateController',
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            type: "EDITOR",
                            name: folder.name
                        };
                    }]
                }
            }).result.then(function (data) {
                folder.name = data;
                Folder.update(folder, function (resp) {
                    if(resp.status == 200){
                        loadFolders();
                        vm.indexNum = 0;
                        vm.maxId = null;
                        vm.minId = null;
                        vm.totalPhotoItems = null;
                        vm.galleryPage = [];
                        $scope.photos = [];
                        loadAll('isNull');
                        MessageService.success($translate.instant("editorMessage.modifySuccess"));
                    }else {
                        MessageService.error(resp.message);
                    }
                },function () {
                    swal.showInputError($translate.instant("editorMessage.pleaseEnterTheCorrectFileName"));
                })
            }, function(){
            });
        }
        //选择文件夹
        $scope.showFolderContent = function (folder) {
            vm.indexNum = 0;
            vm.unusedQuantity = null;
            vm.oldQuantity = null;
            vm.maxId = null;
            vm.minId = null;
            vm.totalPhotoItems = null;
            $scope.photos = [];
            $scope.gallaryPic = [];
            vm.folderId = folder ? folder.id : '';
            vm.folderName = folder ? folder.name : '';
            if (folder) {
                loadAll(folder.id);
            } else {
                loadAll('isNull');
            }

        };
        $scope.deleteShow = function (ids,event) {
            event.stopPropagation();
            vm.folderIds=ids;
            vm.deleteSure = true;
        }
        $scope.photoCb = function () {
            loadAll('isNull');
        }

        vm.photoSort = '1';
        $scope.photoSortChange = function (num) {
            vm.indexNum = 0;
            vm.maxId = null;
            vm.minId = null;
            vm.totalPhotoItems = null;
            vm.photoSort = num;
            $scope.photos = [];
            if(vm.folderId){
                loadAll(vm.folderId);
            }else{
                loadAll('isNull');
            }
        }
        $('#navId').on('scroll', function(event){
            if(vm.nav=='picture'){
                var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                if($scope.photos.length < vm.totalPhotoItems && !vm.picLoading){
                    if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                        if(vm.folderId){
                            loadAll(vm.folderId);
                        } else {
                            loadAll('isNull');
                        }
                    }
                }
            }
        });
        function loadAll(folderId) {
            vm.picLoading = true;
            //$scope.gallaryPic = [];
            //$scope.moveGalleryIds = [];
            var sort = ["id,desc"];
            if(vm.photoSort == 1){
                sort = ["id,desc"]
            }else if(vm.photoSort == 2){
                sort = ["name,desc"]
            }else if(vm.photoSort == 3){
                sort = ["name,asc"]
            }
            var items = [];
            var articleId = vm.article ? (vm.article.articleId || vm.article.id) : vm.articleId;
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: ''})
            }else{
                items.push({key: "folderId", op: "=",value: folderId});
            }
            items.push({key: "articleId", op: "=", value: articleId})
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            if (vm.minId) {
                items.push({key: "id", op: "<",value: vm.minId});
            }
            if (folderId == 'isNull') {
                $scope.selectFolder = '已传照片';
                Gallery.storeByCondition({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            } else {
                Gallery.storeByCondition({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            }
        }
        function onSuccess(data, headers) {
            if (vm.totalPhotoItems == null) {
                vm.totalPhotoItems = Number(headers('X-Total-Count'));
                console.log(vm.totalPhotoItems,'第一次请求');
            }
            if (vm.maxId == null && vm.photoSort == 1 && data.data.length > 0) {
                vm.maxId = data.data[0].id;
            }
            vm.indexNum++;
            vm.picLoading = false;
            var len = $scope.photos.length;
            angular.forEach(data.data, function (item, i) {
                item.identifier = $scope.domain + item.identifier;
                item.select = false;
                item.index = i + len;
                item.usenum = 0;
                item.type = 'picture';
                $scope.photos.push(item);
            });
            photosNum();
            if($scope.photos.length < vm.totalPhotoItems){
                vm.isPhotoFinish = false;
            }else {
                vm.isPhotoFinish = true;
            }
            if($scope.photos.length > 0){
                newCreateDate = new Date($scope.photos[0].createdDate).getTime();
            }else {
                newCreateDate = new Date().getTime();
            }
        }

        function onError(error) {
            vm.picLoading = false;
            MessageService.error($translate.instant("editorMessage.failedToObtainThePhotoGallery"));
        }
        //删除多个文件夹
        $scope.deleteFolders = function () {
            vm.deleteSure = false;
            Folder.batchDelete({ids: vm.folderIds}, function () {
                vm.indexNum = 0;
                vm.maxId = null;
                vm.minId = null;
                vm.totalPhotoItems = null;
                $scope.photos = [];
                loadAll('isNull');
                loadFolders();
                MessageService.success($translate.instant("editorMessage.deleteSuccess"));
            }, function () {
                MessageService.error($translate.instant("editorMessage.deleteError"));
            });
        };
        $scope.choosePhoto = function (photo) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            photo.select = !photo.select;
            angular.forEach($scope.photos, function (p, i) {
                if (p.select) {
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                }
            });
        }
        $scope.switchBgTab = function (attr) {
            vm.photosTab = attr;
            if(vm.photosTab!='all'){
                vm.unUsedImageboxsNum = 0;
                angular.forEach($scope.photos,function (p) {
                    if(p.usenum==0){
                        vm.unUsedImageboxsNum++;
                    }
                })
                getGallery();
                $scope.whetherChoose(false);
            }
        }
        $scope.whetherChoose = function (attr) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.photos.some(function (p, i) {
                if (attr) {
                    p.select = true;
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                } else {
                    p.select = false;
                    $scope.gallaryPic = [];      //选择导入图片的数组
                    $scope.moveGalleryIds = [];
                }
            })
        }
        $scope.deleteBatchPhotos = function () {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectAPhoto"));
                return
            }
            angular.forEach($scope.moveGalleryIds, function(id){
                angular.forEach($scope.template.pages.page, function (item, index) {
                    angular.forEach(item.levels.level, function (level) {
                        if (level.imagebox && level.imagebox.image && level.imagebox.image.resourceid == id) {
                            var box = level.imagebox
                            box.blurredrectangle = {
                                x: 0,
                                y: 0,
                                width: box.geometry.width,
                                height: box.geometry.height
                            }
                            box.imgUrl = "content/images/mask_souce.png";
                            box.image = {
                                resource: {
                                    identifier:"",
                                    provider: ''
                                },
                                filterResource: {
                                    identifier: '',
                                    provider: ''
                                },
                                offsetx: 0,
                                offsety: 0,
                                width: '',
                                height: '',
                                imgsize: '',
                                index: '',
                                parameter: [],
                                scale: 1,
                                angle: 0,
                            }
                        }
                    })
                });
            });
            Gallery.batchDelete($scope.moveGalleryIds, function () {
                MessageService.success($translate.instant("editorMessage.deleteSuccess"));
                // 若被选中的pic被删除，就重新生成该数组
                // vm.totalPhotoItems = vm.totalPhotoItems - $scope.moveGalleryIds.length
                vm.unusedQuantity = null;
                vm.oldQuantity = null;
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                $scope.photos = [];
                //没有数据，页码置为0，重新调接口查询
                vm.indexNum = 0;
                vm.maxId = null;
                vm.minId = null;
                vm.totalPhotoItems = null;
                if (vm.folderId) {
                    loadAll(vm.folderId);
                } else {
                    loadAll('isNull');
                }
                magnitudeBox();
            }, function (error) {
                MessageService.error($translate.instant("editorMessage.deleteError"));
            });
        }
        //一键填充
        $scope.directFill= function(){
            vm.sumIndex = 0;
            if($scope.gallaryPic.length < 1){
                return
            }
            if($scope.gallaryPic.length < vm.counts-vm.current){
                vm.isDirectFill = true;
                return
            }
            directFill1()
        };
        $scope.fillPage = function(){
            directFill1()
        }
        function directFill1(){
            vm.isDirectFill = false;
            if(vm.isLayout && ($scope.gallaryPic.length > vm.counts-vm.current && vm.picLayoutXmlsList.length>0) && (vm.article.editorType == 'BOOK_JUNIOR' || vm.article.editorType == 'BOOK_SENIOR' || vm.article.editorType == 'NEW_EDITOR' || vm.article.editorType == 'CALENDAR_EDITOR')){
                angular.forEach(vm.pageData, function (page) {page.isFill = false;})
                directFillCb1($scope.gallaryPic)
            }else{
                angular.forEach(vm.pageData, function (page) {
                    setLevelImgeboxImg(page, $scope.gallaryPic);
                })
                photosNum();
                imageSelectInit();
                magnitudeBox();
                onArticleChange();
                thumbnailInit();
                $scope.whetherChoose(false);
            }
        }
        //随机布局
        $scope.stochastic = function () {
            vm.sumIndex = 0;
            vm.galleriesAll = [];
            angular.forEach(vm.pageData, function (page) {
                page.isFill = false;
                angular.forEach(page.levels.level, function (level, i) {
                    if (level.imagebox) {
                        if (level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                            vm.galleriesAll.push({
                                imageInfo: level.imagebox.image.imageInfo,
                                height: level.imagebox.image.height,
                                id: level.imagebox.image.resourceid,
                                identifier: level.imagebox.image.resource.identifier,
                                imgsize: level.imagebox.image.imgsize,
                                name: null,
                                provider: "qiniu",
                                width: level.imagebox.image.width
                            })
                            level.imagebox.image.imageInfo = null;
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.resource.identifier = null;
                            level.imagebox.image.imgsize = null;
                            level.imagebox.image.height = null;
                            level.imagebox.image.width = null;
                        }
                    }
                })
            })
            directFillCb1(vm.galleriesAll)
        }
        function directFillCb1(gallaryPic){
            directFillCb(true, gallaryPic);
            var notPageCounts=0;
            var pages=[];
            angular.forEach(vm.pageData, function (page, index) {
                page.uuid1 = getUuid();
                if(!page.isFill){
                    notPageCounts++;
                    pages.push(page)
                }
            });
            if(pages.length == 0){
                MessageService.error($translate.instant("editorMessage.fillWith"));
                photosNum();
                imageSelectInit();
                magnitudeBox();
                $scope.whetherChoose(false);
                return;
            }
            randomPlacement(Convert,Algorithm, pages,vm.picLayoutXmlsList,gallaryPic, vm.sumIndex, function (pages, notLike) {
                if(notLike){
                    MessageService.error($translate.instant("editorMessage.layoutNotFound"));
                }
                angular.forEach(pages, function (page) {
                    page.isFill = true;
                    setLevelImgeboxImg(page, gallaryPic);
                })
                photosNum();
                imageSelectInit();
                magnitudeBox();
                $scope.whetherChoose(false);
            })
        }
        function directFillCb(item, gallaryPic){
            angular.forEach(vm.pageData, function (page, index) {
                if(item == page.unreplace){
                    page.isFill = true;
                    setLevelImgeboxImg(page, gallaryPic);
                }else{
                    var _isNotImg = false;
                    var _isNotImg1 = false;
                    angular.forEach(page.levels.level, function (level, i) {
                        if(level.imagebox){
                            if(level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                _isNotImg = true;
                            }else{
                                _isNotImg1 = true;
                            }
                        }
                    });
                    if(_isNotImg && _isNotImg1){
                        page.isFill = true;
                        setLevelImgeboxImg(page, gallaryPic);
                    }
                    if(_isNotImg && !_isNotImg1){
                        page.isFill = true;
                    }
                }
            })
        }
        function setLevelImgeboxImg(page, gallaryPic){
            angular.forEach(page.levels.level, function (level, i) {
                if(level.imagebox){
                    if(!level.imagebox.image){
                        level.imagebox.image= {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            scale: 1,
                            angle: 0
                        };
                    }
                    if(!level.imagebox.image.resource){
                        level.imagebox.image.resource = {
                            identifier:''
                        }
                    }
                    if(!level.imagebox.image.resource.identifier){
                        var _imagebox = angular.copy(gallaryPic[vm.sumIndex]);
                        if(!_imagebox){
                            return false
                        }
                        vm.sumIndex++
                        level.imagebox.image.resourceid = _imagebox ? _imagebox.id : '';
                        level.imagebox.image.resource.identifier = _imagebox ? _imagebox.identifier : '';
                        level.imagebox.image.resource.provider = _imagebox ? _imagebox.provider : 'qiniu';
                        level.imagebox.image.width = _imagebox ? _imagebox.width : '';
                        level.imagebox.image.height = _imagebox ? _imagebox.height : '';
                        level.imagebox.image.imageInfo = _imagebox ? _imagebox.imageInfo : '';
                        level.imagebox.image.imgSize = _imagebox ? _imagebox.imgSize : '';
                        level.imagebox.image.index = _imagebox ? _imagebox.seq : '';
                        level.imagebox.image.parameter = _imagebox ? angular.fromJson(_imagebox.parameter) : [];
                        if(level.imagebox.image.resource.identifier){
                            addImagebox($http, level.imagebox, function () {})
                        }
                    }
                }
            })
        }

        vm.photosNum = photosNum;
        function photosNum(){
            for(var i=0; i<$scope.photos.length; i++){
                var _photo = $scope.photos[i];
                _photo.usenum=0;
                angular.forEach(vm.pageData, function (page, index) {
                    angular.forEach(page.levels.level, function (level, i) {
                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                            if (level.imagebox.image.resourceid == _photo.sourceId) {
                                _photo.usenum++
                            }
                        }
                    })
                })
            }
            if(vm.photosTab!='all'){
                $scope.switchBgTab();
            }
            recommendInit($scope.template);
            unusedQuantity();
        }

        $scope.uploadPic = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    $("#uploadImg2").trigger("click");
                }else{
                    if(res.status == 400){MessageService.error('系统出错');}
                    else {
                        MessageService.error(res.data||'系统出错');
                    }
                }
            })
        }
        var bigSizePics = [];
        window.updatePic = function ($event) {
            vm.successNumber = 0;//上传成功数量
            vm.errorNumber = 0;//上传失败数量
            var oldlength = $event.target.files.length;
            var filterFilesList= filterFiles($event.target.files);
            var newlength = filterFilesList.length;
            if (newlength < oldlength) {
                MessageService.error($translate.instant("editorMessage.updatePicError"));
            }
            $timeout(function () {
                vm.picLoading = true;
                var len = $scope.photos.length;
                var files = [];
                var higherSize = [];
                bigSizePics = [];
                // vm.totalPhotoItems = (vm.totalPhotoItems - 0) + (filterFilesList.length-0)
                for (var i = 0; i < filterFilesList.length; i++) {
                    var f = filterFilesList[i];
                    f.imageSize = Math.ceil(f.size/1024/1024);
                    files.push(f)
                }
                if (files.length == 0) {
                    vm.picLoading = false;
                    return
                }
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (file.size>=20*1024*1024 ){
                        file.imageSize= Math.ceil(file.size/1024/1024);
                        bigSizePics.push(file);
                    }else{
                        getBgImg(files, i, len);
                        if (bigSizePics.length==0 && i == (files.length-1)) {
                            // vm.picLoading = false;
                            setTimeout(function () {
                                angular.forEach($scope.photos, function (item, j) {
                                    item.index = j;
                                });
                            }, 400)
                        }
                    }
                }
                if (bigSizePics.length >0) {
                    thumbnailatorPic();
                }
                $event.srcElement.value = ""
            })
        };

        var uploadFailedList = []; //临时存储上传失败图片
        function getBgImg(files, i, len) {
            CommonService.UpdateQiniuService(files, i, len, $scope.domain, vm.token, vm.folderId,(vm.article.articleId || vm.article.id),function (resp) {
                vm.successNumber++;
                if (vm.successNumber + vm.errorNumber >= files.length) {
                    vm.picLoading = false;
                    document.getElementById('uploadImg2').value = '';//上传完清空输入框
                    if (vm.errorNumber > 0) {
                        vm.errorAlertPopup = true;
                    }
                };
            },function(errorFile,executionCompleted){
                uploadFailedList.push(errorFile);
                vm.errorNumber++;
                if (vm.successNumber + vm.errorNumber >= files.length) {
                    $timeout(function(){
                        vm.picLoading = false;
                        vm.uploadFailedList = uploadFailedList;
                        uploadFailedList = [];
                        vm.errorAlertPopup = true;
                        document.getElementById('uploadImg2').value = '';//上传完清空输入框
                        $scope.$apply();
                    })
                };
            })
        }

        $scope.errorAlertPopupClose = function(){
            vm.errorAlertPopup = false;
            vm.uploadFailedList = [];
        }

        $scope.errorPhotoView = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page-compontents/uploadFailedImageListPopup.html',
                controller: 'UploadFailedImageListPopupController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return vm.uploadFailedList;
                    }]
                }
            }).result.then(function (flag) {
                if (flag) {
                    var files = vm.uploadFailedList;
                    for (var i = 0; i < files.length; i++) {
                        getBgImg(files, i);
                    }
                    vm.successNumber = 0;//上传成功数量
                    vm.errorNumber = 0;//上传失败数量
                    vm.picLoading = true;
                    vm.errorAlertPopup = false;
                    vm.uploadFailedList = [];
                }else{
                    vm.errorAlertPopup = false;
                    vm.uploadFailedList = [];
                }
            });
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page-compontents/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            files: bigSizePics,
                            aid:(vm.article.articleId || vm.article.id),
                            folderId:vm.folderId,
                            domain:$scope.domain,
                            token:vm.token
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics=[];
                if(values){
                    for (var j = 0; j < values.length; j++) {
                        var value = values[j];
                        $scope.photos.unshift(value);
                    }
                    vm.picLoading = false;
                    setTimeout(function () {
                        angular.forEach($scope.photos, function (item, z) {
                            item.index = z;
                        });
                    }, 400)
                }
            }, function(){
                vm.picLoading = false;
            });
        }

        //转移照片
        $scope.moveToFolder = function () {
            if ($scope.gallaryPic.length < 1) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectAPhoto"));
                return
            }
            vm.isMoveToFolder = true;
        }
        $scope.chooseFolder = function (folder, index) {
            vm.selectFolderId = null;
            folder.selected = !folder.selected;
            angular.forEach($scope.Folders, function (f, i) {
                if (index != i) {
                    f.selected = false;
                }
            })
            if (folder.selected) {
                vm.selectFolderId = folder.id;
            }
        }

        $scope.chooseFolder2 = function(){
            angular.forEach($scope.Folders, function (f, i) {
                f.selected = false;
            })
            vm.selectFolderId = -1;
        }
        $scope.saveMoveToFolder = function () {
            if (vm.selectFolderId == null) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectAFolder"));
                return
            }
            $scope.moveGalleryIds = [];
            angular.forEach($scope.gallaryPic, function (p, i) {
                $scope.moveGalleryIds.push(p.id);
            })

            Gallery.moveFolder({
                galleries: $scope.moveGalleryIds,
                folderId: vm.selectFolderId === -1 ? null : vm.selectFolderId
            }, function () {
                angular.forEach($scope.Folders, function (f, i) {
                    f.selected = false;
                })
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                vm.unusedQuantity = null;
                vm.oldQuantity = null;
                vm.indexNum = 0;
                vm.maxId = null;
                vm.minId = null;
                vm.totalPhotoItems = null;
                $scope.photos = [];
                vm.isMoveToFolder = false;
                if (vm.folderId) {
                    loadAll(vm.folderId);
                } else {
                    loadAll('isNull');
                }
            })
        }

        //手机传图
        vm.qrcodeShow = false;
        vm.fid = 0;
        var qrcode = null;
        var newCreateDate = 0;
        $scope.uploadByPhone = function () {
            uploadAble(StoreStorages,vm.storeId, function (res) {
                if(res.status == 200){
                    vm.qrcodeShow = !vm.qrcodeShow;
                    if (qrcode != null) {
                        // qrcode.clear();
                        $('#qrcode1').html("");
                    }

                    //vm.folderId -----某一文件夹信息
                    if (vm.folderId == undefined || vm.folderId == null) {
                        vm.fid = 0;
                    } else {
                        vm.fid = vm.folderId;
                    }

                    if (vm.article.ticket) {
                        qrcode = $('#qrcode1').qrcode({
                                render: "canvas",
                                width: 150,
                                height: 150,
                                text: '' + vm.platformSetting.editorNotifyUrl + "/" + vm.storeId + "/" + vm.fid + "/gallery/" + (vm.article.articleId || vm.article.id)+'?ticket='+vm.article.ticket
                            });
                    }else{
                        Article.getPhonePicToken({id:(vm.article.articleId || vm.article.id)}, function(data){
                            // 手机传图二维码
                            qrcode = $('#qrcode1').qrcode({
                                render: "canvas",
                                width: 150,
                                height: 150,
                                text: '' + vm.platformSetting.editorNotifyUrl + "/" + vm.storeId + "/" + vm.fid + "/gallery/" + (vm.article.articleId || vm.article.id)+'?auth='+data.data
                            });
                        },function(){
                    })
                    }
                }else{
                    if(res.status == 400){MessageService.error('系统出错');}
                    else {
                        MessageService.error(res.data||'系统出错');
                    }
                }
            })
        }

        function onSuccessToPhone(data) {
            if (data.data == null || data.data.length == 0) {
                return;
            }
            var loadCreateDate = new Date(data.data[0].createdDate).getTime();
            for (var i = 0; i < data.data.length; i++) {
                var d = data.data[i];
                if(vm.temp.indexOf(d.id)<0){
                    if (new Date(d.createdDate).getTime() > newCreateDate) {
                        d.identifier = $scope.domain + d.identifier;
                        d.select = false;
                        d.index = i;
                        d.name2 = ModulesService.splitFileName(d.name);
                        $scope.photos.unshift(d);
                        vm.totalPhotoItems++
                    } else {
                        newCreateDate = loadCreateDate;
                        break;
                    }
                }
            }
            if (newCreateDate == 0) {
                newCreateDate = loadCreateDate;
            }
        }
        $scope.uploadByPhone1 = function(){
            vm.qrcodeShow = false;
            $scope.photoSortChange(vm.photoSort)
            // clearInterval(phonetimer);
        }
        /***********************  相框  ****************************/
        vm.rahmenPage = 0;
        vm.rahmenInit = rahmenInit;
        vm.masklabelsFulfill = false;
        $scope.showMaskLabels = false;
        vm.maskLoading = false;
        vm.masklabelCb = masklabelCb;
        vm.maskList = [];
        $scope.showMoreMaskLabels = function () {
            $scope.showMaskLabels = !$scope.showMaskLabels;
        }
        function rahmenInit(item) {
            vm.maskLoading = true;
            vm.masklabelChooseId = item;
            if(item.id){
                Material.byCondition3({
                    items: [
                        {key: "themeClassify.id", op: "=", value: item.id}],
                    page: vm.rahmenPage,
                    size: vm.itemsPerPage,
                    sort: ["seq,asc"]
                }, function (res, headers) {
                    var _sum = headers('X-Total-Count');
                    angular.forEach(res.data, function (resp) {
                        var arr = {
                            type:'masking',
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider,
                            }
                        }
                        vm.maskList.push(arr);
                    })
                    vm.maskLoading = false;
                    if(vm.maskList.length < _sum){
                        vm.masklabelsFulfill = true;
                    }else {
                        vm.masklabelsFulfill = false;
                    }
                }, function () {
                    vm.maskLoading = false;
                })
            }else{
                $scope.template.masks.mask.forEach(function (mask) {
                    var arr = {
                        type:'masking',
                        name: "",
                        identifier: mask.resource.identifier,
                        provider: "",
                        themeClassifyName: $translate.instant("button.recommend"),
                        themeClassifyId: "",
                        resource: {
                            identifier: mask.resource.identifier,
                            provider: mask.resource.provider,
                        }
                    }
                    vm.maskList.push(arr);
                })
                vm.maskLoading = false;
            }
        }
        function masklabelCb(item){
            vm.maskList = [];
            vm.rahmenPage = 0;
            rahmenInit(item)
        }
        $scope.loadMoreMasks = function () {
            vm.rahmenPage++;
            rahmenInit(vm.masklabelChooseId)
        }


        /***********************  素材  ****************************/
        vm.clipArtsList = [];
        vm.clipArtsPage = 0;
        vm.clipArtLoading = false;
        vm.clipArtChooseId = "";
        vm.clipArtCb = clipArtCb;
        function clipArtInit(item) {
            if(!item){return}
            vm.clipArtChooseId = item;
            if(item.id){
                vm.clipArtLoading = true;
                ClipArt.byCondition3({
                    items: [
                        {key: "themeClassify.id", op: "=", value: item.id}],
                    page: vm.clipArtsPage,
                    size: vm.itemsPerPage,
                    sort: ["seq,asc"]
                }, function (res, headers) {
                    var _sum = headers('X-Total-Count');
                    angular.forEach(res.data, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider,
                            }
                        }
                        vm.clipArtsList.push(arr);
                    })
                    vm.clipArtLoading = false;
                    if(vm.clipArtsList.length < _sum){
                        vm.clipArtFulfill = true;
                    }else {
                        vm.clipArtFulfill = false;
                    }
                }, function () {
                    vm.clipArtLoading = false;
                })
            }else{
                $scope.template.cliparts.clipart.forEach(function (clipart) {
                    var arr = {
                        name: "",
                        identifier: clipart.resource.identifier,
                        provider: "",
                        themeClassifyName: $translate.instant("button.recommend"),
                        themeClassifyId: "",
                        resource: clipart.resource
                    }
                    vm.clipArtsList.push(arr);
                })
                vm.clipArtLoading = false;
            }
        }
        function clipArtCb(item){
            vm.clipArtsList = [];
            vm.clipArtsPage = 0;
            clipArtInit(item)
        }
        $scope.loadMoreClipArt = function () {
            vm.clipArtsPage++;
            clipArtInit(vm.clipArtChooseId)
        }
        /***********************  文字  ****************************/
        vm.fontList = [];
        vm.fontPage = 0;
        vm.fontLoading = false;
        vm.fontChooseId = "";
        $scope.showfontdocLabels = false;
        vm.fontlabelCb = fontlabelCb;
        function fontInit(item) {
            if(!item){
                return
            }
            vm.fontLoading = true;
            vm.fontChooseId = item;
            FontXmlsManger.byCondition({
                items: [
                    {key: "themeClassify.id", op: "=", value: item.id}],
                page: vm.fontPage,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                var _sum = headers('X-Total-Count');
                vm.fontList = res.data;
                vm.fontLoading = false;
                if(vm.fontList.length < _sum){
                    vm.fontFulfill = true;
                }else {
                    vm.fontFulfill = false;
                }
            }, function () {
                vm.fontLoading = false;
            })
        }
        function fontlabelCb(item){
            vm.fontList = [];
            vm.fontPage = 0;
            fontInit(item)
        }
        // $scope.loadMoreClipArt = function () {
        //     vm.fontPage++;
        //     fontInit(vm.fontChooseId)
        // }
        $scope.showMorefontdocLabels = function () {
            $scope.showfontdocLabels = !$scope.showfontdocLabels;
        }

        $scope.editFont = function () {
            vm.editFailedFont = false;
            // $scope.doubleClicktext(vm.failedFontArr.e,vm.failedFontArr.textbox)
        }
        /***********************  背景  ****************************/
        vm.colorArr1 = ['#ffffff', '#666666', '#7babaf', '#e69594', '#93a9da', '#eac8fb'];
        vm.colorArr2 = ['#000000', '#666666', '#a8a8a8', '#d9d9d9', '#ffffff', '#647c6e', '#ff5b5c', '#ffbd4a', '#fef952', '#99e264', '#36b72a', '#44d9e5', '#c27171', '#2fb3ff', '#b760a6'];

        vm.bgImgList = [];
        vm.bgImgPage = 0;
        vm.bgImgChooseId = "";
        $scope.showbgImgdocLabels = false;
        vm.bgImglabelCb = bgImglabelCb;
        function bgImgInit(item) {
            if(!item){
                return
            }
            vm.bgImgChooseId = item;
            if(item.id){
                Backgrounds.byCondition3({
                    items: [
                        {key: "themeClassify.id", op: "=", value: item.id}],
                    page: vm.bgImgPage,
                    size: vm.itemsPerPage,
                    sort: ["seq,asc"]
                }, function (res, headers) {
                    angular.forEach(res.data, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider,
                            }
                        }
                        vm.bgImgList.push(arr);
                    })
                    var _sum = headers('X-Total-Count');
                    if(vm.bgImgList.length < _sum){
                        vm.bgImgFulfill = true;
                    }else {
                        vm.bgImgFulfill = false;
                    }
                }, function () {
                })
            }else{
                $scope.template.bgimgs2.bgimg.forEach(function (bgimg) {
                    var arr = {
                        name: "",
                        identifier: bgimg.identifier,
                        provider: "",
                        themeClassifyName: $translate.instant("button.recommend"),
                        themeClassifyId: "",
                        resource: {
                            identifier: bgimg.identifier,
                            provider: bgimg.provider,
                        }
                    }
                    vm.bgImgList.push(arr);
                })
                vm.bgImgFulfill = false;
            }
        }
        function bgImglabelCb(item){
            vm.bgImgList = [];
            vm.bgImgPage = 0;
            bgImgInit(item)
        }
        $scope.loadMoreBgImg = function () {
            vm.bgImgPage++;
            bgImgInit(vm.bgImgChooseId)
        }
        $scope.showMorebgImgdocLabels = function () {
            $scope.showbgImgdocLabels = !$scope.showbgImgdocLabels;
        }
        /***********************  换书  ****************************/
        vm.tempCategories = [];
        vm.tempList = {};
        function templateInit() {
            vm.tempCategories = [];
            Product.get({id: productId, cancelled: false}, function (res) {
                vm.product = res.data;
                vm.productTitle = res.data.name;
                // vm.article.productBokeh = resp.data.productBokeh;
                // vm.article.productBokehType = resp.data.productBokehType;
                // if (!resp.data.productBokeh) {
                //     vm.article.bokehtype = 'None';
                // } else {
                //     vm.article.bokehtype = resp.data.productBokehType;
                // }
                vm.article.productBokeh = res.data.bokehType;
                vm.article.productBokehType = res.data.bokehType;
                if (!res.data.bokehType) {
                    vm.article.bokehtype = 'None';
                } else {
                    vm.article.bokehtype = res.data.bokehType;
                }


                for (var i = 0; i < res.data.itemValues.length; i++) {
                    var itemValue = res.data.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.id == vm.article.itemValueId && itemValue.productXmlLines && itemValue.productXmlLines.length>0) {
                        vm.tempList = angular.copy(itemValue);
                    }
                }
                if(vm.tempList && vm.tempList.productXmlLines){
                    angular.forEach(vm.tempList.productXmlLines, function (line, i) {
                        if (line.themeClassifies && !line.cancelled && line.paid) {
                            line.themeClassifies.forEach(function (themeClassifie) {
                                if (vm.tempCategories.indexOf(themeClassifie.name) == -1 && themeClassifie.name != null) {
                                    vm.tempCategories.push(themeClassifie.name);
                                }
                            })
                        }
                    })
                    if (vm.tempCategories.length) {
                        $scope.selectCategory(vm.tempCategories[0])
                    }
                }
            })
        }
        $scope.showCategories = false;
        $scope.showMoreCategories = function () {
            $scope.showCategories = !$scope.showCategories;
        }
        $scope.selectCategory = function (c) {
            $scope.categoryNow = {
                label: c
            };
        };
        $scope.productXmlShow = function(themeClassifies){
            var _exist = false;
            themeClassifies.forEach(function (themeClassifie) {
                if ($scope.categoryNow.label ==  themeClassifie.name) {
                    _exist = true;
                }
            })
            return _exist
        }
        //预览
        $scope.tempPreview = function (temp) {
            ModulesService.pagePreview(temp,false);
        };
        //分类
        function classifyInit(item){
            ThemeClassify.getAllByCondition4({
                type: item,
                storeId: vm.storeId
            }, function (res) {
                if(item == 'Material'){
                    vm.masklabels = [];
                    if($scope.template.masks && $scope.template.masks.mask && $scope.template.masks.mask.length>0){
                        vm.masklabels.push({
                            id: "",
                            name: $translate.instant("button.recommend"),
                            seq: 0,
                            type: "MASK",
                            used: true,
                        });
                    }
                    vm.masklabels = vm.masklabels.concat(res.data);
                    vm.rahmenPage = 0;
                    vm.maskList = [];
                    rahmenInit(vm.masklabels[0])
                }
                if(item == 'ClipArt'){
                    vm.clipArts = [];
                    if($scope.template.cliparts && $scope.template.cliparts.clipart && $scope.template.cliparts.clipart.length>0){
                        vm.clipArts = [{
                            id: "",
                            name: $translate.instant("button.recommend"),
                            seq: 0,
                            type: "CLIPART",
                            used: true
                        }]
                    }
                    res.data.forEach(function (item) {
                        if(item.storeId){
                            vm.clipArts.push(item)
                        }
                    })
                    res.data.forEach(function (item) {
                        if(!item.storeId){
                            vm.clipArts.push(item)
                        }
                    })
                    vm.clipArtsPage = 0;
                    vm.clipArtsList = [];
                    clipArtInit(vm.clipArts[0]);
                }
                if(item == 'FONT'){
                    vm.fontlabels = res.data;
                    vm.fontlabelPage = 0;
                    vm.fontlabelList = [];
                    fontInit(res.data[0])
                }
                if(item == 'Background'){
                    vm.bgImglabels = [];
                    if($scope.template.bgimgs2 && $scope.template.bgimgs2.bgimg && $scope.template.bgimgs2.bgimg.length>0){
                        vm.bgImglabels = [{
                            id: "",
                            name: $translate.instant("button.recommend"),
                            seq: 0,
                            type: "Background",
                            used: true
                        }]
                    }
                    res.data.forEach(function (item) {
                        vm.bgImglabels.push(item)
                    })

                    vm.bgImgPage = 0;
                    vm.bgImgList = [];
                    bgImgInit(vm.bgImglabels[0])
                }
                if(item == 'BORDER'){
                    vm.borderLabels = res.data;
                    if($scope.template.frameConfigs && $scope.template.frameConfigs.frameConfig.length>0){
                        vm.borderLabels.unshift({
                            cancelled: false,
                            id: null,
                            name: $translate.instant("button.recommend"),
                            seq: '',
                            storeId: 10,
                            type: "BORDER",
                            used: true
                        })
                    }
                    vm.borderPage = 0;
                    vm.borderList = [];
                    borderInit(vm.borderLabels[0])
                }
            });
        }

        /***********************  边框  ****************************/
        vm.borderUnfold = false;
        $scope.bordersCb = function(label, index){
            vm.borderId = label;
            vm.borderPage = 0;
            vm.borderList = [];
            borderInit(vm.borderId);
        }
        $scope.loadMoreBorder = function () {
            vm.borderPage++;
            borderInit(vm.borderId);
        }
        function borderInit(item){
            vm.borderId = item;
            if(!vm.borderId){
                angular.forEach($scope.template.frameConfigs.frameConfig, function (data) {
                    vm.borderList.push(data);
                })
                return
            }
            vm.borderLoading = true;
            FrameConfigs.byCondition3({
                items: [{key: "themeClassify.id", op: "=", value: item.id},{key: "enable", op: "=", value: true},{key: "status", op: "=", value: 'Approved'}],
                page: vm.borderPage,
                size: vm.itemsPerPage,
                sort: ["seq,asc"]
            }, function (res, headers) {
                vm.borderLoading = false;
                angular.forEach(res.data, function (resp) {
                    vm.borderList.push(resp);
                })
                var _sum = headers('X-Total-Count');
                if(vm.borderList.length < _sum){
                    vm.borderFulfill = true;
                }else {
                    vm.borderFulfill = false;
                }
            }, function () {
            })
        }
        /***********************  版式  ****************************/
        $scope.partCb = function(item){
            vm.partid = item.id;
        }
        $scope.layoutCb = function(item){
            if(vm.pageData[vm.pageNumber].config.partid!=item.config.partid){
                MessageService.error("不是相同部件的版式不可替换");
                return
            }
            if(item.uuid != vm.pageData[vm.pageNumber].uuid){
                vm.threeLoading = true;
                vm.threeLoadingText = $translate.instant("editorCommon.loadingText1");
                item.config = vm.pageData[vm.pageNumber].config;
                var pages = {
                    config:$scope.template.config,
                    pages:{
                        page: [angular.copy(item)]
                    }
                }
                documentAnalysis($scope, $http, vm, pages,FontManagement,'other', function(page){
                    ratioInfo(page,vm.pageNumber2 > -1?false:true)
                }, function () {
                    $timeout(function () {
                        var layoutPage = pages.pages.page[0];
                        layoutPage.backup = false;
                        layoutPage.unreplace = false;
                        pageDataInfo(layoutPage)
                    },200)
                })
            }
        }
        function pageDataInfo(page) {
            vm.threeLoading = true;
            vm.minLoading = true;
            vm.threeLoadingText = $translate.instant("editorCommon.loadingText1");
            page.pageUuid = getUuid();
            var _pageData = angular.copy(vm.pageData[vm.pageNumber]);
            var _pageDataImageboxImage = [];
            var _pageDataImageboxImageSum = 0;
            for(var i=0; i<_pageData.levels.level.length; i++){
                var _lecel = _pageData.levels.level[i];
                if(_lecel.imagebox && _lecel.imagebox.image && _lecel.imagebox.image.resource && _lecel.imagebox.image.resource.identifier){
                    _lecel.imagebox.imgRatio = _lecel.imagebox.image.width/_lecel.imagebox.image.height;
                    _pageDataImageboxImage.push(_lecel.imagebox)
                }
            }
            if(!page.levels.level){
                page.levels.level=[];
            }
            _pageDataImageboxImage = imageboxAdapt(page.levels.level, _pageDataImageboxImage);
            angular.forEach(page.levels.level, function (level, i) {
                if (level.imagebox) {
                    var _imagebox = null;
                    for(var j=0; j<_pageDataImageboxImage.length; j++){
                        if(_pageDataImageboxImage[j].imageboxIndex == _pageDataImageboxImageSum){
                            _imagebox = _pageDataImageboxImage[j];
                            break
                        }
                    }
                    _pageDataImageboxImageSum++;
                    if (_imagebox) {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            blurredrectangle: {
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0
                            },
                            resource: {
                                identifier: _imagebox ? _imagebox.image.resource.identifier : '',
                                provider: _imagebox ? _imagebox.image.resource.provider : 'qiniu'
                            },
                            resourceid:_imagebox ? _imagebox.image.resourceid : '',
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            angle: 0,
                            offsetx: 0,
                            offsety: 0,
                            imageInfo: _imagebox? _imagebox.image.imageInfo : '',
                            width: _imagebox ? _imagebox.image.width : '',
                            height: _imagebox ? _imagebox.image.height : '',
                            imgSize: _imagebox ? _imagebox.image.imgSize : '',
                            index: _imagebox ? _imagebox.image.seq : '',
                            parameter: _imagebox ? angular.fromJson(_imagebox.image.parameter) : [],
                            scale: 1,
                            brightness:_imagebox ?_imagebox.image.brightness : 0,
                            contrast: _imagebox ?_imagebox.image.contrast : 0,
                            saturate: _imagebox ?_imagebox.image.saturate : 0,
                            temperature: _imagebox ?_imagebox.image.temperature : 0,
                            hue: _imagebox ?_imagebox.image.hue : 0
                        }
                        if (level.imagebox.image.resource.identifier) {

                            addImagebox($http, level.imagebox, function () {})
                            // getBackgroundSource3(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtype, $scope)
                        }
                    } else {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            resourceid:"",
                            filterResource: {
                                identifier: '',
                                provider: ''
                            },
                            width: '',
                            height: '',
                            imgSize: '',
                            offsetx: 0,
                            offsety: 0,
                            index: '',
                            parameter: [],
                            scale: 1,
                            angle: 0,
                        }
                    }
                }
            })
            vm.isCut = true;
            $timeout(function () {
                vm.pageData[vm.pageNumber] = page;
                vm.pageData.forEach(function (value, index) {
                    value.seq = index;
                    ratioInfo(value,vm.pageNumber2 > -1?false:true);
                })
                MessageService.success($translate.instant("editorMessage.formatReplacementSucceeded"));
                reserveQuantity();
                magnitudeBox();
                vm.isCut = false;
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                $timeout(function () {
                    vm.minLoading = false;
                },500)
            },200)
        }
        //换一换
        $scope.changePage = function(index){
            vm.isChangePage1 = false;
            $scope.pageInfo(index);
            var _recommendLayout = [];
            vm.pageData[vm.pageNumber].recommendLayout.forEach(function (recommendLayoutItem) {
                if(recommendLayoutItem.uuid != vm.pageData[vm.pageNumber].uuid){
                    _recommendLayout.push(recommendLayoutItem)
                }
            })
            if(_recommendLayout.length > 0){
                var x = _recommendLayout.length-1;
                var y = 0;
                var rand = parseInt(Math.random() * (x - y + 1) + y);
                $scope.layoutCb(_recommendLayout[rand])
            }
        }
        vm.isChangePage1 = false;
        $scope.changePage1 = function(e){
            vm.isChangePage1 = !vm.isChangePage1;
            fn(e)
        }
        /***********************  版式end  ****************************/
        /***********************  二维码  ****************************/
        function clearCode(){
            vm.code={
                width: '',
                height: '',
                value: null
            }
            vm.addQrcode = true;
            vm.editorQrcode = false;
        }
        $scope.addQrcode = function () {
            if(!vm.code.value){
                MessageService.error($translate.instant("editorMessage.pleaseEnterTheContent"));
                return
            }
            if(!vm.code.width){
                MessageService.error($translate.instant("editorMessage.pleaseEnterTheWidth"));
                return
            }
            if(!vm.code.height){
                MessageService.error($translate.instant("editorMessage.pleaseEnterTheHeight"));
                return
            }
            if(vm.code.width<10){
                MessageService.error($translate.instant("editorMessage.codeWidth"));
                return
            }
            if(vm.code.height<10){
                MessageService.error($translate.instant("editorMessage.codeHeight"));
                return
            }
            var w = transitionPx(angular.copy(vm.code.width));
            var h = transitionPx(angular.copy(vm.code.height));
            if(vm.elementOn.name == 'code'){
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].code.geometry.width = w;
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].code.geometry.height = h;
            }else{
                var code = ({
                    value:vm.code.value,
                    geometry: {
                        width: w,
                        height: h,
                        x: 0,
                        y: 0
                    },
                    rotation: {
                        angle: 0
                    },
                    lock: false
                });
                code.geometry.x = (vm.pageData[vm.pageNumber].mediabox.width - w)/2;
                code.geometry.y = (vm.pageData[vm.pageNumber].mediabox.height - h)/2;
                var _level ={
                    seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                    code: code
                }
                vm.pageData[vm.pageNumber].levels.level.push(_level);
            }
        };
        /***********************  二维码end  ****************************/
        /***********************  布局库  ****************************/
        $scope.selectLayoutLabel = function (label, index) {
            vm.picLayoutXmlGroup = label;
        };
        function setLayout(){
            if(vm.picLayoutXmlsList && vm.picLayoutXmlsList.length>0 && !vm.pageData[vm.pageNumber].unreplace){
                var _imageboxNum = 0;
                vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                    if(level.imagebox){
                        _imageboxNum++
                    }
                })
                for(var i=0; i<vm.picLayoutXmlsList.length; i++){
                    if(vm.picLayoutXmlsList[i].quantity == _imageboxNum){
                        if(vm.picLayoutXmlsList[i].data && vm.picLayoutXmlsList[i].data.length>0){
                            $scope.addLayout(vm.picLayoutXmlsList[i].data[0]);
                        }
                        break
                    }
                }
            }
        }
        $scope.addLayout = function(data,isLayout){
            if(vm.pageData[vm.pageNumber].unreplace){
                MessageService.error($translate.instant("editorMessage.theCurrentPageIsNotReplaceableLayout"));
                return
            }
            if(!vm.isLayout){
                if(isLayout){
                    MessageService.error($translate.instant("editorMessage.theCurrentPageIsNotReplaceableLayout1"));
                }
                return;
            }
            vm.threeLoading = true;
            vm.threeLoadingText = $translate.instant("editorCommon.loadingText");
            Convert.xmlToPicLayoutDocument({url: data.resource.identifier}, function (res) {
                var mediabox = ModulesService.getGeometryToPx(res.mediabox);
                var _pageData = angular.copy(vm.pageData[vm.pageNumber]);
                var _pageDataImageboxImage = [];
                var _pageDataImageboxImageSum = 0;
                for(var i=0; i<_pageData.levels.level.length; i++){
                    var _lecel = _pageData.levels.level[i];
                    if(_lecel.imagebox && _lecel.imagebox.image && _lecel.imagebox.image.resource && _lecel.imagebox.image.resource.identifier){
                        _lecel.imagebox.imgRatio = _lecel.imagebox.image.width/_lecel.imagebox.image.height;
                        _pageDataImageboxImage.push(_lecel.imagebox)
                    }
                }

                var _ratio = 1;
                if(_pageData.mediabox.height / mediabox.height >= _pageData.mediabox.width / mediabox.width ){
                    _ratio = _pageData.mediabox.width / mediabox.width;
                }else {
                    _ratio = _pageData.mediabox.height / mediabox.height;
                }

                if(!_pageData.levels.level){
                    _pageData.levels.level=[];
                }
                for(var i=_pageData.levels.level.length-1; i>=0; i--){
                    if(_pageData.levels.level[i].imagebox){
                        _pageData.levels.level.splice(i,1)
                    }
                }
                res.levels.level.forEach(function (level) {
                    if(level.imagebox){
                        level.imagebox.geometry={
                            width: transitionPx(level.imagebox.geometry.width*_ratio),
                            height: transitionPx(level.imagebox.geometry.height*_ratio),
                            x: transitionPx(level.imagebox.geometry.x*_ratio),
                            y: transitionPx(level.imagebox.geometry.y*_ratio),
                        }
                        level.seq = _pageData.levels.level.length-1;
                        _pageData.levels.level.push(level)
                    }
                })
                _pageDataImageboxImage = imageboxAdapt(_pageData.levels.level, _pageDataImageboxImage);
                angular.forEach(_pageData.levels.level, function (level, i) {
                    if (level.imagebox) {
                        var _imagebox = null;
                        for(var j=0; j<_pageDataImageboxImage.length; j++){
                            if(_pageDataImageboxImage[j].imageboxIndex == _pageDataImageboxImageSum){
                                _imagebox = _pageDataImageboxImage[j];
                                break
                            }
                        }
                        _pageDataImageboxImageSum++;
                        if (_imagebox) {
                            level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                            level.imagebox.image = {
                                blurredrectangle: {
                                    width: 0,
                                    height: 0,
                                    x: 0,
                                    y: 0
                                },
                                resource: {
                                    identifier: _imagebox ? _imagebox.image.resource.identifier : '',
                                    provider: _imagebox ? _imagebox.image.resource.provider : 'qiniu'
                                },
                                resourceid:_imagebox ? _imagebox.image.resourceid : '',
                                filterResource: {
                                    identifier: '',
                                    provider: 'qiniu'
                                },
                                angle: 0,
                                offsetx: 0,
                                offsety: 0,
                                imageInfo: _imagebox? _imagebox.image.imageInfo : '',
                                width: _imagebox ? _imagebox.image.width : '',
                                height: _imagebox ? _imagebox.image.height : '',
                                imgSize: _imagebox ? _imagebox.image.imgSize : '',
                                index: _imagebox ? _imagebox.image.seq : '',
                                parameter: _imagebox ? angular.fromJson(_imagebox.image.parameter) : [],
                                scale: 1,
                                brightness:_imagebox ?_imagebox.image.brightness : 0,
                                contrast: _imagebox ?_imagebox.image.contrast : 0,
                                saturate: _imagebox ?_imagebox.image.saturate : 0,
                                temperature: _imagebox ?_imagebox.image.temperature : 0,
                                hue: _imagebox ?_imagebox.image.hue : 0
                            }
                            if (level.imagebox.image.resource.identifier) {

                                addImagebox($http, level.imagebox, function () {})
                                // getBackgroundSource3(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtype, $scope)
                            }
                        } else {
                            level.imagebox.image = {
                                resource: {
                                    identifier: '',
                                    provider: ''
                                },
                                resourceid:"",
                                filterResource: {
                                    identifier: '',
                                    provider: ''
                                },
                                width: '',
                                height: '',
                                imgSize: '',
                                offsetx: 0,
                                offsety: 0,
                                index: '',
                                parameter: [],
                                scale: 1,
                                angle: 0,
                            }
                        }
                    }
                })
                $timeout(function () {
                    vm.pageData[vm.pageNumber] = _pageData;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    MessageService.success($translate.instant("editorMessage.layoutReplacementSucceeded"));
                    magnitudeBox();
                },200)
            })
        }
        /***********************  布局库end  ****************************/
        /***********************  可变数据  ****************************/
        $scope.downloadFile = function(){
            $http({
                url: 'fileserves/api/convert/document/downTemp',
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = $translate.instant("editorCommon.variableDataTemplate") + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});
        }


        $scope.uploadFile = function(fileWatched){
            Upload.upload({
                type: 'POST',
                url: 'fileserves/api/convert/document/importData', //上传路径
                data: {
                    file: fileWatched,
                    name: fileWatched.name,
                }
            }).then(function successCallback(res) {
                if (res.status && res.status == 200) {
                    vm.variableData = res.data;
                    vm.variableName = fileWatched.name;
                }
            }, function errorCallback(response) {
                MessageService.error($translate.instant("editorMessage.importingATemplateFails"));
            });
        }

        //转为位组合
        $scope.transformGroup = function(){
            var uuid = UidService.get();
            for(var i=0;i<vm.pageData[vm.pageNumber].levels.level.length;i++) {
                var level = vm.pageData[vm.pageNumber].levels.level[i];
                if(level.imagebox && level.imagebox.ctrlKey&&level.imagebox.value){
                    level.group = true;
                    level.guid = uuid;
                }
                if(level.textbox && level.textbox.ctrlKey&&level.textbox.value){
                    level.group = true;
                    level.guid = uuid;
                }
            }
        }
        //查看全部数据
        $scope.watchVariable = function(){
            $uibModal.open({
                templateUrl: 'app/entities/page-compontents/variable-data.html',
                controller: 'VariableDataController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            data: vm.variableData
                        };
                    }]
                }
            }).result.then(function (data) {
                // var box2 = angular.copy(box);
                if(data){
                    //从当前页：this  ;   从第一页：one
                    var index = 0;
                    if(data.range=='this'){
                        index = vm.pageNumber;
                    }

                    for (var i = index; i < vm.pageData.length; i++) {
                        var page = vm.pageData[i];
                        // var arr = {
                        //     seq: page.arr.levels.level.length - 0 + 1,
                        //     imagebox: null,
                        //     textbox: null,
                        //     textdocument: null,
                        //     imageart:null,
                        //     code: null
                        // }
                        // arr[box.current] = box2;
                        // arr[box.current].pageI = page.arr.seq - 1;
                        // arr[box.current].levelI = page.arr.levels.level.length;
                        //
                        // //从第一页开始,全部增加
                        // if(data.generLoc == 'all'){
                        //     // 判断左右页
                        //     if(i == $scope.pageI&&$scope.arrI&&page.arr2&&$scope.arrI!=2){
                        //         setBox(arr,box,page)
                        //     }
                        //     if(i!= $scope.pageI){
                        //         var arr2 = angular.copy(arr)
                        //         page.arr.levels.level.push(arr2);
                        //         if(page.arr2){
                        //             setBox(arr,box,page)
                        //         }
                        //     }
                        // }else if(data.generLoc=='next'){
                        //     var boxSeq = $scope.template.pages[$scope.pageI].arr.seq;
                        //     if($scope.arrI == 2){
                        //         boxSeq = $scope.template.pages[$scope.pageI].arr2.seq;
                        //     }
                        //
                        //     if(i != $scope.pageI){
                        //         if(page.arr.seq % 2 == boxSeq % 2){
                        //             var arr2 = angular.copy(arr)
                        //             page.arr.levels.level.push(arr2);
                        //         }
                        //         if(page.arr2.seq % 2 == boxSeq % 2){
                        //             setBox(arr,box,page)
                        //         }
                        //     }
                        // }
                    }

                    console.log($scope.template.pages)
                }
            });
        }
        /**
         * index 从第N页开始生成
         * x1 共有几个组合
         * maxlen 可生成N页
         */
        $scope.generVariable = function (e) {
            fn(e)
            if(!vm.variableData) { return }
            console.log(vm.variableData);
            var variables = angular.copy(vm.variableData)
            var index = vm.pageNumber;
            var pages = $scope.template.pages.page;
            var page = pages[index];
            var levels = angular.copy(page.levels.level);
            var a1 = {}, x1 = 0, pageLen = pages.length;
            // 封底不参与生成
            if(pages[pages.length-1].type=='BackCover'){
                pageLen--
            }
            for (var j1 = 0; j1 < levels.length; j1++) {
                if(levels[j1].guid){
                    if (!a1[levels[j1].guid]) {
                        a1[levels[j1].guid] = [levels[j1]];
                        x1++;
                    } else {
                        a1[levels[j1].guid].push(levels[j1]);
                    }
                }
            }

            if(x1==0){
                MessageService.error($translate.instant("editorMessage.combinationOfTransformation"));
                return;
            }
            var maxlen = index+Math.ceil(variables.values.value.length/x1);

            if(maxlen > pageLen){
                maxlen = pageLen
            }
            for (var i = index; i < maxlen; i++) {
                var uuid = angular.copy(pages[i].uuid);
                var type = angular.copy(pages[i].type);
                pages[i] = angular.copy(page);
                pages[i].uuid = uuid;
                pages[i].type = type;
                pages[i].seq = i+1;

                var a = {}, x = 0;
                var levels = pages[i].levels.level;
                for (var j = 0; j < levels.length; j++) {
                    if(levels[j].guid){
                        if (!a[levels[j].guid]) {
                            a[levels[j].guid] = [levels[j]];
                            x++;
                        } else {
                            a[levels[j].guid].push(levels[j]);
                        }
                    }
                }
                Object.keys(a).forEach(function(key,ii,v){
                    angular.forEach(a[key],function (level) {
                        //占位框
                        if(level.imagebox&&level.imagebox.geometry&&level.imagebox.value){
                            // 取得占位框中的value
                            var value = level.imagebox.value.replace(/#/g, '');
                            // 根据value 拿到可变数据中对应的内容
                            var _index = variables.titles.title.findIndex(function (t) {
                                return t.value == value
                            })

                            //根据筛选到的可变数据，从照片库中获取对应的照片
                            var varValue = null,dataIndex=null;
                            if(variables.values.value[ii]&&variables.values.value[ii].values){
                                varValue = variables.values.value[ii].values;
                                dataIndex = $scope.photos.findIndex(function (p) {
                                    return ModulesService.splitFileName(p.name) == varValue[_index].value
                                })
                            }

                            // 生成照片到占位框
                            if(dataIndex!=null&&dataIndex>=0){
                                $scope.picArr = angular.copy($scope.photos);
                                level.imagebox.image= {
                                    resource: {
                                        identifier: $scope.picArr[dataIndex].identifier,
                                            provider: 'qiniu'
                                    },
                                    filterResource: {
                                        identifier: '',
                                            provider: 'qiniu'
                                    },
                                    resourceid:$scope.picArr[dataIndex].sourceId,
                                    width: $scope.picArr[dataIndex].width,
                                    height: $scope.picArr[dataIndex].height,
                                    imageInfo: $scope.picArr[dataIndex].imageInfo,
                                    imgSize: $scope.picArr[dataIndex].imgSize,
                                    index: $scope.picArr[dataIndex].seq,
                                    parameter: angular.fromJson($scope.picArr[dataIndex].parameter),
                                    scale: 1,
                                    angle: 0,
                                };
                                addImagebox($http, level.imagebox, function () {});
                            }
                        }
                        //文本框
                        if(level.textbox&&level.textbox.geometry&&level.textbox.value){
                            var value = level.textbox.value.replace(/#/g, '');
                            // 根据value 拿到可变数据中对应的文字内容
                            var _index = variables.titles.title.findIndex(function (t) {
                                return t.value == value
                            });
                            //根据筛选到的可变数据，生成对应的文字
                            if(variables.values.value[ii]&&variables.values.value[ii].values){
                                var varValue = variables.values.value[ii].values;
                                level.textbox.text = varValue[_index].value;
                                vm.elementOn.item = level.textbox;
                                sizeHeight2(level.textbox);
                            }
                        }
                    })
                })
                // 可变数据比页数多时
                if(variables.values.value.length<x){
                    MessageService.success($translate.instant("editorMessage.completionOfGeneration",{number:(x-variables.values.value.length)}));
                }
                variables.values.value.splice(0,x);
            }
            photosNum();
        }

        vm.isTransformGroup = false;
        function variableNumber(){
            vm.isTransformGroup = false;
            var all = getAllCtrlKeyArray();
            if(all && all.length>1){
                vm.isTransformGroup = true;
                all.forEach(function (level) {
                    if(!level.value){
                        vm.isTransformGroup = false;
                    }
                })
            }
        }
        /***********************  可变数据end  ****************************/
        vm.fullFlag = false;
        //全屏
        $scope.toggleFullScreen = function(){
            vm.fullFlag = ModulesService.toggleFullScreen(vm.fullFlag);
        }
        //删除
        $scope.deleteBox = vm.deleteBox = function ($event) {
            if(vm.elementOn.name == ""||vm.elementOn.name == "calTitleBox"||vm.elementOn.name == "calMonthBox"){
                MessageService.error($translate.instant("editorMessage.pleaseSelectTheContentToDelete"));
                return
            }
            if(vm.elementOn.item.lock){
                return;
            }
            vm.pageData[vm.pageNumber].levels.level.splice(vm.elementOn.index, 1);
            if(vm.elementOn&&vm.elementOn.name == 'imagebox'){
                setLayout();
            }
            $scope.editorBodyView();
            magnitudeBox();
            cookie();
        }
        //复制
        $scope.copyimg = vm.copyimg = function ($event) {
            if(!vm.copyBtn){
                return;
            }
            var all = getAllCtrlKeyArray();
            if(!vm.clickNum){
                vm.clickNum = 0;
            }
            vm.clickNum+=10;
            if(all.length>0){
                all.forEach(function (item) {
                    (function (item1) {
                        var _level = {
                            seq: vm.pageData[vm.pageNumber].levels.level.length + 1,
                        }
                        _level[item1.current] = angular.copy(item1);
                        _level[item1.current].lock = false;
                        _level[item1.current].ctrlKey = false;
                        _level[item1.current].geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                        _level[item1.current].geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                        vm.pageData[vm.pageNumber].levels.level.push(_level);
                    })(item)
                })
            }else{
                if(!vm.elementOn.item){return}
                var _level = {
                    seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                }
                if(vm.elementOn.name == "imagebox"){
                    _level.imagebox = angular.copy(vm.elementOn.item);
                    _level.imagebox.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.imagebox.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(vm.elementOn.name == "imageart"){
                    _level.imageart = angular.copy(vm.elementOn.item);
                    _level.imageart.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.imageart.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(vm.elementOn.name == "textbox"){
                    _level.textbox = angular.copy(vm.elementOn.item);
                    _level.textbox.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.textbox.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(vm.elementOn.name == "textdocument"){
                    _level.textdocument = angular.copy(vm.elementOn.item);
                    _level.textdocument.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.textdocument.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(vm.elementOn.name == "calMonthBox"){
                    _level.calMonthBox = angular.copy(vm.elementOn.item);
                    _level.calMonthBox.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.calMonthBox.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(vm.elementOn.name == "calTitleBox"){
                    _level.calTitleBox = angular.copy(vm.elementOn.item);
                    _level.calTitleBox.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.calTitleBox.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                vm.pageData[vm.pageNumber].levels.level.push(_level);
            }
            magnitudeBox();
            cookie();
            photosNum();
        }
        vm.copyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: [], calTitleBoxs: [], calMonthBoxs: []};
        // 复制元素
        vm.copyElement = function(){
            if(vm.copyArray.imageboxes.length == 0 && vm.copyArray.imagearts.length == 0 && vm.copyArray.textboxes.length == 0){
                return;
            }
            copyTogether();
        }

        // 多选调用
        function copyTogether(){
            angular.forEach(vm.copyArray.imageboxes, function (imagebox, i) {
                console.log("i: "+i)
                if(imagebox&&imagebox.geometry){
                    // 当前页所有占位框
                    var box = angular.copy(imagebox);
                    var _level = {
                        seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                    }
                    _level.imagebox = angular.copy(copyDetail(box));
                    vm.pageData[vm.pageNumber].levels.level.push(_level)
                    for (var j = 0; j < $scope.photos.length; j++) {
                        var arr = $scope.photos[j];
                        if (arr.sourceId == box.image.resourceid) {
                            arr.usenum++;
                        }
                    }
                }

            })

            angular.forEach(vm.copyArray.imagearts, function (imageart, i) {
                if(imageart&&imageart.geometry){
                    // 当前页所有素材
                    var box = angular.copy(imageart);
                    var _level = {
                        seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                    }
                    _level.imageart = angular.copy(copyDetail(box));
                    vm.pageData[vm.pageNumber].levels.level.push(_level)
                }

            })

            angular.forEach(vm.copyArray.textboxes, function (textbox, i) {
                if(textbox&&textbox.geometry){
                    // 当前页所有文字
                    var box = angular.copy(textbox);
                    var _level = {
                        seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                    }
                    console.log(box)
                    _level.textbox = angular.copy(copyDetail(box));
                    vm.pageData[vm.pageNumber].levels.level.push(_level)
                }

            })
        }
        function copyDetail(box) {
            box.levelI = vm.pageData[vm.pageNumber].levels.level.length-1;
            box.lock = false;
            box.ctrlKey = false;
            var repeatEle = false;
            vm.pageData[vm.pageNumber].levels.level.forEach(function(item){
                if(item.imagebox && item.imagebox.geometry.x == box.geometry.x && item.imagebox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.imageart && item.imageart.geometry.x == box.geometry.x && item.imageart.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.textbox && item.textbox.geometry.x == box.geometry.x && item.textbox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.code && item.code.geometry.x == box.geometry.x && item.code.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
            })
            if(!repeatEle){
                return box
            }
            // 复制上一次的位置
            if (box.current == 'imagebox') {
                vm.copyArray.imageboxes.forEach(function(imagebox){
                    if(imagebox && imagebox.geometry.x == box.geometry.x && imagebox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imagebox.geometry.x) + 1;
                        var y = PxTurnMm(imagebox.geometry.y) + 1;
                        imagebox.geometry.x = MmTurnPx(x);
                        imagebox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if (box.current == 'imageart') {
                vm.copyArray.imagearts.forEach(function(imageart){
                    if(imageart && imageart.geometry.x == box.geometry.x && imageart.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imageart.geometry.x) + 1;
                        var y = PxTurnMm(imageart.geometry.y) + 1;
                        imageart.geometry.x = MmTurnPx(x);
                        imageart.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if(box.current == 'textbox'){
                vm.copyArray.textboxes.forEach(function(textbox){
                    if(textbox && textbox.geometry.x == box.geometry.x && textbox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(textbox.geometry.x) + 1;
                        var y = PxTurnMm(textbox.geometry.y) + 1;
                        textbox.geometry.x = MmTurnPx(x);
                        textbox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            // 新元素
            var x = PxTurnMm(box.geometry.x) + 1;
            var y = PxTurnMm(box.geometry.y) + 1;
            box.geometry.x = MmTurnPx(x);
            box.geometry.y = MmTurnPx(y);
            return box
        }
        //锁定
        $scope.locking = vm.locking = function(e){
            fn(e);
            if(vm.elementOn.item){
                vm.elementOn.item.lock = !vm.elementOn.item.lock;
                if(vm.elementOn.name === 'imagebox'){
                    vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.lock = vm.elementOn.item.lock;
                }
                multiSelect()
            }
        }
        //重复生成

        $scope.generBox = function(e){
            fn(e)
            if(!vm.elementOn.item){
                MessageService.error($translate.instant("editorMessage.pleaseChooseLevel"));
                return
            }
            if(vm.elementOn.name!='imagebox'&&vm.elementOn.name!='imageart'&&vm.elementOn.name!='textbox'&&vm.elementOn.name!='code'){
                MessageService.error($translate.instant("editorMessage.pleaseChooseLevel"));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/page-compontents/box-copy.html',
                controller: 'BoxCopyController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            box: vm.elementOn.item,
                            pageI:vm.pageNumber
                        };
                    }]
                }
            }).result.then(function (data) {
                if(data){
                    var all = getAllCtrlKeyArray();
                    if(all.length>0){
                        all.forEach(function (item) {
                            (function (item1) {
                                var _level = {
                                    seq: vm.pageData[vm.pageNumber].levels.level.length + 1,
                                }
                                _level[item1.current] = angular.copy(item1);
                                _level[item1.current].lock = false;
                                _level[item1.current].ctrlKey = false;
                                BoxCopyInit(_level,data)
                            })(item)
                        })
                    }else{
                        var _level = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index];
                        BoxCopyInit(_level,data)
                    }
                }
            });
        }
        function BoxCopyInit(level, data) {
            var _level = angular.copy(level);
            vm.pageData.forEach(function (page, index) {
                if(data.range == 'this'){
                    if(data.generLoc == 'all'){
                        if(vm.pageNumber<index){
                            _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                            vm.pageData[index].levels.level.push(angular.copy(_level))
                        }
                    }
                    if(data.generLoc == 'next'){
                        if(vm.pageNumber<index && index%2===vm.pageNumber%2){
                            _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                            vm.pageData[index].levels.level.push(angular.copy(_level))
                        }
                    }
                }
                if(data.range == 'first' && vm.pageNumber != index){
                    if(data.generLoc == 'all'){
                        _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                        vm.pageData[index].levels.level.push(angular.copy(_level))
                    }
                    if(data.generLoc == 'next'){
                        if(index%2===0%2){
                            _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                            vm.pageData[index].levels.level.push(angular.copy(_level))
                        }
                    }
                }
            })
            photosNum();
        }
        //图层
        vm.isCoverage = false;
        $scope.coverageMove = coverageMove;
        $scope.coverageSelect = function (attr) {
            if(vm.elementOn.item.lock){return}
            toolbarStatus()
            vm.isCoverage = attr;
        }
        function coverageMove(data){
            if(vm.elementOn.item.lock){return}
            var _seq = Number(vm.pageData[vm.pageNumber].levels.level.length);
            if(data == "up"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq == _seq){
                    MessageService.error($translate.instant("editorMessage.topLevel"));
                    return;
                }
                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq+1);
                vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                    if(level.seq == seq){
                        level.seq--
                    }
                })
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq++
            }
            if(data == "down"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq <2){
                    MessageService.error($translate.instant("editorMessage.bottomLevel"));
                    return;
                }
                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq-1);
                vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                    if(level.seq == seq){
                        level.seq++
                    }
                });
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq--
            }
            if(data == "top"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq == _seq){
                    MessageService.error($translate.instant("editorMessage.topLevel"));
                    return;
                }
                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq);
                for (var i = 0; i < vm.pageData[vm.pageNumber].levels.level.length; i++) {
                    if(vm.pageData[vm.pageNumber].levels.level[i].seq > seq){
                        vm.pageData[vm.pageNumber].levels.level[i].seq--;
                    }
                }
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq = _seq
            }
            if(data == "bottom"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq == 0){
                    MessageService.error($translate.instant("editorMessage.bottomLevel"));
                    return;
                }

                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq);
                for (var i = 0; i < vm.pageData[vm.pageNumber].levels.level.length; i++) {
                    if(vm.pageData[vm.pageNumber].levels.level[i].seq < seq){
                        vm.pageData[vm.pageNumber].levels.level[i].seq+=1;
                    }
                }
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq = 1;
            }

            var _seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq);
            var _levelI = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq - 1;
            vm.pageData[vm.pageNumber].levels.level.sort(compare('seq', true));
            for (var i = 0; i < vm.pageData[vm.pageNumber].levels.level.length; i++) {
                if(vm.pageData[vm.pageNumber].levels.level[i].seq == _seq){
                    _levelI = i;
                    break
                }
            }
            vm.elementOn.index = _levelI;
            cookie();
        }
        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }
        // 透明度
        vm.isTransp = false;
        $scope.transparencySelect = function () {
            var _isTransp = angular.copy(vm.isTransp);
            toolbarStatus()
            if(vm.elementOn.name !='imageart' && vm.elementOn.name !='textbox' && vm.elementOn.name !='imagebox' ){
                MessageService.error($translate.instant("editorMessage.pleaseChooseLevel1"));
                return
            }
            vm.isTransp = !_isTransp;
            if(vm.isTransp){
                var _transparency = 0;
                if(vm.elementOn.name =='imagebox'){
                    _transparency = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.transparency;
                }
                if(vm.elementOn.name =='imageart'){
                    _transparency = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imageart.transparency;
                }
                if(vm.elementOn.name =='textbox' ){
                    _transparency = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.transparency;
                }
                $(".transparency-range").val(_transparency);
                $(".transparency-num").val(_transparency * 100);
                vm.transparency = _transparency * 100;
            }
        }
        $timeout(function () {
            var elem = document.querySelector('input[type="range"].transparency-range');
            var rangeValue = function () {
                $(".transparency-num").val(elem.value * 100);
                vm.transparency = elem.value * 100;
                transparencyInit();
                $scope.$digest();
            };

            if(elem){
                elem.addEventListener("input", function () {
                    var value = ModulesService.rangeValue(elem);
                    $(".transparency-num").val(value * 100);
                    vm.transparency = value * 100;

                    var all = getAllCtrlKeyArray();
                    if(all.length>0){
                        levelMove(null, 'transparency')
                    }else{
                        transparencyInit();
                    }
                    $scope.$digest();
                });
            }
        },1000)

        $scope.transparencyChange = function () {
            if (vm.transparency > 100) {
                MessageService.error($translate.instant("editorMessage.transparencyInvalid"));
                vm.transparency = 100;
            }
            if (vm.transparency < 0) {
                MessageService.error($translate.instant("editorMessage.transparencyInvalid"));
                vm.transparency = 0;
            }
            transparencyInit(vm.transparency)
        }
        function transparencyInit() {
            if(vm.elementOn.name =='imagebox'){
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.transparency = vm.transparency/100;
                return
            }
            if(vm.elementOn.name =='imageart'){
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imageart.transparency = vm.transparency/100;
                return
            }
            if(vm.elementOn.name =='textbox' ){
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.transparency = vm.transparency/100;
                return
            }
        }

        //字体
        vm.isOperationColor = false;
        vm.colorList = [
            {name: '#000000', id: '#000000'},
            {name: '#666666', id: '#666666'},
            {name: '#a8a8a8', id: '#a8a8a8'},
            {name: '#d9d9d9', id: '#d9d9d9'},
            {name: '#ffffff', id: '#ffffff'},
            {name: '#647c6e', id: '#647c6e'},
            {name: '#ffbd4a', id: '#ffbd4a'},
            {name: '#fef952', id: '#fef952'},
            {name: '#99e264', id: '#99e264'},
            {name: '#36b72a', id: '#36b72a'},
            {name: '#44d9e5', id: '#44d9e5'},
            {name: '#c27171', id: '#c27171'},
            {name: '#2fb3ff', id: '#2fb3ff'},
            {name: '#b760a6', id: '#b760a6'},
        ];
        $scope.operationColorOpen = function ($event) {
            vm.isOperationColor = !vm.isOperationColor;
            fn($event)
        }
        $scope.operationColor = function (item) {
            vm.elementOn.item.style.color = item.id;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
        }
        $scope.chooseTextColor = function () {
            vm.elementOn.item.style.color = vm.textColor;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
            vm.textColor = null;
        }
        function fontSizeInit(size){
            var _size = 101;
            var _size1 = Math.floor(size/2);
            $scope.fontSizes = [];
            for (var f = 6; f < _size; f++) {
                $scope.fontSizes.push(f);
            }
            if(_size1 > _size){
                $scope.fontSizes.push(_size1);
            }
        }
        var a = null;
        $scope.updateTextSize = function (size) {
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function () {
                if (size <= 6) {
                    size = 6
                }
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.style.size = size;
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.style.lineheight = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.style.size;
                vm.elementOn.item = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
                $timeout(function () {
                    // vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio)) + Number(5));
                    // vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio) + Number(10);
                    if(vm.elementOn.item.geometry.width < ptToPx( vm.elementOn.item.style.size)){
                       vm.elementOn.item.geometry.width = ptToPx( vm.elementOn.item.style.size)+10;
                    }

                    sizeHeight();
                    // $scope.$digest();
                    cookie();
                })
            },500)
        }
        vm.isOperationSpacing = false;
        $scope.operationSpacing = function (item) {
            vm.isOperationSpacing = item;
            vm.isOperationDir = false;
            $timeout(function () {
                var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
                $(".block-width").css({left: text.style.letterspacing});
                $(".block-height").css({left: text.style.lineheight});
                $scope.template.letterspacing =text.style.letterspacing;
                $scope.template.lineheight = text.style.lineheight;
            })
        }
        //斜体
        $scope.operationItalic = function () {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            if (text) {
                if(text.style.italic){
                    if(vm.fontStyle.indexOf('NORMAL') > -1){
                        text.style.italic = false;
                        if (vm.fontStyle.indexOf('BOLD') == '-1') {
                            text.style.bold = false;
                        }
                    }else{
                        if(text.style.bold){
                            text.style.italic = false;
                        }
                    }
                }else{
                    if(vm.fontStyle.indexOf('ITALIC') > -1){
                        text.style.italic = true;
                        if(text.style.bold){
                            if(vm.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                text.style.bold = false;
                            }
                        }else{
                            // if( vm.fontStyle.indexOf('BOLD_ITALIC') > -1){
                            //     text.style.bold = true;
                            // }
                        }
                    }else if (vm.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                        text.style.bold = true;
                        text.style.italic = true;
                    }
                }
                $timeout(function () {
                    sizeHeight();
                },200)
            }
        }
        //粗体
        $scope.operationBold = function () {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            if (text) {
                if(text.style.bold){
                    if(vm.fontStyle.indexOf('NORMAL') > -1){
                        text.style.bold = !text.style.bold;
                        if (vm.fontStyle.indexOf('ITALIC') == '-1') {
                            text.style.italic = text.style.bold;
                        }
                    }else{
                        if(text.style.italic){
                            text.style.bold = !text.style.bold;
                        }
                    }
                }else{
                    if(vm.fontStyle.indexOf('BOLD') > -1){
                        text.style.bold = true;
                        if(text.style.italic){
                            if(vm.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                text.style.italic = false;
                            }
                        }else{
                            // if( vm.fontStyle.indexOf('BOLD_ITALIC') > -1){
                            //     text.style.italic = true;
                            // }
                        }
                    }else if (vm.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                        text.style.bold = true;
                        text.style.italic = true;
                    }
                }
                $timeout(function () {
                    sizeHeight();
                },200)
            }
        }

        // 字/行间距鼠标点击移动
        var spacingW = {};
        var flagSpacing = false
        window.resizeSpacingStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            spacingW.clientX = e.clientX || 0;
            spacingW.originLeft = targ.offsetLeft;
            spacingW.value = targ.dataset.value;
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            if (text.lock) {
                return
            }
            flagSpacing = true;
            document.addEventListener("mousemove", resizeSpacingOn, true);
            document.addEventListener("mouseup", resizeSpacingEnd, true);
        }

        function resizeSpacingOn(e) {
            if (flagSpacing) {
                var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
                var l = spacingW.originLeft + e.clientX - spacingW.clientX;
                if (spacingW.value == 'height' && l < text.style.size) {
                    l = text.style.size;
                } else if (spacingW.value == 'width' && l < 0) {
                    l = 0;
                }
                if (l > 110) {
                    l = 110
                }
                if (spacingW.value == 'width') {
                    $(".block-width").css({left: l});
                    text.style.letterspacing = $scope.template.letterspacing = l;
                } else {
                    $(".block-height").css({left: l});
                    text.style.lineheight = $scope.template.lineheight = l;
                }
                sizeHeight();
                // var h = Number($('.selectedBorder').find('.c-xml-page-textboxe-text').height());
                // text.geometry.height = h / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio) + Number(5);
                $scope.$digest();
            }
        }
        function resizeSpacingEnd() {
            if(flagSpacing){
                cookie();
            }
            spacingW = {}
            flagSpacing = false;
            document.removeEventListener("mousemove", resizeSpacingOn, true);
            document.removeEventListener("mouseup", resizeSpacingEnd, true);
        }
        vm.isOperationDir = false;
        $scope.operationDir = function (item) {
            vm.isOperationDir = item;
            vm.isOperationSpacing = false;
        }
        //左对齐
        $scope.operationBarDir = function (item) {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            text.style.align = item;
        }
        //横版
        $scope.textHengban = function () {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            var text1 = angular.copy(text);
            text.style.writingmode = 'horizontal-tb';
            text.geometry.width = text1.geometry.height;
            text.geometry.height = text1.geometry.width;
        }
        //竖版
        $scope.textShuban = function (item) {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            var text1 = angular.copy(text);
            var _writingmode = angular.copy(text.style.writingmode);
            text.style.writingmode = item;
            $timeout(function () {
                if(writingmodeInit(_writingmode) && writingmodeInit(text.style.writingmode)){
                }else{
                    text.geometry.width = text1.geometry.height;
                    text.geometry.height = text1.geometry.width;
                }
            },200)
        }

        //缓存每一步
        vm.cookie=cookie;
        function cookieInfo() {
            vm.cookies=[];
            var _data = angular.copy(vm.pageData[vm.pageNumber]);
            vm.cookies.push(_data);
            vm.cookieIndex = 0;
        }
        function cookie(){
            var _data = angular.copy(vm.pageData[vm.pageNumber]);
            vm.cookies.push(_data);
            vm.cookieIndex = vm.cookies.length - 1;
            onArticleChange();
            pageUpdate();
        }
        //上一步
        $scope.rollback = function(){
            if(vm.cookieIndex == 0){
                return
            }
            vm.cookieIndex--;
            vm.pageData[vm.pageNumber] = vm.cookies[vm.cookieIndex];
            vm.cookies.pop();
            if(vm.cookieIndex == 0){
                vm.cookies=[];
                var _data = angular.copy(vm.pageData[vm.pageNumber]);
                vm.cookies.push(_data);
            }
            magnitudeBox();
        };
        //恢复
        $scope.xmlNextStep = function(){
            vm.pageData[vm.pageNumber] = vm.cookies[0];
            vm.cookies=[];
            var _data = angular.copy(vm.pageData[vm.pageNumber]);
            vm.cookies.push(_data);
            vm.cookieIndex = 0;
            magnitudeBox();
        };

        //换书
        $scope.changeAllTemp = function ($event,line) {
            if(line.productXmlId == vm.article.productXmlId){
                MessageService.success("已是当前模板");
                return
            }
            MessageService.confirm({
                title:$translate.instant("editorCommon.replacementTemplate"),
                cancel:$translate.instant("button.cancel"),
                confirm:$translate.instant("button.confirm"),
                msg:$translate.instant("editorMessage.confirmReplace")
            },function(){
                templateReplace(line)
            })
        }
        function templateReplace(line) {
            if(vm.isEdit){
                vm.threeLoading = true;
                vm.pageData.forEach(function (page) {
                    if(page.levels.level){
                        page.levels.level.forEach(function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                vm.photoSelectAll.push({
                                    height: level.imagebox.image.resource.height,
                                    id: level.imagebox.image.resourceid,
                                    imageInfo: level.imagebox.image.imageInfo,
                                    identifier: level.imagebox.image.resource.identifier,
                                    imgsize: level.imagebox.image.imgsize,
                                    name: null,
                                    provider: "qiniu",
                                    usenum: 1,
                                    width: level.imagebox.image.resource.width
                                });
                            }
                        })
                    }
                })
                Convert.xmlToDocument2({
                    identifier: line.productXmlIdentifier,
                    provider: "qiniu",
                    editorType:vm.article.editorType,
                    patternType:vm.product.patternType,
                }, function (res) {
                    vm.pageSize = [];
                    res.pages.page.forEach(function(page){
                        if(page.levels && page.levels.level && page.levels.level.length>0){
                            page.levels.level.sort(compare('seq', true));
                        }
                        var o = {
                            width:page.mediabox.width,
                            height:page.mediabox.height
                        }
                        vm.pageSize.push(o);
                    })
                    angular.forEach(res.pages.page, function (page, i) {
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                            }
                        })
                    });
                    vm.editProductXml = true;
                    vm.isHtml2canvas = false;
                    vm.article.document = res;
                    vm.productXmlLine = angular.copy(line);
                    vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                    vm.article.modelBlendentUuid= vm.productXmlLine.modelBlendentUuid;
                    vm.article.cover= vm.productXmlLine.coverImg;
                    vm.article.themeClassifyId= vm.productXmlLine.productXmlThemeId;
                    vm.article.themeClassifyName= vm.productXmlLine.productXmlThemeName;
                    vm.article.productXmlId= vm.productXmlLine.productXmlId;
                    vm.article.productXmlName= vm.productXmlLine.productXmlName;
                    vm.article.productXmlLineId= vm.productXmlLine.id;
                    if(vm.productXmlLine && vm.productXmlLine.musicIdentify){
                        vm.article.musicIdentify= vm.productXmlLine.musicIdentify;
                    }
                    abook(res);
                });
            }else{
                $state.go("editorPhotoAlbum", {
                    storeId: vm.storeId,
                    pid: vm.pid,
                    vid: vm.vid,
                    xmlId: line.id
                });
                localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
            }
        }

        // 即时保存
        $scope.immediateSave = function (e) {
            articleOverdue(function () {
                $scope.template.pages.page = vm.pageData;
                localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
                $scope.isContinue();
                generateJsonFile(true);
            })
        }
        //保存
        $scope.isSave = false;

        $scope.saveClick = function(){
            generateJsonFile();
            localStorage.removeItem("photoSelectAll");
        }
        vm.isPageWarnExamine = false;
        $scope.save = function () {
            articleOverdue(function () {
                $scope.template.pages.page = vm.pageData;
                if($scope.isSave){
                    return;
                }
                if(!vm.isPageWarnExamine && pageWarnExamine($scope.template.pages.page, vm.imgSize, vm.imgDpi)){
                    MessageService.confirm({
                        title:$translate.instant("editorCommon.hint"),
                        cancel:$translate.instant("button.amend"),
                        confirm:$translate.instant("button.continueSave"),
                        msg:$translate.instant("editorMessage.lackPixelsMessage")
                    },function(){
                        vm.isPageWarnExamine = true;
                        $scope.save();
                    })
                    return
                }
                vm.isPageWarnExamine = false;

                if(vm.article.ordered){
                    SweetAlert.swal({
                        title: $translate.instant("editorMessage.notDelete"),
                        imageUrl: Theme.sweetHintImg(),
                        text: $translate.instant("editorMessage.newWork"),
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                //判断是否有图片未上传  且是否继续保存
                if (!$scope.isContinue()) {
                    vm.saveSure = true;
                } else {
                    generateJsonFile();
                    localStorage.removeItem("photoSelectAll");
                }
            })
        }
        function articleOverdue(callback){
            if($stateParams.aid){
                Article.get({id: $stateParams.aid}, function (article) {
                    if(article.data.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:$translate.instant("editorCommon.hint"),
                            cancel:$translate.instant("button.cancel"),
                            confirm:$translate.instant("button.refresh"),
                            msg:$translate.instant("hint.articleOverdue")
                        },function(){
                            $state.go("editorPhotoAlbumEdit", {storeId: vm.storeId, aid:  vm.article.id},{reload:true});
                        })
                    }else{
                        callback();
                    }
                })
            }
            if($stateParams.orderArticleId) {
                OrderArticle.getByUser({id: $stateParams.orderArticleId}, function (article) {
                    if(article.data.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:$translate.instant("editorCommon.hint"),
                            cancel:$translate.instant("button.cancel"),
                            confirm:$translate.instant("button.refresh"),
                            msg:$translate.instant("hint.articleOverdue")
                        },function(){
                            $state.go("editorPhotoAlbumEdit1", {storeId: vm.storeId, orderArticleId:  $stateParams.orderArticleId},{reload:true});
                        })
                    }else{
                        callback();
                    }
                })
            }
        }
        vm.documentDetection = {
            pass: true
        };
        vm.documentDetectionTab="text";
        function generateJsonFile(flag){
            vm.documentDetection = documentTextDetection($scope.template.pages.page);
            if(!vm.documentDetection.pass){
                if(vm.documentDetection.isText){
                    vm.documentDetectionTab="text";
                }else{
                    vm.documentDetectionTab="repetition";
                }
                return;
            }
            thumbnailInit1(function (){
                generateJsonFile1(flag)
            })
        }
        function generateJsonFile1(flag){
            $scope.isSave = true;
            var template = saveData();
            // if(vm.reservePage){
            //     vm.reservePage.forEach(function (page){
            //         template.pages.page.push(page);
            //     });
            // }
            template.hostinfo = hostInfo();
            vm.threeLoading = true;
            Convert.articleUploadDocument(template, function (res) {
                vm.article.articleStatus = vm.completed;
                vm.article.productType = 'CustomPrint';
                vm.article.fileName = res.data.identifier;
                vm.article.unFilledCount = vm.unFilledCount;
                vm.article.version = '4.0';
                vm.article.pageCount = vm.pageData.length;
                if($stateParams.aid){
                    Article.update(vm.article, function (resp) {
                        vm.article.fileName = resp.data.fileName;
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        if (flag) {
                            MessageService.success($translate.instant("editorMessage.saveSuccess"));
                            // localStorage.setItem('save-article',resp.id);
                        } else {
                            swal({
                                title: $translate.instant("editorMessage.modifySuccess"),
                                text: $translate.instant("editorMessage.synchronizationOfWorks"),
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            $(".wrap").show();
                            $(".navbar").show();
                            var screenW = document.body.clientWidth;
                            //web 端
                            if(screenW>1000){
                                $state.go("page-view", {storeId: vm.storeId, aid:  resp.data.id});
                            }else{
                                $state.go("product-view5-ec", {storeId: vm.storeId, aid:  resp.data.id});
                            }

                            // return
                            // $state.go("page-view", {storeId: vm.storeId, aid: resp.data.id}, {reload: true});

                        }
                    }, function () {
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        swal({
                            title: $translate.instant("editorMessage.saveError"),
                            text: $translate.instant("editorMessage.saveError1"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                }else if($stateParams.orderArticleId){
                    OrderArticle.update(vm.article, function (resp) {
                        vm.article.fileName = resp.data.fileName;
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        if (flag) {
                            MessageService.success($translate.instant("editorMessage.saveSuccess"));
                            // localStorage.setItem('save-article',resp.id);
                        } else {
                            swal({
                                title: $translate.instant("editorMessage.modifySuccess"),
                                text: $translate.instant("editorMessage.synchronizationOfWorks"),
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            $(".wrap").show();
                            $(".navbar").show();
                            var screenW = document.body.clientWidth;
                            //web 端
                            $state.go("page-view2", {storeId: vm.storeId, orderArticleId:  resp.data.id});

                        }
                    }, function () {
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        swal({
                            title: $translate.instant("editorMessage.saveError"),
                            text: $translate.instant("editorMessage.saveError1"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                }
            }, function () {
                $scope.isSave = false;
                vm.threeLoading = false;
                swal({
                    title: $translate.instant("editorMessage.saveError"),
                    text: $translate.instant("editorMessage.saveError1"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            })
        }
        $scope.isContinue = function () {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for(var i=0; i<$scope.template.pages.page.length; i++){
                var _page = $scope.template.pages.page[i];
                if(_page.levels.level){
                    for(var j=0; j<_page.levels.level.length; j++){
                        var _level = _page.levels.level[j];
                        if(_level.imagebox&&_level.imagebox.image){
                            if(_level.imagebox.image.resource&& _level.imagebox.image.resource.identifier){
                            }else {
                                hasEmpty = false;
                                vm.unFilledCount++
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty ? 'COMPLETED' : 'EDITING';
            return hasEmpty;
        };
        $scope.exitWork = function (e) {
            vm.exitSure = true;
        }
        $scope.exitPage = function () {
            localStorage.removeItem("photoSelectAll");
            if (vm.pid) {
                $(".wrap").show();
                $(".navbar").show();
                $state.go("commoditySelect", {
                    storeId: vm.storeId,
                    pid: vm.pid,
                    vid: vm.vid,
                    aid: vm.aid,
                }, {reload: true});
            } else {
                window.location.href = localStorage.getItem('returnurl');
            }
        }
        function saveData() {
            var _text = $translate.instant("editorCommon.doubleClickType");
            return documentMM($scope.template, _text);
        }

        //预览
        $scope.previewTab = function () {
            if(vm.productXmlData.modelType && vm.productXmlData.modelType === 'THREE'){
                if(!vm.productXmlData.modelPath){
                    MessageService.error($translate.instant("editorMessage.not3D"));
                    return
                }
                vm.threeView = true;
            }else {
                $scope.template.pages.page = vm.pageData;
                vm.previewArticle = saveData();
                vm.watchSure = true;
            }
        }



        //画布操作
        vm.moveCanvasView = false;
        $scope.ratioJia = function(){
            if(vm.pageData[vm.pageNumber1]){
                vm.pageData[vm.pageNumber1].custom.ratio2 += 2;
                if(vm.pageData[vm.pageNumber1].custom.ratio2 >300){
                    vm.pageData[vm.pageNumber1].custom.ratio2 = 300;
                }
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                vm.pageData[vm.pageNumber2].custom.ratio2 += 2;
                if(vm.pageData[vm.pageNumber2].custom.ratio2 >300){
                    vm.pageData[vm.pageNumber2].custom.ratio2 = 300;
                }
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        }
        $scope.ratioJian = function(){
            if(vm.pageData[vm.pageNumber1]){
                var ratio3 = vm.pageData[vm.pageNumber1].custom.ratio3 > 100 ? 100 : vm.pageData[vm.pageNumber1].custom.ratio3;
                vm.pageData[vm.pageNumber1].custom.ratio2 -= 2;
                if(vm.pageData[vm.pageNumber1].custom.ratio2 < ratio3){
                    vm.pageData[vm.pageNumber1].custom.ratio2 = ratio3;
                }
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                var ratio3 = vm.pageData[vm.pageNumber2].custom.ratio3 > 100 ? 100 : vm.pageData[vm.pageNumber2].custom.ratio3;
                vm.pageData[vm.pageNumber2].custom.ratio2 -= 2;
                if(vm.pageData[vm.pageNumber2].custom.ratio2 < ratio3){
                    vm.pageData[vm.pageNumber2].custom.ratio2 = ratio3;
                }
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        };
        $scope.ratioInit = function(){
            if(vm.pageData[vm.pageNumber1]){
                vm.pageData[vm.pageNumber1].custom.ratio2 = vm.pageData[vm.pageNumber1].custom.ratio3;
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                vm.pageData[vm.pageNumber2].custom.ratio2 = vm.pageData[vm.pageNumber2].custom.ratio3;
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        }
        $scope.ratioOriginal = function(){
            if(vm.pageData[vm.pageNumber1]){
                vm.pageData[vm.pageNumber1].custom.ratio2 = 100;
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                vm.pageData[vm.pageNumber2].custom.ratio2 = 100;
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        }
        $scope.moveCanvasCb = function(){
            vm.moveCanvasView = !vm.moveCanvasView;
        }
        // 保存滚轮事件处理函数的变量
        var mousewheelHandler = function(event){
            if (event.ctrlKey === true || event.metaKey) {
                var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : ( - event.detail / 3);
                if(wheelDelta>0){
                    $scope.ratioJia();
                }else{
                    $scope.ratioJian();
                }
                $scope.$apply();
                event.preventDefault();
            }
        }
        originalEventRatio();
        vm.textFont = false;
        function originalEventRatio(){
            window.addEventListener('mousewheel', mousewheelHandler,{ passive: false});

            document.onkeydown = function (event) {
                if (event.keyCode == 32 && !vm.moveCanvasView && !vm.textFont) {
                    $timeout(function () {
                        vm.moveCanvasView = true;
                    })
                    return false
                }
            }
            $(document).keyup(function(event){
                vm.moveCanvasView = false;
                $scope.$apply();
                return false
            });
        }
        //移动画布
        vm.moveCanvasObj = {
            x:0,
            y:0
        };
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView){
                return
            }
            var _moveCanvasObj = angular.copy(vm.moveCanvasObj);
            moveCanvas(e, function (x, y) {
                vm.moveCanvasObj.x = _moveCanvasObj.x + x;
                vm.moveCanvasObj.y = _moveCanvasObj.y + y;
                $scope.$apply();
            })
        }


        $scope.writingmodeInit = function (item) {
            if(item == 'vertical-lr' || item == 'vertical-rl'){
                return true
            }else{
                return false
            }
        }


        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.imgSize = res.data.imgSize;
                vm.imgDpi = res.data.imgDpi;
            }
        });
        $scope.onArticleChange = onArticleChange;
        vm.onArticleChange = onArticleChange;
        function onArticleChange() {
            vm.articleChanged = true;
        }

        //生成缩略图
        function startThumbnailTimer() {
            $timeout(handleThumbnailForThree, 3000); // every 3 seconds
        }
        function handleThumbnailForThree() {
            if (vm.productXmlData && vm.productXmlData.modelType === 'THREE' && vm.articleChanged) {
                vm.articleChanged = false;
                try {
                    thumbnailInit();
                }
                catch (error) {
                    console.log("Error to handleThumbnailForThree", error);
                }
            }
            startThumbnailTimer();
        }

        startThumbnailTimer();
        vm.isHtml2canvas = false;
        vm.pageData1 = [];
        vm.thumbnailInit = thumbnailInit;
        var a = null;
        function thumbnailInit() {
            if(!vm.productXmlData || vm.productXmlData.modelType != 'THREE'){
                return
            }
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function() {
                vm.thumbnailPageData = angular.copy(vm.pageData[vm.pageNumber]);
                pageTrimbox(vm.thumbnailPageData,$translate.instant("editorCommon.doubleClickType"))
                $timeout(function () {
                    html2canvas_adapt(document.getElementById('thumbnail-view'), {
                        onrendered: function (canvas) {
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);
                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', '3dThumbnail-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    console.log($scope.domain + data.key)
                                    vm.pageData[vm.pageIndex].pagecover = {
                                        identifier: $scope.domain + data.key,
                                        provider: 'qiniu'
                                    }
                                    vm.isHtml2canvas = true;
                                    vm.pageData1 = angular.copy(vm.pageData);
                                    $scope.$apply();
                                }
                            })
                        },
                        letterRendering: true,
                        useCORS: true,
                        width: vm.thumbnailPageData.mediabox.width*vm.thumbnailPageData.custom.thumbnailScale,
                        height: vm.thumbnailPageData.mediabox.height*vm.thumbnailPageData.custom.thumbnailScale,
                        windowWidth: document.body.scrollWidth,
                        windowHeight: document.body.scrollHeight,
                        x: 0,
                        y: 0,
                        backgroundColor: "rgba(0, 0, 0, 0)"
                    });
                },1000)
            }, 3000);
        }
        //生成封面图
        function thumbnailInit1(callback) {
            vm.threeLoading = true;
            vm.threeLoadingText = $translate.instant("editorCommon.loadingText");
            vm.thumbnailPageData1 = angular.copy(vm.pageData[0]);
            pageTrimbox(vm.thumbnailPageData1,$translate.instant("editorCommon.doubleClickType"))
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view1'), {
                    onrendered: function (canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                vm.threeLoading = false;
                                var identifier = data.key;
                                vm.article.covered=true;
                                vm.article.cover=identifier;
                                console.log(vm.article.cover);
                                if(callback){callback()};
                            }
                        })
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: vm.thumbnailPageData1.mediabox.width*vm.thumbnailPageData1.custom.thumbnailScale,
                    height: vm.thumbnailPageData1.mediabox.height*vm.thumbnailPageData1.custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            },1000)
        }


        //页面元素
        vm.isEditorPageElementBody = true;
        $scope.levelInfo = function (level, index) {
            var item = "";
            index = completeIndex(index)
            var i = index+1;
            if(level.imagebox){
                item += $translate.instant("editorCommon.imagebox")
            }
            if(level.imageart){
                item += $translate.instant("editorCommon.imageart")
            }
            if(level.textbox){
                item += level.textbox.text || ""
            }
            if(level.textdocument){
                item += $translate.instant("editorCommon.textdocument")
            }
            if(level.calMonthBox){
                item += $translate.instant("editorCommon.calMonthBox")
            }
            if(level.calTitleBox){
                item += $translate.instant("editorCommon.calTitleBox")
            }
            return item
        };
        function levelInfo1(level) {
            var item = "";
            if(level.imagebox){
                item = "imagebox"
            }
            if(level.imageart){
                item = "imageart"
            }
            if(level.textbox){
                item = 'textbox'
            }
            if(level.textdocument){
                item = "textdocument"
            }
            if(level.calTitleBox){
                item = "calTitleBox"
            }
            if(level.calMonthBox){
                item = "calMonthBox"
            }
            return item
        };
        $scope.levelDelete = function (index) {
            var levels = vm.pageData[vm.pageNumber].levels.level.splice(completeIndex(index), 1);
            if(levels && levels[0] && levels[0].imagebox){
                setLayout();
            }
            if (levels && levels[0] && levels[0].imagebox && levels[0].imagebox.image&& levels[0].imagebox.image.resourceid) {
                for (var j = 0; j < $scope.photos.length; j++) {
                    var arr = $scope.photos[j];
                    if (arr.sourceId == levels[0].imagebox.image.resourceid) {
                        arr.usenum == 0 ? 0 : arr.usenum--;
                    }
                }
            }
            magnitudeBox()
            cookie();
        };
        $scope.levelHidden = function (e, level) {
            level.isHidden = !level.isHidden;
            fn(e)
        }

        //页面元素移动
        var isBoxDown = false;
        vm.pageElementW = $('.editor-page-element-title').width();
        vm.pageElementH = $('.editor-page-element-title').height();
        vm.pageElementL = $(window).width() - vm.pageElementW - 160;
        vm.pageElementT = 110;
        $scope.movePageElement = function (e) {
            fn(e);
            var x = e.clientX;
            var y = e.clientY;
            var l = angular.copy(vm.pageElementL);
            var t = angular.copy(vm.pageElementT);
            isBoxDown = true;
            window.onmousemove = function (e) {
                if (isBoxDown) {
                    var nx = e.clientX;
                    var ny = e.clientY;
                    var nl = nx - (x - l);
                    var nt = ny - (y - t);
                    if(nl < $("#canvasView").offset().left){
                        nl = $("#canvasView").offset().left
                    }
                    if(nl > ($("#canvasView").offset().left + $("#canvasView").width() - vm.pageElementW)){
                        nl = ($("#canvasView").offset().left + $("#canvasView").width() - vm.pageElementW)
                    }
                    if(nt < $("#canvasView").offset().top){
                        nt = $("#canvasView").offset().top
                    }
                    if(nt > ($("#canvasView").offset().top + $("#canvasView").height() - vm.pageElementH)){
                        nt = ($("#canvasView").offset().top + $("#canvasView").height() - vm.pageElementH)
                    }
                    vm.pageElementL = nl;
                    vm.pageElementT = nt;
                    $scope.$apply();
                }

            }
            window.onmouseup = function (e) {
                isBoxDown = false;
                e.stopPropagation();
                $scope.$apply();
            }
        };
        //页面元素拖拽
        var sort1 = null;
        var sortArray1 = [];
        function pageListDrag1() {
            $timeout(function () {
                vm.sortingLevel = angular.copy(vm.pageData[vm.pageNumber].levels.level);
                sort1 = new Sortable(document.getElementById('pageList1'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    onEnd: function (/**Event*/evt) {
                        sortArray1 = [];
                        angular.forEach($('#pageList1').find(".sortingPageView"), function (item, index) {
                            var page = item.getAttribute("data-page");
                            sortArray1.push(JSON.parse(page));
                        });
                        if(sortArray1 && sortArray1.length>0){
                            vm.pageData[vm.pageNumber].levels.level = [];
                            $timeout(function () {
                                vm.pageData[vm.pageNumber].levels.level = angular.copy(sortArray1.reverse());
                                vm.pageData[vm.pageNumber].levels.level.forEach(function (value, index) {
                                    value.seq = index+1;
                                })
                                sortArray1 = [];
                                $scope.editorBodyView();
                            })
                        }
                    }
                });
            })
        }
        $scope.dragCompleteClick = function (e, level, index) {
            fn(e)
            elementInit((vm.pageData[vm.pageNumber].levels.level.length-1-index),vm.pageNumber,level[levelInfo1(level)],levelInfo1(level));
        };
        vm.completeIndex = completeIndex;
        function completeIndex(index) {
            return vm.pageData[vm.pageNumber].levels.level.length-1-index
        }
        function scrollIntoView() {
            multiSelect();
            $timeout(function () {
                if(vm.elementOn.index>=0){
                    if(document.getElementById('editor-page-element-body-view' + vm.elementOn.index)){
                        document.getElementById('editor-page-element-body-view' + vm.elementOn.index).scrollIntoView()
                    }
                }
            })
        }

        vm.multiSelect = multiSelect;
        function multiSelect() {
            var all = getAllCtrlKeyArray();
            vm.transparencyBtn = true;//透明度
            vm.lockingBtn = true;//锁定
            vm.generBoxBtn = true;//重复生成
            vm.coverageBtn = true;//图层
            vm.copyBtn = true;//复制
            vm.deleteBtn = true;//删除
            vm.textboxBtn = true;//文字
            vm.toobarAlignmentBtn = false;//对齐
            vm.isAlignHover = false;// 对齐方式
            vm.isDistributeHover = false;//分布
            vm.hasImgBtn = false; //裁切交换
            vm.distribute = false;//分布
            if(all.length>0){
                vm.textboxBtn = false;
                vm.lockingBtn = false;
                vm.coverageBtn = false;
                vm.deleteBtn = false;
                if (all.length>1) {
                    vm.toobarAlignmentBtn = true;
                }
                if (all.length>2) {
                    vm.distribute = true;
                }
                angular.forEach(all, function (level) {
                    if (level.code && level.code.ctrlKey) {
                        vm.transparencyBtn = false;
                    }
                })
            }else{
                if(!vm.elementOn || !vm.elementOn.name || vm.elementOn.name == 'calTitleBox' || vm.elementOn.name == 'calMonthBox'){
                    vm.transparencyBtn = false;
                    vm.lockingBtn = false;
                    vm.generBoxBtn = false;
                    vm.coverageBtn = false;
                    vm.copyBtn = false;
                    vm.deleteBtn = false;
                }
                if(vm.elementOn.name == 'textdocument'){
                    vm.copyBtn = false;
                }
                if(vm.elementOn && vm.elementOn.item && vm.elementOn.item.lock){
                    vm.textboxBtn = false;
                    vm.coverageBtn = false;
                    vm.deleteBtn = false;
                }
                if(vm.elementOn && vm.elementOn.name == 'imagebox' && vm.elementOn.item && vm.elementOn.item.image && vm.elementOn.item.image.resource && vm.elementOn.item.image.resource.identifier){
                    vm.hasImgBtn = true;
                }
            }
        }
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }

        //元素全选
        vm.levelInif = levelInif;
        function levelInif() {
            vm.ctrlKey = false;
            angular.forEach($scope.template.pages.page, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                        if(level.code&&level.code.geometry){
                            level.code.ctrlKey = false;
                        }
                        if(level.imageart){
                            level.imageart.ctrlKey = false;
                        }
                        if(level.imagebox){
                            level.imagebox.exchange = false;
                            level.imagebox.ctrlKey = false;
                        }
                        if(level.textbox){
                            level.textbox.ctrlKey = false;
                        }
                        if (level.textdocument && level.textdocument.geometry) {
                            level.textdocument.ctrlKey = false;
                        }
                        if (level.calMonthBox && level.calMonthBox.geometry) {
                            level.calMonthBox.ctrlKey = false;
                        }
                        if (level.calTitleBox && level.calTitleBox.geometry) {
                            level.calTitleBox.ctrlKey = false;
                        }
                    })
            })
            multiSelect();
        }
        vm.levelInif1 = levelInif1;
        function levelInif1() {
            $timeout(function () {
                angular.forEach($scope.template.pages.page, function (page, index) {
                    if(index != vm.pageNumber){
                        angular.forEach(page.levels.level, function (level, i) {
                            if(level.code&&level.code.geometry){
                                level.code.ctrlKey = false;
                            }
                            if(level.imageart){
                                level.imageart.ctrlKey = false;
                            }
                            if(level.imagebox){
                                level.imagebox.exchange = false;
                                level.imagebox.ctrlKey = false;
                            }
                            if(level.textbox){
                                level.textbox.ctrlKey = false;
                            }
                            if (level.textdocument && level.textdocument.geometry) {
                                level.textdocument.ctrlKey = false;
                            }
                            if (level.calTitleBox && level.calTitleBox.geometry) {
                                level.calTitleBox.ctrlKey = false;
                            }
                            if (level.calMonthBox && level.calMonthBox.geometry) {
                                level.calMonthBox.ctrlKey = false;
                            }
                        })
                    }
                })
                multiSelect();
            })
        };

        vm.levelMove = levelMove;
        function levelMove(event, operation) {
            angular.forEach($scope.template.pages.page, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart && level.imageart.ctrlKey && !level.imageart.edit){
                        if(operation == 'transparency'){
                            level.imageart.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.imageart.lock) {
                            moveWebdown($scope, event, level.imageart, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if(level.imagebox && level.imagebox.ctrlKey){
                        if(operation == 'transparency'){
                            level.imagebox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.imagebox.lock) {
                            moveWebdown($scope, event, level.imagebox, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if(level.textbox && level.textbox.ctrlKey){
                        if(operation == 'transparency'){
                            level.textbox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.textbox.lock) {
                            moveWebdown($scope, event, level.textbox, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if (level.textdocument && level.textdocument.ctrlKey) {
                        if(operation == 'transparency'){
                            level.textdocument.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.textdocument.lock) {
                            moveWebdown($scope, event, level.textdocument, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if (level.calMonthBox && level.calMonthBox.ctrlKey) {
                        if(operation == 'transparency'){
                            level.calMonthBox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.calMonthBox.lock) {
                            moveWebdown($scope, event, level.calMonthBox, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if (level.calTitleBox && level.calTitleBox.ctrlKey) {
                        if(operation == 'transparency'){
                            level.calTitleBox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.calTitleBox.lock) {
                            moveWebdown($scope, event, level.calTitleBox, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                })
            })
        }
        //对齐
        vm.isAlignHover = false;
        $scope.alignHover = function () {
            vm.isAlignHover = !vm.isAlignHover;
            vm.isDistributeHover = false;
        }
        //裁切
        $scope.doubleClickImagebox = function (event) {
            $rootScope.$broadcast('doubleClickImageboxCallback',{
                event: event,
                index: vm.elementOn.index,
                item: vm.elementOn.item,
                pageIndex: vm.elementOn.pageIndex,
            });
            toolbarStatus();
        };
        //交换
        $scope.exchange = function () {
            vm.pageData[vm.pageNumber].levels.level.forEach(function (level, index) {
                if(level.imagebox){
                    if(index!=vm.elementOn.index){
                        level.imagebox.exchange = true;
                    }else{
                        level.imagebox.exchange = false;
                    }
                }
            })
            toolbarStatus();
        };

        function compare2(property, attr) {
            return function (a, b) {
                var value1 = a.geometry[property];
                var value2 = b.geometry[property];
                if (attr) {
                    return value1 - value2;
                }
                else {
                    return value2 - value1;
                }
            }
        }
        vm.getAllCtrlKeyArray=getAllCtrlKeyArray;
        function getAllCtrlKeyArray() {
            var all = [];
            angular.forEach($scope.template.pages.page, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart && level.imageart.ctrlKey){
                        level.imageart.levelI = i;
                        level.imageart.current = 'imageart';
                        all.push(level.imageart)
                    }
                    if(level.imagebox && level.imagebox.ctrlKey){
                        level.imagebox.levelI = i;
                        level.imagebox.current = 'imagebox';
                        all.push(level.imagebox)
                    }
                    if(level.textbox && level.textbox.ctrlKey){
                        level.textbox.levelI = i;
                        level.textbox.current = 'textbox';
                        all.push(level.textbox)
                    }
                    if (level.textdocument && level.textdocument.ctrlKey) {
                        level.textdocument.levelI = i;
                        level.textdocument.current = 'textdocument';
                        all.push(level.textdocument)
                    }
                    if (level.calTitleBox && level.calTitleBox.ctrlKey) {
                        level.calTitleBox.levelI = i;
                        level.calTitleBox.current = 'calTitleBox';
                        all.push(level.calTitleBox)
                    }
                    if (level.calMonthBox && level.calMonthBox.ctrlKey) {
                        level.calMonthBox.levelI = i;
                        level.calMonthBox.current = 'calMonthBox';
                        all.push(level.calMonthBox)
                    }
                })
            })
            return all;
        }

        function clearAllCtrlKey(){
            angular.forEach($scope.template.pages.page, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart && level.imageart.ctrlKey){
                        level.imageart.ctrlKey = false;
                    }
                    if(level.imagebox && level.imagebox.ctrlKey){
                        level.imagebox.ctrlKey = false;
                    }
                    if(level.textbox && level.textbox.ctrlKey){
                        level.textbox.ctrlKey = false;
                    }
                    if (level.textdocument && level.textdocument.ctrlKey) {
                        level.textdocument.ctrlKey = false;
                    }
                    if (level.calMonthBox && level.calMonthBox.ctrlKey) {
                        level.calMonthBox.ctrlKey = false;
                    }
                    if (level.calTitleBox && level.calTitleBox.ctrlKey) {
                        level.calTitleBox.ctrlKey = false;
                    }
                })
            })
        }
        $scope.boxAlignment = function (attr) {
            var levels = vm.pageData[vm.pageNumber].levels;
            var all = getAllCtrlKeyArray();
            if(all.length == 1){
                if (attr == 'left') {
                    all[0].geometry.x = elementAlign(all[0].geometry , all[0].rotation.angle , 'left' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
                } else if (attr == 'right') {
                    all[0].geometry.x = elementAlign(all[0].geometry , all[0].rotation.angle , 'right' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
                } else if (attr == 'top') {
                    all[0].geometry.y = elementAlign(all[0].geometry , all[0].rotation.angle , 'top' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
                } else if (attr == 'bottom') {
                    all[0].geometry.y = elementAlign(all[0].geometry , all[0].rotation.angle , 'bottom' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
                } else if (attr == 'vertical') {
                    all[0].geometry.y = (vm.pageData[vm.pageIndex].mediabox.height - all[0].geometry.height) / 2;
                } else {
                    all[0].geometry.x = (vm.pageData[vm.pageIndex].mediabox.width - all[0].geometry.width) / 2;
                }
                return
            }
            if (attr == 'left') {
                all.sort(compare2('x', true));
            } else if (attr == 'right') {
                all.sort(compare2('width', false));
                all.sort(compare2('x', false));
            } else if (attr == 'top') {
                all.sort(compare2('y', true));
            } else if (attr == 'bottom') {
                all.sort(compare2('height', false));
                all.sort(compare2('y', false));
            }
            if(attr == 'vertical' && all.length>0){
                var verticalY = horizontalAndVerticalCenter(attr, all);
            }
            if(attr == 'standard' && all.length>0){
                var standardX = horizontalAndVerticalCenter(attr, all);
            }

            for (var i = 0; i < all.length; i++) {
                if(!all[i].lock) {
                    if (attr == 'left') {
                        all[i].geometry.x = all[0].geometry.x;
                    } else if (attr == 'right') {
                        all[i].geometry.x = all[0].geometry.x + all[0].geometry.width - all[i].geometry.width;

                    } else if (attr == 'top') {
                        all[i].geometry.y = all[0].geometry.y;

                    } else if (attr == 'bottom') {
                        all[i].geometry.y = all[0].geometry.y + all[0].geometry.height - all[i].geometry.height;

                    } else if (attr == 'vertical') {
                        all[i].geometry.y = verticalY - all[i].geometry.height / 2;
                    } else {
                        all[i].geometry.x = standardX - all[i].geometry.width / 2;
                    }

                    if (all[i].current == 'imagebox') {
                        levels.level[all[i].levelI].imagebox = all[i];
                    } else if (all[i].current == 'textbox') {
                        levels.level[all[i].levelI].textbox = all[i];
                    } else {
                        levels.level[all[i].levelI].imageart = all[i];
                    }
                }
            }
            // levelInif()
            cookie()
        }

        window.onresize = function () {
            $timeout(function () {
                if($("#canvasView")){
                    var l = angular.copy(vm.pageElementL);
                    var t = angular.copy(vm.pageElementT);
                    if(l < $("#canvasView").offset().left){
                        l = $("#canvasView").offset().left
                    }
                    if(l > ($("#canvasView").offset().left + $("#canvasView").width() - vm.pageElementW)){
                        l = ($("#canvasView").offset().left + $("#canvasView").width() - vm.pageElementW)
                    }
                    if(t < $("#canvasView").offset().top){
                        t = $("#canvasView").offset().top
                    }
                    if(t > ($("#canvasView").offset().top + $("#canvasView").height() - vm.pageElementH)){
                        t = ($("#canvasView").offset().top + $("#canvasView").height() - vm.pageElementH)
                    }
                    vm.pageElementL = l;
                    vm.pageElementT = t;
                }
            })
        }

        $scope.changeFilletType = function(type){
            if (vm.elementOn.item.border.radius.filletType == type) {
                return
            }
            vm.elementOn.item.border.radius.filletType = type;
            switchToRoundedMode(vm.elementOn.item,vm.borderRadiusAllbtn);//切换圆角显示模式
        }

        vm.borderRadiusAllbtn = true
        $scope.borderRadiusAll = function () {
             vm.elementOn.item.border.radius.lt =  vm.elementOn.item.border.radius.all;
             vm.elementOn.item.border.radius.rt =  vm.elementOn.item.border.radius.all;
             vm.elementOn.item.border.radius.rb =  vm.elementOn.item.border.radius.all;
             vm.elementOn.item.border.radius.lb =  vm.elementOn.item.border.radius.all;
        }
        $scope.borderRadiusAllbtn = function(){
            vm.borderRadiusAllbtn = true;
             vm.elementOn.item.border.radius.rt =  vm.elementOn.item.border.radius.lt;
             vm.elementOn.item.border.radius.rb =  vm.elementOn.item.border.radius.lt;
             vm.elementOn.item.border.radius.lb =  vm.elementOn.item.border.radius.lt;
             vm.elementOn.item.border.radius.all =  vm.elementOn.item.border.radius.lt
        }
        // 圆角设置
        vm.isRoundedSet = false;
        $scope.roundedSet = function(attr){
            toolbarStatus();
            vm.isRoundedSet = attr;
            vm.borderRadiusAllbtn = false;
        }

        // 边框设置
        vm.isBorderSet = false;
        $scope.borderSet = function(attr){
            toolbarStatus();
            vm.isBorderSet = attr;
        }

        // 阴影设置
        vm.isShadowSet = false;
        $scope.shadowSet = function(attr){
            toolbarStatus();
            vm.isShadowSet = attr;
        }

        function toolbarStatus(){
            vm.isTransp = false;
            vm.isRoundedSet = false;
            vm.isBorderSet = false;
            vm.isShadowSet = false;
            vm.isCoverage = false;
            vm.isOptimiza = false;
            vm.isAlign = false;
            //文字
            vm.isOperationSpacing = false;
            vm.isOperationDir = false;
        }

        $scope.lineTypeChenge = function(lineType){
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.lineType = lineType;
            onArticleChange()
        }
        $scope.dropShadow = function(imagebox,ratio){
            if (imagebox.shadow == null) {
                imagebox.shadow={
                    color:'#777777',
                    x:0,
                    y:0,
                    blur:0
                }
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio;
            return dropShadow(imagebox,width,height)
        }
        $scope.clearOperate = function(){
            toolbarStatus()
        }
        vm.editorMiniPage = 'page';
        $scope.editorMiniPageCb = function (item) {
            vm.editorMiniPage = item;
        }
        vm.isWatchName = false;
        $scope.watchGalleryName = function(state){
            vm.isWatchName = state;
            // setTimeout(function () {
            //     waterFallPic('main5');
            //     $(".photo-imgs").height($scope.photoHight);
            // }, 200);
        }

        function scrollIntoViewElement(){
            if(document.getElementById('c-editor-page-view-li' + completeIndex(vm.elementOn.index))){
                document.getElementById('c-editor-page-view-li' + completeIndex(vm.elementOn.index)).scrollIntoView()
            }
        }


        function pageUpdate() {
            vm.isPageUpdate = true;
            $timeout(function () {
                vm.isPageUpdate = false;
            })
        }

        // 单个元素对齐
        vm.isAlign = false;
        $scope.alignHover2 = function () {
            if(vm.elementOn.name == ''){
                MessageService.error($translate.instant("editorMessage.pleaseSelectAElement"));
                return
            }
            if (vm.elementOn.item.lock) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectALock"));
                return
            }
            toolbarStatus();
            vm.isAlign = !vm.isAlign;
        }

        $scope.singleBoxAlignment = function (attr) {
            if(vm.toobarAlignmentBtn){
                $scope.boxAlignment(attr);
                return
            }
            if (vm.elementOn.item.lock) {
                MessageService.error($translate.instant("editorMessage.pleaseSelectALock"));
                return
            }
            if (attr == 'left') {
                vm.elementOn.item.geometry.x = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'left' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
            } else if (attr == 'right') {
                vm.elementOn.item.geometry.x = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'right' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
            } else if (attr == 'top') {
                vm.elementOn.item.geometry.y = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'top' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
            } else if (attr == 'bottom') {
                vm.elementOn.item.geometry.y = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'bottom' , vm.pageData[vm.pageIndex].mediabox.width , vm.pageData[vm.pageIndex].mediabox.height);
            } else if (attr == 'vertical') {
                vm.elementOn.item.geometry.y = (vm.pageData[vm.pageIndex].mediabox.height - vm.elementOn.item.geometry.height) / 2;
            } else {
                vm.elementOn.item.geometry.x = (vm.pageData[vm.pageIndex].mediabox.width - vm.elementOn.item.geometry.width) / 2;
            }
            cookie()
        }


        // 元素水平垂直分布
        vm.isDistributeHover = false;
        $scope.distribute = function(){
            vm.isDistributeHover = !vm.isDistributeHover;
            vm.isAlignHover = false;
        }
        $scope.boxDistribute = function (direction){
            var all1 = getAllCtrlKeyArray();
            var all = [];
            all1.forEach(function(box){
                if (!box.lock) {
                    all.push(box)
                }
            })
            if (all.length < 3) {
                MessageService.error('请选择3个以上非锁定状态的元素！');
                return
            }
            if(direction == 'horizontal'){
                setAllCtrlKeyArray(all, 'x')
            }
            if(direction == 'vertical'){
                setAllCtrlKeyArray(all, 'y')
            }
        }
        function setAllCtrlKeyArray(all, attr) {
            var len = all.length - 1;
            all.sort(compare2(attr, true));
            if (attr == 'x') {
                var elementAllWidth = 0;
                for (var i = 1; i < len; i++) {
                    elementAllWidth+=all[i].geometry.width;
                }
                var spacingPx = (all[len].geometry.x - all[0].geometry.x - all[0].geometry.width - elementAllWidth) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.x = all[i-1].geometry.x + all[i-1].geometry.width + spacingPx;
                }
            }
            if (attr == 'y') {
                var elementAllHeight = 0;
                for (var i = 1; i < len; i++) {
                    elementAllHeight+=all[i].geometry.height;
                }
                var spacingPx = (all[len].geometry.y - all[0].geometry.y - all[0].geometry.height - elementAllHeight) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.y = all[i-1].geometry.y + all[i-1].geometry.height + spacingPx;
                }
            }
        }

        $scope.addPages1 = function(){
            if(!vm.pageData[vm.pageNumber]){
                MessageService.error($translate.instant("editorMessage.pleaseSelectAddPagePosition"));
                return
            }
            if (vm.article.xml && (!vm.article.xml.addpage /* false */ || !vm.article.xml.addunit /* 0 */)) {
                MessageService.error($translate.instant("editorMessage.noAddPage"));
                return
            }
            if($scope.template.pages.page.length >= vm.article.xml.maxpage){
                MessageService.error($translate.instant("editorMessage.beSatisfiedMax"));
                return
            }
            $scope.addPages(vm.pageData[vm.pageNumber],vm.pageNumber+1);
        }
        $scope.addPages = function(page,index){
            var _pindex = -1;
            for (var i = vm.pageData.length - 1; i >= 0; i--) {
                if (vm.pageData[i].config.partid == 'P') {
                    _pindex = i+1;
                    break;
                }
            }
            for (var i = 0; i < vm.article.xml.addunit; i++) {
                if(vm.pageData.length<vm.article.xml.maxpage){
                    var p = angular.copy(page)

                    p.background={
                        color: "#ffffff",
                        offsetx: 0,
                        offsety: 0,
                        resource: {provider: null, identifier: null},
                        scale: 0,
                        type: "Color"
                    }
                    p.unreplace = false;
                    p.type = null;
                    p.levels = {level:[]};
                    p.pagecover = {provider: "qiniu", identifier: ""}
                    p.uuid = UidService.get();
                    vm.pageData.splice(_pindex,0,p)
                }
            }
            $timeout(function(){
                angular.forEach(vm.pageData, function (obj, index1) {
                    obj.seq = index1;
                    ratioInfo(obj);
                });
                vm.pageSum = vm.pageData.length - 1;
                pageDataInit();
                $scope.turning(index)
            })
        }
        $scope.delAddPage = function(index){
            if(vm.pageData[index].unreplace){
                MessageService.error($translate.instant("editorMessage.notDelete"));
                return
            }
            if(vm.pageData.length > vm.article.xml.minpage){
                vm.pageData.splice(index,1)
            }
            angular.forEach(vm.pageData, function (obj, index) {
                obj.seq = index;
                ratioInfo(obj);
            });
            vm.pageSum--;
            pageDataInit();
            if(vm.pageNumber > vm.pageData.length-1){
                $scope.turning(vm.pageData.length-1)
            }
        }

        var codeWidthDebounceTimer = null;
        $scope.codeWidth = function () {
            if (codeWidthDebounceTimer !== null) {
                clearTimeout(codeWidthDebounceTimer)
            }
            codeWidthDebounceTimer = setTimeout(function() {
                if (vm.code.width <= 10) {
                    vm.code.width = 10
                }

                console.log(vm.code);
            }, 300);
        }

        var codeHeightDebounceTimer = null;
        $scope.codeHeight = function () {
            if (codeHeightDebounceTimer !== null) {
                clearTimeout(codeHeightDebounceTimer)
            }
            codeHeightDebounceTimer = setTimeout(function() {
                if (vm.code.height <= 10) {
                    vm.code.height = 10
                }
            }, 300);
        }
        /*
        * 动态日历
        * */
        vm.years=[];
        vm.months=[1,2,3,4,5,6,7,8,9,10,11,12];
        vm.yearIndex=2024;
        vm.monthIndex=1;
        vm.isCalendar=false;
        vm.monthIndex1 = 0;
        vm.yearPage = 1;
        vm.yearSize = 10;
        vm.isYear = false;
        function yearInfo() {
            if($scope.pages.config.productType == 'CALENDAR'&&$scope.pages.config.dynamic){
                $scope.pages.pages.page.forEach(function (page) {
                    if(vm.monthIndex1 < page.config.monthIndex){
                        vm.monthIndex1 = page.config.monthIndex
                    }
                })
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var year1 = 2000;
                for(var i=0;i<100-Math.floor(vm.monthIndex1/13);i++){
                    vm.years.push(year1+i);
                }
                console.log(vm.years)
                vm.yearSize = Math.ceil(vm.years.length/10);
                if(month>5){
                    vm.yearIndex = year+1;
                }else{
                    vm.yearIndex = year;
                }
            }
        }
        $scope.getEndYear = function (month) {
            return getDay('year',vm.yearIndex,month||vm.monthIndex,vm.monthIndex1)
        }
        $scope.getEndMonth = function (month) {
            return getDay('month',vm.yearIndex,month||vm.monthIndex,vm.monthIndex1)
        }
        $scope.yearIndex = function(item){
            if(item == 'subtract'){
                vm.yearIndex = vm.yearIndex<=2000?2000:vm.yearIndex-1;
            }
            if(item == 'add'){
                vm.yearIndex = vm.yearIndex>=vm.years[vm.years.length-1]?vm.years[vm.years.length-1]:vm.yearIndex+1;
            }
            monthInfo()
        }
        $scope.yearPageCb = function(item){
            if(item == 'subtract'){
                vm.yearPage = vm.yearPage<2?1:vm.yearPage-1;
            }
            if(item == 'add'){
                vm.yearPage = vm.yearPage>=vm.yearSize?vm.yearSize:Number(vm.yearPage)+1;
            }
        }
        var a = null;
        $scope.yearPageChange = function(){
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function () {
                $timeout(function () {
                    var regex = /^[1-9]\d*$/;
                    if(!regex.test(vm.yearPage)){
                        vm.yearPage = 1;
                    }
                    if(vm.yearPage>=vm.yearSize){
                        vm.yearPage=vm.yearSize
                    }
                    if(vm.yearPage<2){
                        vm.yearPage=1;
                    }
                })
            },500)
        }
        $scope.yearIndexSave = function(e,item){
            if(item){
                vm.yearIndex = item;
                vm.isYear=false;
                monthInfo()
            }
            fn(e)
        }
        $scope.isYearHide = function(e){
            vm.isYear = false;
            fn(e);
        }
        $scope.isYearShow = function(e){
            vm.isYear = true;
            fn(e);
        }
        function monthInfo(){
            // if($scope.getEndYear(12)>2099){
            //     vm.months=[];
            //     vm.monthIndex=1;
            //     for(var i=1;i<=12-$scope.getEndMonth(12);i++){
            //         vm.months.push(i)
            //     }
            // }else{
            //     vm.months=[1,2,3,4,5,6,7,8,9,10,11,12];
            // }
        }
        $scope.mathFloor = function(item){
            if(item>=0){
                return Math.floor(item/10)+1
            }
        }
        $scope.calendarSave = function () {
            $scope.template.config.startYear = vm.yearIndex;
            $scope.template.config.startMonth = vm.monthIndex;
            $scope.template.pages.page.forEach(function (page) {
                page.levels.level.forEach(function (level) {
                    if (level.calMonthBox && level.calMonthBox.geometry) {
                        var calMonthBox = level.calMonthBox;
                        calMonthBox.obj=null;
                        calMonthBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                        calMonthBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                    }
                    if (level.calTitleBox && level.calTitleBox.geometry) {
                        var calTitleBox = level.calTitleBox;
                        calTitleBox.obj=null;
                        calTitleBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                        calTitleBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                    }
                })
            })
            vm.isCalendar=false;
            vm.minLoading = true;
            $timeout(function () {
                console.log($scope.template.pages.page)
                vm.minLoading = false;
            },2000)
        }

        vm.showCanvasImageFilter = false;
        // 图片优化
        $scope.optimizationSelect = function (attr) {
           if (!vm.elementOn.name=='imagebox') {
               MessageService.error($translate.instant("editorMessage.pleaseSelectAImagebox"));
               return
           }
           if(vm.elementOn.item.image && vm.elementOn.item.image.resourceid) {
               // 获取原图信息
               Gallery.getOne({id:vm.elementOn.item.image.resourceid},function (res){
                   vm.elementOn.item.image.resource.primary = res.data.identifier;
                   // 图片优化
                   if (vm.elementOn.item.image && vm.elementOn.item.image.resource && vm.elementOn.item.image.resource.identifier) {
                       toolbarStatus();
                       var current = vm.elementOn.item;
                       if (current.image) {
                           vm.showCanvasImageFilter = true;
                           window.removeEventListener('mousewheel', mousewheelHandler);
                           if(vm.elementOn.item.optimizations){
                               vm.optimizations = vm.elementOn.item.optimizations;
                           }
                       } else {
                           MessageService.error($translate.instant("editorMessage.pleaseSelectAImagebox"));
                       }
                   }
               })
           }
       }

        // 图片优化
        $rootScope.$on('editorApp.closeImageFilter', function () {
            vm.showCanvasImageFilter = false;
            window.addEventListener('mousewheel', mousewheelHandler,{ passive: false});
        });
        $rootScope.$on('editorApp.saveImageFilter', function (event,imgUrl) {
            vm.showCanvasImageFilter = false;
            window.addEventListener('mousewheel', mousewheelHandler,{ passive: false});
            filterImgUpdate(imgUrl);
        });

        function filterImgUpdate(img){
            vm.elementOn.item.imageLoading = true;
            var url = img
            var blob = dataURLtoBlob(url);
            var formData = new FormData();
            formData.append('file', blob);
            formData.append('key', '3dThumbnail-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    $.ajax({
                        type: "GET",
                        url: vm.elementOn.item.image.resource.identifier + "?imageInfo",
                        async: false,
                        success: function (va) {
                            var identifier = $scope.domain + data.key;
                            vm.elementOn.item.image.resource.identifier = identifier;
                            vm.elementOn.item.imageLoading = false;
                            cookie();
                            $timeout(function() {
                                vm.elementOn.item.imageLoading = false;
                            })
                            if(va.orientation){
                                imageboxImgInit();
                            }
                        }
                    })
                }
            })
        }
        function imageboxImgInit(){
            $.ajax({
                type: "GET",
                url: $scope.domain + vm.elementOn.item.image.resource.primary + "?imageInfo",
                async: false,
                success: function (va1) {
                    var angle = (vm.elementOn.item.image.angle + 360 - orientationInit1(va1.orientation).angle) % 360;
                    if (vm.elementOn.item.flip) {
                        vm.elementOn.item.image.angle = -angle;
                        vm.elementOn.item.flip = false;
                    }else{
                        vm.elementOn.item.image.angle = angle;
                    }
                }
            });
        }

        vm.unusedQuantity = null;
        function unusedQuantity(){
            var galleryIdList = [];
            angular.forEach(vm.pageData, function (page, i) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid){
                        galleryIdList.push(level.imagebox.image.resourceid)
                    }
                });
            });
            var newArr = galleryIdList.filter(function(item,index){
                return galleryIdList.indexOf(item) === index;  // 因为indexOf 只能查找到第一个
            });
            Gallery.unUsed({
                galleryIdList:newArr,
                folderId: vm.folderId,
                articleId: (vm.article.articleId || vm.article.id)
            },function (res) {
                if(res.status == 200){
                    vm.unusedQuantity = res.data;
                    getGallery();
                }
            })
        }

        function getGallery(){
            console.log($scope.photos.length < vm.totalPhotoItems);
            if(vm.photosTab!='all' && $scope.photos.length < vm.totalPhotoItems){
                var _photoQuantity = 0;
                $scope.photos.forEach(function (item) {
                    if(item.usenum==0||!item.usenum){
                        _photoQuantity++
                    }
                })
                if(_photoQuantity < vm.itemsPerPage){
                    if(vm.folderId){
                        loadAll(vm.folderId);
                    }else{
                        loadAll('isNull');
                    }
                }
            }
        }

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+(vm.article ? (vm.article.articleId || vm.article.id) : vm.articleId));

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:'+ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                // console.log(photo);
                if (photo.folderId == vm.folderId || (photo.folderId == null && vm.folderId == '')) {
                    vm.totalPhotoItems++;
                    vm.unusedQuantity++;
                    var len = $scope.photos.length;
                    photo.identifier = $scope.domain + photo.identifier;
                    photo.select = false;
                    photo.index = len;
                    photo.usenum = 0;
                    photo.type = 'picture';
                    $scope.photos.unshift(photo);
                    if (vm.photoSort != 1 && vm.minId==null) {
                        vm.minId = photo.id;
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }

    }
})();
