(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductViewEcThreeController', ProductViewEcThreeController);

    ProductViewEcThreeController.$inject = ['$scope', '$stateParams', 'entity', '$q', '$localStorage', 'Qinius', '$state', 'Cart', 'CommonService', 'Store',
        'ImageService', '$http', '$rootScope', 'Theme', 'ItemValue', 'PlatformWechatSetting', 'Article','Music','MessageNotification', 'FontManagement','ProductXml',
        'Convert', 'StoreWorkEditConfig','ModulesService', '$timeout', '$translate','Product'];
    function ProductViewEcThreeController($scope, $stateParams, entity, $q, $localStorage, Qinius, $state, Cart, CommonService, Store,
                                   ImageService, $http, $rootScope, Theme, ItemValue, PlatformWechatSetting, Article, Music,MessageNotification, FontManagement,ProductXml,
                                          Convert, StoreWorkEditConfig, ModulesService, $timeout, $translate,Product) {
        var vm = this;

        CommonService.setDocumentTitle('预览');
        $scope.orderFlag = localStorage.getItem('returnurl2Text') || $translate.instant("button.buttonText");

        //风格切换  引入不同的css文件
        Theme.judge();
        vm.platformSetting = PlatformWechatSetting.get();
        var audio = document.getElementById("audio");
        // audio.src = "content/ihope.mp3";
        audio.crossOrigin = 'anonymous';

        vm.orderOrigin =  localStorage.getItem('orderOrigin');


        var timer = null;
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.aid = $stateParams.aid;
        vm.orderArticleId = $stateParams.orderArticleId;
        vm.store = Store.get({id: vm.storeId});
        vm.article = entity.data;
        vm.bindingtype = 'LAR';

        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                $scope.orderFlag = localStorage.getItem('returnurl2Text') || res.data.buttonText;
            }else{
                swal.showInputError(res.message);
            }
        });
        //预览引导页
        var tt = localStorage.getItem('guide-share');
        if (tt == 'true') {
            vm.share = true;
        } else if (tt == 'false' || tt == '' || tt == null) {
            vm.share = false;
        }
        $scope.nextStep = function () {
            vm.share = true;
            localStorage.setItem('guide-share', vm.share);
        };

        var monthStr = new Date().getMonth() + 1;
        var dayStr = new Date().getDate();
        var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
        // MessageNotification.getAllByCondition2({
        //     items: [{key: "available", op: "=", value: "true"}, {
        //         key: "validate",
        //         op: ">=",
        //         value: timestr
        //     }, {key: "pageType", op: "=", value: "View"}]
        // },function (res) {
        //     if(res.status == 200){
        //         res.data.sort(compare('seq'))
        //         vm.message = angular.copy(res.data);
        //         vm.messageText = "\xa0\xa0\xa0\xa0\xa0\xa0";
        //         angular.forEach(res.data,function (resp) {
        //             console.log(resp);
        //             vm.messageText += resp.content+"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
        //         })
        //     }else {
        //         MessageService.error(res.message);
        //     }
        // });

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1-value2;
            }
        }

        //dpi
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[0];
            // vm.windowDpi = 300;
        }
        $scope.action_addCart = 'addCart';
        $scope.action_orderNow = 'orderNow';
        $scope.quantity = 1;
        $scope.copy = 1;
        $scope.hideEdit = vm.article.id;
        $scope.price = 0;
        $scope.musicPage = false;
        $scope.dHeight = vm.dHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        $scope.dWidth = vm.dWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        $scope.document = {
            pages: [{}]
        };

        var artDoc = angular.copy(vm.article.document);

        $('.view2-article').on('click', 'touchstart', 'touchmove', function (event) {
            event.preventDefault();
        });
        /**
         * mm换算成px
         * @param mm
         * @returns {number}
         */
        var unitTransform = function (mm) {
            // return mm * 3;
            return mm / 25.4 * vm.windowDpi;
        };
        var $li = $(".templateView2-carousel");
        $li.hide();
        $scope.domains = Qinius.getDomain({storeId: vm.storeId});
        var $load = $(".loading-page");
        var $flagBox = $(".flag-box");
        $flagBox.hide();

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
                console.log(error);
            });
        }

        var rot = 10 - 0;
        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                audio.play();
                Play();
                $("#Play").children("img").removeClass("play").addClass("pause");
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        vm.isHideMusicBtn = false;
        getAll(artDoc);
        function getAll(doc) {
            $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise]).then(function () {
                if(vm.article.invalid){//作品失效
                    $state.go('articlesFailure',{storeId:vm.storeId});
                    return
                }
                vm.platformSetting = vm.platformSetting.data;
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                ItemValue.get({id: vm.article.itemValueId}, function (res) {
                    if(res.status == 200){
                        vm.itemValue = res.data;

                        //取得xml文档内容
                        vm.bindingtype = vm.itemValue.bindingType;
                        localStorage.setItem('bindingtype', vm.bindingtype);

                        $scope.price = res.data.price;
                        if (vm.article.musicIdentify) {
                            audio.src = $scope.domain + vm.article.musicIdentify;
                            vm.musicIdentify = vm.article.musicIdentify;
                        }else{
                            if ( res.data.productXmlLines) {
                                for (var i = res.data.productXmlLines.length - 1; i >= 0; i--) {
                                    var xml = res.data.productXmlLines[i];
                                    if (xml.productXmlId == vm.article.productXmlId && xml.musicIdentify) {
                                        audio.src = $scope.domain + xml.musicIdentify;
                                    }
                                }
                            } else {
                                audio.src = '';
                                vm.isHideMusicBtn = true;
                            }
                        }

                    }else {
                        MessageService.error(res.message);
                    }
                });
                Play();
                Product.get({id: vm.article.productId}, function (resP) {
                    vm.product = resP.data;
                    Convert.xmlToDocument2({
                        identifier: vm.article.fileName,
                        provider: 'qiniu',
                        editorType:vm.article.editorType,
                        patternType:resP.data.patternType,
                    }, function (res) {
                        vm.article.document = res;
                        // $scope.getDetail(arrUse, 0);
                        $scope.getDetail1(res);
                        Music.allByCondition({
                            items:[{key: "storeId", op: "=", value: vm.storeId}],
                            sort: ["id,asc"]
                        }, function (resp) {
                            if(resp.status == 200){
                                $scope.Musics = resp.data;
                            }else {
                                MessageService.error(resp.message);
                            }
                        });

                        ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                            if(productXml.data.modelType && productXml.data.modelType === 'THREE'){
                                vm.modelType = 'THREE';
                                if(!productXml.data.modelPath){
                                    MessageService.error($translate.instant("editorCommon.not3D"));
                                    vm.threeView = false;
                                    return
                                }
                                vm.threeView = true;
                                vm.threeTemplate = vm.article.document;
                                vm.threeProductXmlData = productXml.data;
                            }else {
                                vm.modelType = 'TWO';
                            }
                        })
                    });
                })
                //获取自定义颜色
                ModulesService.getToneSettings(vm.storeId,function (res) {
                    vm.toneSettings = res;
                });
            });
        }

        /**
         * 赋值 --> 先制作文字 --> 文字 --> [有占位框(生成新数组-->占位框图片转canvas) || 无占位框(占位框图片转canvas)]
         * @param document
         * @param index
         */
        $scope.getDetail1 = function(document){
            $scope.template = angular.copy(document);
            vm.isEdit = true;
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                $scope.template.pages.page.forEach(function (page) {
                    if (!page.scene) {
                        pageTrimbox(page)
                    }
                })
            })
            showPage()
        }
        function ratioInfo(item) {
            var windowRatio = 0.85;
            item.custom.ratio = $scope.dWidth * windowRatio / item.custom.width;
        }
        $scope.getDetail = function (document, index) {
            var obj = document.pages.page[index];
            $scope.scene = angular.copy(document.scene);

            $scope.template = document;
            var bgIdentifier = 'content/images/white.png';
            if (obj.background && obj.background.resource && obj.background.resource.identifier) {
                bgIdentifier = obj.background.resource.identifier + '?imageMogr2/thumbnail/!40p';
            }

            $scope.template.pages.page[index] = {
                bgImg:bgIdentifier,
                background: angular.copy(obj.background),
                barcode: angular.copy(obj.barcode),
                barcodeM: angular.copy(obj.barcode),
                qrcode: angular.copy(obj.qrcode),
                qrcodeM: angular.copy(obj.qrcode),
                barcodeRotation : {
                    angle: obj.barcodeRotation?obj.barcodeRotation.angle:0
                },
                mediabox: angular.copy(obj.mediabox),
                trimbox: angular.copy(obj.trimbox),
                textboxes: angular.copy(obj.textboxes),
                imageboxes: angular.copy(obj.imageboxes),
                levels: angular.copy(obj.levels),
                spread: obj.spread,
                centerrotation: obj.centerrotation ? true : false
            };

            $scope.scene = $scope.template.scene;
            $scope.scene.geometry = setGeometry($scope.scene.geometry);
            $scope.scene.imagebox.geometry = setGeometry1($scope.scene.imagebox.geometry);

            unitTransform($scope.template.pages.page[0].mediabox.width) * ($scope.dWidth - 45) /unitTransform($scope.template.pages.page[0].mediabox.width)
            //摆台
            var w0 = $scope.dWidth - 45,
                h0 = ($scope.dWidth - 45)*unitTransform($scope.template.pages.page[0].mediabox.height)/unitTransform($scope.template.pages.page[0].mediabox.width),
                w1 = $scope.scene.imagebox.geometry.width,
                h1 = $scope.scene.imagebox.geometry.height;
            if ((w0 <= h0 || w1 <= h1) && (w0 / h0 < w1 / h1) || w0 >= h0 && w1 >= h1 && (w0 / h0 < w1 / h1)) {
                $scope.imageboxConH = h1;
                $scope.imageboxConW = h1* w0 / h0;
                $scope.proportion = h0 / h1;
            } else {
                $scope.imageboxConW = w1;
                $scope.imageboxConH = w1 * h0 / w0;
                $scope.proportion = w0 / w1;
            }
            $scope.marginLeft = (w1 - $scope.imageboxConW) / 2;
            $scope.marginTop = (h1 - $scope.imageboxConH) / 2;


            var temp = $scope.template.pages.page[index];
            temp.styleScale = ((vm.dWidth - 45) / (unitTransform(obj.mediabox.width))) / $scope.proportion;
            //背景图片
            var w = $scope.imageboxConW* temp.styleScale,
                h = $scope.imageboxConH* temp.styleScale;
            if (temp.background.resource) {
                getBackgroundSource($http, temp.background.resource,w,h,temp.background);
                temp.background.offsetXM = angular.copy(temp.background.offsetx);
                temp.background.offsetYM = angular.copy(temp.background.offsety);
                temp.background.offsetx = unitTransform(temp.background.offsetx)* temp.styleScale;
                temp.background.offsety = unitTransform(temp.background.offsety)* temp.styleScale;
            }
            if (obj.barcode) {
                var barcode = obj.barcode.geometry;
                var angle = 0;
                if (obj.barcode.rotation && obj.barcode.rotation.angle) {
                    angle = obj.barcode.rotation.angle
                }
                temp.barcode = {
                    geometry: {
                        width: unitTransform(barcode.width) * temp.styleScale,
                        height: unitTransform(barcode.height) * temp.styleScale,
                        x: unitTransform(barcode.x) * temp.styleScale,
                        y: unitTransform(barcode.y) * temp.styleScale,
                        widthM: barcode.width,
                        heightM: barcode.height,
                        offsetXM: barcode.x,
                        offsetYM: barcode.y
                    },
                    rotation: {
                        angle: angle
                    }
                }
            }
            if (obj.qrcode) {
                var qrcode = obj.qrcode.geometry;
                var angle = 0;
                if (obj.qrcode.rotation && obj.qrcode.rotation.angle) {
                    angle = obj.qrcode.rotation.angle
                }
                temp.qrcode = {
                    geometry: {
                        width: unitTransform(qrcode.width) * temp.styleScale,
                        height: unitTransform(qrcode.height) * temp.styleScale,
                        x: unitTransform(qrcode.x) * temp.styleScale,
                        y: unitTransform(qrcode.y) * temp.styleScale,
                        widthM: qrcode.width,
                        heightM: qrcode.height,
                        offsetXM: qrcode.x,
                        offsetYM: qrcode.y
                    },
                    rotation: {
                        angle: angle
                    }
                }
            }

            angular.forEach(obj.levels.level, function (level, n) {

                if (level.imageart && level.imageart.geometry) {
                    var art = level.imageart;
                    $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                        temp.levels.level[n].imageart = {
                            geometry: getGeometry(art.geometry, temp),
                            // rotation: angular.copy(art.rotation),
                            rotation: {
                                angle: -art.rotation.angle
                            },
                            type: art.type,
                            resource: {
                                identifier:art.resource.identifier,
                                provider:art.resource.provider,
                                width:data.width,
                                height:data.height
                            },
                            scale:art.scale,
                            widthM: art.geometry.width,
                            heightM: art.geometry.height,
                            XM: art.geometry.x,
                            YM:art.geometry.y,
                            offsetXM: angular.copy(art.offsetx),
                            offsetYM: angular.copy(art.offsety),
                            offsetx: unitTransform(art.offsetx)* temp.styleScale,
                            offsety: unitTransform(art.offsety)* temp.styleScale,
                            current: 'imageart',
                            levelI: n,
                            pageI: index,
                            lock: false,
                            transparency: art.transparency,
                            onlyshow: art.onlyshow,
                            movable: art.movable,
                            editable: art.editable,
                        }
                    }).error(function (error) {});
                }
                if(level.textbox){
                    $scope.template.pages.page[index].levels.level[n].textbox = {
                        geometry: getGeometry(level.textbox.geometry, temp),
                        style: angular.copy(level.textbox.style),
                        identifier: level.textbox.identifier,
                        rotation: {
                            angle: -level.textbox.rotation.angle
                        },
                        text: level.textbox.text,
                        styles: 'NORMAL',
                        status: level.textbox.status,
                        type: level.textbox.type,
                        transparency: level.textbox.transparency,
                        movable: level.textbox.movable,
                        editable: level.textbox.editable,
                        fontuuid: level.textbox.fontuuid
                    };
                    var tempText = level.textbox;
                    if (tempText.style.bold && tempText.style.italic) {
                        tempText.styles = 'BOLD_ITALIC';
                    } else if (tempText.style.bold) {
                        tempText.styles = 'BOLD';
                    } else if (tempText.style.italic) {
                        tempText.styles = 'ITALIC';
                    } else {
                        tempText.styles = 'NORMAL';
                    }
                    if (level.textbox.text && level.textbox.identifier) {
                        toImg(tempText, $scope.template.pages.page[index].styleScale);
                    }
                    getFontCSS(FontManagement,level.textbox.fontuuid, $scope);
                }
                if(level.imagebox){
                    var art = level.imagebox;
                    $scope.template.pages.page[index].levels.level[n].imagebox = {
                        geometry: getGeometry(level.imagebox.geometry, temp),
                        blurredrectangle: level.imagebox.blurredrectangle ? getGeometry(level.imagebox.blurredrectangle, temp) : null,

                        mask: angular.copy(level.imagebox.mask),
                        image: {
                            resource: {
                                identifier: level.imagebox.image ? level.imagebox.image.resource.identifier : '',
                                provider: level.imagebox.image ? level.imagebox.image.resource.provider : 'qiniu'
                            },
                            filterResource: {
                                identifier: level.imagebox.image && level.imagebox.image.filterResource ? level.imagebox.image.filterResource.identifier : '',
                                provider: level.imagebox.image && level.imagebox.image.filterResource ? level.imagebox.image.filterResource.provider : 'qiniu'
                            },
                            rotation: angular.copy(level.imagebox.rotation),
                            parameter: (!level.imagebox.image) || level.imagebox.image.parameter == null ? [] : angular.fromJson(level.imagebox.image.parameter),
                            filter: (!level.imagebox.image) || level.imagebox.image.filter == null ? '' : level.imagebox.image.filter,
                            offsetx: level.imagebox.image.offsetx == 0 ? 0 : (unitTransform(level.imagebox.image.offsetx) * $scope.template.pages.page[index].styleScale),
                            offsety: level.imagebox.image.offsety == 0 ? 0 : (unitTransform(level.imagebox.image.offsety) * $scope.template.pages.page[index].styleScale),
                            angle: level.imagebox.image && level.imagebox.image.angle ? level.imagebox.image.angle : 0,
                            scale: (!level.imagebox.image) || level.imagebox.image.scale == 1 ? 1 : level.imagebox.image.scale,
                            index: (!level.imagebox.image) ? '' : level.imagebox.image.index
                        },
                        imgUrl: 'content/images/timg.jpg',
                        maskUrl: 'content/images/timg.jpg',
                        rotation: angular.copy(level.imagebox.rotation),
                        scaleW: 1,
                        scaleH: 1,
                        flip: level.imagebox.flip,
                        bokehtype: level.imagebox.bokehtype
                    };

                    if (level.imagebox.bokehtype!='None') {
                        level.imagebox.blurredrectangle = getGeometry(level.imagebox.blurredrectangle, temp)
                    }

                    var imageboxes = $scope.template.pages.page[index].levels.level[n].imagebox;
                    if (level.imagebox.mask.resource.identifier) {
                        imageboxes.maskUrl = level.imagebox.mask.resource.identifier + '?imageMogr2';
                    }

                    if (level.imagebox.image && level.imagebox.image.resource.identifier) {       //qiniu
                        imageboxes.imgUrl = level.imagebox.image.resource.identifier + '?imageMogr2/strip/thumbnail/!40p';
                        if (imageboxes.image.angle != 0) {
                            imageboxes.imgUrl += '/rotate/' + imageboxes.image.angle
                        }
                    }
                }

                if (level.textdocument && level.textdocument.geometry) {
                    var textdocument = level.textdocument;
                    $scope.template.pages.page[index].levels.level[n].textdocument = {
                        background: textdocument.background,
                        geometry: getGeometry(textdocument.geometry,temp),
                        widthM: textdocument.geometry.width,
                        heightM: textdocument.geometry.height,
                        offsetXM: textdocument.geometry.x,
                        offsetYM: textdocument.geometry.y,
                        rotation: {
                            angle:textdocument.rotation.angle||0
                        },
                        current: 'textdocument',
                        levels: angular.copy(textdocument.levels),
                        levelI: n,
                        pageI: index,
                        lock: true,
                        transparency: textdocument.transparency,
                        movable: textdocument.movable,
                        editable: textdocument.editable,
                        scale:textdocument.geometry.width/obj.mediabox.width
                    }

                    var backg = temp.levels.level[n].textdocument.background;
                    var pageW = temp.levels.level[n].textdocument.geometry.width,
                        pageH = temp.levels.level[n].textdocument.geometry.height;
                    if(backg.resource.identifier){
                        getBackgroundSource($http, backg.resource,pageW,pageH,backg)
                        backg.offsetx = unitTransform(backg.offsetx)* temp.styleScale
                        backg.offsety = unitTransform(backg.offsety)* temp.styleScale
                        backg.offsetXM = angular.copy(backg.offsetx);
                        backg.offsetYM = angular.copy(backg.offsety);
                    }

                    angular.forEach(textdocument.levels.level, function (lev, l) {
                        if (lev.imageart && lev.imageart.geometry) {
                            var art = lev.imageart;
                            $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                temp.levels.level[n].textdocument.levels.level[l].imageart = {
                                    geometry: getGeometry(art.geometry,temp),
                                    rotation: angular.copy(art.rotation),
                                    type: art.type,
                                    resource: {
                                        identifier:art.resource.identifier,
                                        provider:art.resource.provider,
                                        width:data.width,
                                        height:data.height
                                    },
                                    scale:art.scale,
                                    widthM: art.geometry.width,
                                    heightM: art.geometry.height,
                                    XM: art.geometry.x,
                                    YM:art.geometry.y,
                                    offsetXM: angular.copy(art.offsetx),
                                    offsetYM: angular.copy(art.offsety),
                                    offsetx: unitTransform(art.offsetx)* temp.styleScale,
                                    offsety: unitTransform(art.offsety)* temp.styleScale,
                                    current: 'imageart',
                                    levelI: n,
                                    pageI: index,
                                    lock: false,
                                    transparency: art.transparency,
                                    onlyshow: art.onlyshow,
                                    movable: art.movable,
                                    editable: art.editable,
                                }
                            }).error(function (error) {});
                        }
                        if (lev.textbox && lev.textbox.geometry) {
                            var text = lev.textbox;
                            temp.levels.level[n].textdocument.levels.level[l].textbox = {
                                geometry: getGeometry(text.geometry, temp),
                                identifier: text.identifier,
                                rotation: angular.copy(text.rotation),
                                angle: angular.copy(text.angle),
                                status: angular.copy(text.status),
                                style: angular.copy(text.style),
                                type: angular.copy(text.type),
                                text: angular.copy(text.text),
                                fontStyle: [{
                                    style: '',
                                    path: text.identifier
                                }],
                                widthM: text.geometry.width,
                                heightM: text.geometry.height,
                                offsetXM: text.geometry.x,
                                offsetYM: text.geometry.y,
                                current: 'textbox',
                                levelI: n,
                                pageI: index,
                                lock: true,
                                transparency: text.transparency,
                                lineheight: text.style.lineheight,
                                letterspacing: text.style.letterspacing,
                                fontuuid:text.fontuuid,
                                version:text.version
                            }
                            getFontCSS(FontManagement,text.fontuuid, $scope);
                            // toImg($scope.template.pages.page[i].levels.level[n].textbox)
                        }
                    })
                }
            });
            //背景图片
            if($scope.template.pages.page[index].background.resource.identifier){

                $http({url: $scope.template.pages.page[index].background.resource.identifier+'?imageInfo'}).success(function (data) {
                    var bgImgW = data.width;
                    var bgImgH = data.height;

                    var bgImgR = bgImgW/bgImgH;
                    var bgImgR1 = $scope.template.pages.page[index].width/$scope.template.pages.page[index].height;
                    if(bgImgR >= bgImgR1){
                        $scope.template.pages.page[index].background.adaptation = 'Height';
                    }else {
                        $scope.template.pages.page[index].background.adaptation = 'Width';
                    }
                }).error(function (error) {
                    MessageService.error("获取图片信息失败");
                });
            }
            var w =(unitTransform(document.scene.geometry.width) * $scope.template.pages.page[index].styleScale);
            $scope.template.pages.page[index].ratio = w  / (document.scene.geometry.width / 25.4 * vm.windowDpi)
            $scope.isLeftRotation = false;
            angular.forEach($scope.template.pages.page[0].levels.level, function (image) {
                if(image.imagebox){
                    imgScale(image.imagebox, 0, 0, image.imagebox.maskUrl, image.imagebox.image.offsetx, image.imagebox.image.offsety);
                }
            })
        };


        //mm转px
        function transitionPx(item) {
            return item * vm.windowDpi / 25.4;
        }
        //px转mm
        function transitionMm(item) {
            return item * 25.4 / vm.windowDpi;
        }
        /**
         * 摆台宽高坐标从 mm 换算成 px
         * @param obj
         * @param index
         * @returns {{height: number, width: number, x: number, y: number}}
         */
        function setGeometry(obj) {
            $scope.template.scene.styleScale = (unitTransform(obj.width) / ($scope.dWidth - 45));
            return {
                height: unitTransform(obj.height) / $scope.template.scene.styleScale,
                width: $scope.dWidth - 45,
                x: 0,
                y: 0
            };
        }
        function setGeometry1(obj) {
            return {
                height: unitTransform(obj.height) / $scope.template.scene.styleScale,
                width: unitTransform(obj.width) / $scope.template.scene.styleScale,
                x: unitTransform(obj.x) / $scope.template.scene.styleScale,
                y: unitTransform(obj.y) / $scope.template.scene.styleScale
            };
        }

        /**
         * 宽高坐标从 mm 换算成 px
         * @param obj
         * @param index
         * @returns {{height: number, width: number, x: number, y: number}}
         */
        function getGeometry(obj, temp) {
            return {
                height: unitTransform(obj.height) * temp.styleScale,
                width: unitTransform(obj.width) * temp.styleScale,
                x: unitTransform(obj.x) * temp.styleScale,
                y: unitTransform(obj.y) * temp.styleScale
            }
        }

        /**
         * 占位框宽高适配，进行canvas操作
         * @param images    占位框
         * @param index     第N页page
         * @param mask      蒙版图形
         * @param x         图片坐标x
         * @param y         图片坐标y
         */
        function imgScale(images, pageI, index, mask, x, y, flag) {
            var scaleW = 1, scaleH = 1;
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = images.imgUrl;

            newImg.onload = function () {
                newImg.onload = null;
                var w = newImg.width;
                var h = newImg.height;
                var maskWidth = images.geometry.width;
                var maskHight = images.geometry.height;
                if ((newImg.width <= newImg.height || maskWidth <= maskHight) && (newImg.width / newImg.height < maskWidth / maskHight) || newImg.width >= newImg.height && maskWidth >= maskHight && (newImg.width / newImg.height < maskWidth / maskHight)) {
                    newImg.width = maskWidth;
                    newImg.height = maskWidth * h / w;
                } else {
                    newImg.height = maskHight;
                    newImg.width = maskHight * w / h;
                }

                scaleW = newImg.width / w;
                scaleH = newImg.height / h;
                x = x / scaleW;
                y = y / scaleH;

                var img = images.imgUrl;
                if (images.bokehtype == 'Transparent') {
                    maskd3(img, images.imgUrl, images, function (src) {
                        images.blobSmall = src;
                        dropPicFinished(pageI,index);
                    });

                } else if (images.bokehtype == 'Blurred') {
                    maskd3(img, images.imgUrl, images, function (src) {
                        images.blobSmall = src;
                        $scope.$digest();
                    });

                    img = img + '/blur/40x1000';
                    maskd(img, images.maskUrl, 'source-in', x, y, maskWidth, maskHight, scaleW, scaleH, images.image.scale, function (src) {
                        images.blobUrl = src;
                        dropPicFinished(pageI,index);
                    });

                } else {
                    maskd(img, images.maskUrl, 'source-in', x, y, maskWidth, maskHight, scaleW, scaleH, images.image.scale, function (src) {
                        images.blobUrl = src;
                        dropPicFinished(pageI,index);
                    });
                }

            };
            newImg.onerror = function () {
                images.imgUrl = images.image.resource.identifier + '?imageMogr2/strip';
                if (images.image.angle != 0) {
                    images.imgUrl += '/rotate/' + images.image.angle
                }

                if (!flag) {
                    imgScale(images, pageI, index, mask, x, y, true);
                } else {
                    showPage();
                    $(".templateView-loading").addClass('hide1');
                    $(".background").show();
                }

            }
        }


        function dropPicFinished(pageI,index) {
            $scope.$apply(function () {
                if (index == pageI) {
                    showPage();
                }
                if (vm.bindingtype == 'OTHER' || $scope.template.pages.page.length < 2) {
                    showPage();
                }
                $(".templateView-loading").addClass('hide1');
                $(".background").show();
            });
        }

        function maskd3(img, src, imagebox, callback) {
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');
            var geo = imagebox.geometry;
            var blurredRec = imagebox.blurredrectangle;

            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                newImg.width = blurredRec.width;
                newImg.height = blurredRec.height;
                var masks = new Image();
                masks.crossOrigin = "Anonymous";
                masks.src = imagebox.maskUrl;

                masks.onload = function () {
                    masks.width = geo.width;
                    masks.height = geo.height;
                    imagecanvas.width = geo.width;
                    imagecanvas.height = geo.height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, geo.width, geo.height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.drawImage(newImg, blurredRec.x, blurredRec.y, blurredRec.width, blurredRec.height);

                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
            };
        }

        function showPage() {
            $scope.loading = false;
            $(".page-loading").hide();
            $li.show();
            $load.hide();
            $flagBox.show();
        }

        var getPixelRatio = function (context) {
            var backingStoreRatio = context.webkitBackingStorePixelRatio ||
                context.mozBackingStorePixelRatio ||
                context.msBackingStorePixelRatio ||
                context.oBackingStorePixelRatio ||
                context.backingStorePixelRatio || 1;

            return (window.devicePixelRatio || 1) / backingStoreRatio;
        };

        function maskd(src, maskUrl, attr, x0, y0, width, height, scaleW, scaleH, scale, callback) {
            var imagecanvas = document.createElement('canvas');
            var imagecontext = imagecanvas.getContext('2d');

            var ratio = getPixelRatio(imagecontext);
            imagecanvas.style.width = width + "px";
            imagecanvas.style.height = height + "px";
            imagecanvas.width = width * ratio;
            imagecanvas.height = height * ratio;
            imagecontext.scale(ratio, ratio);

            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.width = width;
            newImg.height = height;
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                var masks = new Image();
                masks.crossOrigin = "Anonymous";
                masks.onload = function () {
                    masks.onload = null;
                    masks.width = width;
                    masks.height = height;
                    imagecanvas.width = width;
                    imagecanvas.height = height;

                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = attr;

                    imagecontext.scale(scaleW, scaleH);
                    imagecontext.scale(scale, scale);

                    imagecontext.drawImage(newImg, x0, y0);


                    if (!callback) {
                        return;
                    }
                    callback.call(this, ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                    imagecanvas = null;
                };
                masks.src = maskUrl;
            };
        }

        function toImg(text, scale) {
            text.geometry.width /= scale;
            text.geometry.height /= scale;

            $http({
                url: '/editor/fileserves/api/convert/textToImg',
                method: 'POST',
                data: {
                    identifier: text.identifier,
                    text: text.text,
                    fontSize: text.style.size,
                    style: text.styles,
                    height: text.geometry.height,
                    width: text.geometry.width,
                    color: text.style.color,
                    family: text.style.font,
                    storeId: vm.storeId
                },
                responseType: 'blob'
            }).success(function (res, status, headers, config) {
                if(res.status == 200){
                    text.geometry.width = text.geometry.width * scale;
                    text.geometry.height = text.geometry.height * scale;

                    var createObjectURL = function (blob) {
                        return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
                    };
                    var fileURL = URL.createObjectURL(res.data);

                    text.picCode = text.pic = fileURL;
                    text.identifier = text.identifier || $scope.defaultText.path;
                }else {
                    MessageService.error(res.message);
                }
            }).error(function () {
                console.log("textToImg error");
            });
        }

        //旋转屏幕
        // window.addEventListener('orientationchange', function (event) {
        //     setReviewDoc();
        // });
        window.addEventListener('resize', function (event) {
            var status = localStorage.getItem('Production');
            if(status=='make'){
                return
            }
            if (vm.bindingtype == 'OTHER' || $scope.template.pages.page.length < 2) {
                return
            }
            // alert(window.location.href);
            if(window.location.href.indexOf('choose')!=-1){
                return
            }

            if (window.orientation == 0) {
                $rootScope.orientations = false;
            }
            setReviewDoc();
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $li.width($scope.dWidth);
            $rootScope.orientation = false;
        } else {
            $rootScope.orientations = true;
        }

        function setReviewDoc() {
            $(".save-page").removeClass('hide1');
            $(".view2-article").addClass('hide1');
            $(".share-wrap").addClass('hide1');
            setTimeout(function () {
                if (window.orientation == 180 || window.orientation == 0) {
                    $rootScope.orientations = true;
                    orientationChange();

                } else if (window.orientation == 90 || window.orientation == -90) {
                    $rootScope.orientations = false;
                    orientationChange();
                }
            }, 500);
        }

        function orientationChange() {
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(".back-to-customer").width($scope.dWidth);
            $li.width($scope.dWidth);
            var newDoc = angular.copy(entity.data.document);
            getAll(newDoc);
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                $(".view2-article").removeClass('hide1');
                $(".share-wrap").removeClass('hide1');
            }, 1500);
        }

        $scope.toggle = {
            now: false
        };

        vm.returnurl = localStorage.getItem('returnurl');
        vm.returnurl1 = localStorage.getItem('returnurl1');
        vm.returnurl2 = localStorage.getItem('returnurl2');
        vm.source = localStorage.getItem('source');
        $scope.goToCustomer = function () {
            if(vm.source){
                uni.redirectTo({
                    url: vm.returnurl1
                })
                return
            }
            window.location.href = vm.returnurl1;
        };

        $scope.gotoOrder = function(){
            if(vm.source){
                uni.redirectTo({
                    url: vm.returnurl2
                })
                return
            }
            window.location.href = vm.returnurl2;
        }

        $scope.editWork = function () {
            window.removeEventListener("resize", setReviewDoc, false);
            if(vm.article.editorType == "PHOTO_EDITOR"){
                $state.go('idPhotoEdit', {storeId: vm.storeId, aid: vm.article.id}, {reload: true});
            }else{
                $state.go('product-choosesThree-ec', {storeId: vm.storeId, aid: vm.article.id}, {reload: true});
            }
            localStorage.setItem("Production", "view");
            //清除关于作品的localStorage
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("gallery");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("reviewPages");
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("saveOldPageIndex");
            localStorage.removeItem("pageAllPx");
            localStorage.removeItem("pageAllMm");
            localStorage.removeItem("view-scrollTop");
            localStorage.removeItem("work-save");
            localStorage.removeItem("delPic");
            localStorage.removeItem("picArrChoose");
            localStorage.removeItem("picArrChoose2");
            localStorage.removeItem("gallery2");
            localStorage.removeItem("picArrCopy");
            localStorage.removeItem("picArr");
        };

        $scope.deleteWork = function () {
            swal({
                title: $translate.instant("editorCommon.describe11"),
                text: $translate.instant("editorCommon.describe12"),
                imageUrl: Theme.sweetHintImg(),
                showCancelButton: true,
                confirmButtonText: $translate.instant("button.confirm"),
                cancelButtonText: $translate.instant("button.cancel"),
                closeOnConfirm: false
            }, function () {
                Article.delete({
                    id: vm.article.id
                }, function (res) {
                    if(res.status == 200){
                        swal({
                            title: $translate.instant("editorCommon.deleteSuccess"),
                            text: "",
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 2000,
                            showConfirmButton: false
                        }, function () {
                            if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                                swal({
                                    title: $translate.instant("editorCommon.describe13"),
                                    text: $translate.instant("editorCommon.describe14"),
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                            }
                            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/article';
                        });
                    }else {
                        MessageService.error(res.message);
                    }
                }, function () {
                    console.log("error")
                });

            });
        };

        $scope.closeEditMenu = function () {
            if ($scope.showEditMenu) {
                // $scope.showEditMenu = false;
            }
        };

        $scope.toggleEditMenu = function (event) {
            $scope.selectMenuShow = false;
            event.stopPropagation();
            $('.edit-menu1').removeClass('hide1');
            $scope.showEditMenu = !$scope.showEditMenu;
        };

        $scope.addToCart = function () {
            $scope.selectMenuShow = true;
            $scope.showEditMenu = false;
            $scope.action = $scope.action_addCart;
        };

        $scope.addCart = function () {
            if (!vm.itemValue.productSaleable || vm.itemValue.cancelled || vm.itemValue.productCancelled) {
                swal({
                    title: $translate.instant("editorCommon.describe15"),
                    text: $translate.instant("editorCommon.describe16"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            } else if (vm.article.articleStatus == 'EDITING') {
                swal({
                    title: $translate.instant("editorCommon.describe15"),
                    text: $translate.instant("editorCommon.describe16"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            } else {
                Cart.addGoods({
                    articleId: vm.article.id,
                    price: $scope.price,
                    quantity: $scope.quantity,
                    copy: $scope.copy
                }, function (res) {
                    if(res.status == 200){
                        if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                            swal({
                                title: $translate.instant("editorCommon.describe13"),
                                text: $translate.instant("editorCommon.describe14"),
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }
                        swal({
                            title: $translate.instant("editorCommon.shoppingSuccess"),
                            text: "",
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 1500,
                            showConfirmButton: false
                        }, function () {
                            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/cart';
                        });
                    }else {
                        MessageService.error(res.message);
                    }
                }, function () {
                })
            }

        };

         $scope.purchase = function () {
            if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                swal({
                    title: $translate.instant("editorCommon.describe13"),
                    text: $translate.instant("editorCommon.describe14"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            }
            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/' + vm.article.id + '/' + $scope.copy + '/new2';
        };

        $scope.orderNow = function () {
            window.location.href = "https://avatar-demo.fotavatar.com";
            return
            $scope.selectMenuShow = false;
            $scope.showEditMenu = false;
            $scope.action = $scope.action_orderNow;

            if (!vm.itemValue.productSaleable || vm.itemValue.cancelled || vm.itemValue.productCancelled) {
                swal({
                    title: $translate.instant("editorCommon.describe15"),
                    text: $translate.instant("editorCommon.describe16"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            } else if (vm.article.articleStatus == 'EDITING') {
                swal({
                    title: $translate.instant("editorCommon.describe15"),
                    text: $translate.instant("editorCommon.describe16"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }

            $scope.purchase();
        };

        $scope.closeModal = function () {
            $scope.selectMenuShow = false;
            $scope.showEditMenu = false;
        };


        var audioTest = null;
        $scope.openMusicList = function () {
            $scope.musicPage = true;
            audio.pause();
            vm.musicIdentify = vm.article.musicIdentify;
            if(vm.article.musicIdentify){
                angular.forEach($scope.Musics,function (music,i) {
                    if(music.identify== vm.article.musicIdentify){
                        $(".music-current").hide();
                        $(".music-current"+i).show();
                        setMusicStyle(i)
                    }
                })
            }

            audioTest = document.getElementById("audition");
            audioTest.crossOrigin = 'anonymous';
        };

        $scope.playList = function (identify,index) {
            // audioTest.pause();
            audioTest.src = $scope.domain + identify;
            vm.musicIdentify = identify;
            audioTest.play();
            setMusicStyle(index);
            Play()
        };

        function setMusicStyle(index) {
            $(".music-name").removeClass('music-active');
            $(".music-name"+index).addClass('music-active');
            $(".music-icon").hide();
            $(".music-icon"+index).show();

        }

        $scope.backTo = function () {
            $scope.musicPage = false;
            audioTest.pause();
            audio.play();
        };

        $scope.saveMusic = function () {
            if (vm.musicIdentify == null || vm.musicIdentify == "") {
                $scope.musicPage = false;
             }else{
                Article.updateMusic({id:vm.article.id, musicIdentify:vm.musicIdentify},function(res){
                    if(res.status == 200){
                        vm.article = res.data;
                        $scope.musicPage = false;
                        audio.src = $scope.domain + vm.article.musicIdentify;
                        audioTest.pause();
                        audio.play();
                    }else {
                        MessageService.error(res.message);
                    }
                },function(){

                })
             }

        }
    }
})();
