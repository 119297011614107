(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductViewEcController', ProductViewEcController);

    ProductViewEcController.$inject = ['$scope', '$stateParams', 'entity', '$q', '$localStorage', 'Qinius', '$state', 'Cart', 'CommonService', 'Store',
        'StoreConfig', '$rootScope', 'Theme', 'ItemValue', 'PlatformWechatSetting', 'Article', 'Music', 'MessageNotification', 'StoreWorkEditConfig',
        'ModulesService', 'Convert', '$translate','Product'];
    function ProductViewEcController($scope, $stateParams, entity, $q, $localStorage, Qinius, $state, Cart, CommonService, Store,
                                   StoreConfig, $rootScope, Theme, ItemValue, PlatformWechatSetting, Article, Music, MessageNotification, StoreWorkEditConfig,
                                     ModulesService, Convert, $translate,Product) {
        var vm = this;

        CommonService.setDocumentTitle('预览');
        $scope.orderFlag = localStorage.getItem('returnurl2Text') || $translate.instant("button.buttonText");

        //风格切换  引入不同的css文件
        Theme.judge();
        vm.platformSetting = PlatformWechatSetting.get();
        var audio = document.getElementById("audio");
        audio.crossOrigin = 'anonymous';

        vm.orderOrigin = localStorage.getItem('orderOrigin');

        var returnurl = localStorage.getItem('returnurl');
        vm.returnurl = localStorage.getItem('returnurl');
        vm.returnurl1 = localStorage.getItem('returnurl1');
        vm.returnurl2 = localStorage.getItem('returnurl2');
        vm.source = localStorage.getItem('source');

        var timer = null;
        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.aid = $stateParams.aid;
        vm.orderArticleId = $stateParams.orderArticleId;
        Store.get({id: vm.storeId}, function (res) {
            if(res.status == 200){
                vm.store = res.data;
            }else {
                MessageService.error(res.message);
            }
        });
        vm.article = entity.data;
        vm.bindingtype = 'Left';
        vm.counts = 0;
        vm.isHideMusicBtn = false;

        StoreWorkEditConfig.storeId({storeId: vm.storeId}, function (res) {
            if(res.status == 200){
                $scope.orderFlag = localStorage.getItem('returnurl2Text') || res.data.buttonText;
            }else{
                swal.showInputError(res.message);
            }
        });
        //预览引导页
        var tt = localStorage.getItem('guide-share');
        if (tt == 'true') {
            vm.share = true;
        } else if (tt == 'false' || tt == '' || tt == null) {
            vm.share = false;
        }
        $scope.nextStep = function () {
            vm.share = true;
            localStorage.setItem('guide-share', vm.share);
        };
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            // vm.windowDpiW = 300;
            // vm.windowDpiH = 300;
            return arrDPI;
        }

        $scope.action_orderNow = 'orderNow';
        $scope.quantity = 1;
        $scope.copy = 1;
        $scope.hideEdit = vm.article.id;
        $scope.price = 0;
        $scope.k = 0;
        $scope.musicPage = false;
        $scope.viewPage = false;
        $scope.isRandomView = false;
        $scope.dHeight = vm.dHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        $scope.dWidth = vm.dWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});

        var $li = $(".templateView2-carousel");
        $scope.domains = Qinius.getDomain({storeId: vm.storeId});

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
                console.log(error);
            });
        }

        var rot = 10 - 0;

        function begin(time) {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                audio.play();
                Play();
                $("#Play").children("img").removeClass("play").addClass("pause");
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        var monthStr = new Date().getMonth() + 1;
        var dayStr = new Date().getDate();
        var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
        MessageNotification.getAllByCondition2({
            items: [{key: "available", op: "=", value: "true"}, {
                key: "validate",
                op: ">=",
                value: timestr
            }, {key: "pageType", op: "=", value: "View"}]
        }, function (res) {
            if(res.status == 200){
                res.data.sort(compare('seq'))
                vm.message = angular.copy(res.data);
                vm.messageText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                angular.forEach(res.data, function (resp) {
                    vm.messageText += resp.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                })
            }else {
                MessageService.error(res.message);
            }
        });

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        getAll();
        function getAll() {
            $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise]).then(function () {
                if(vm.article.invalid){//作品失效
                    $state.go('articlesFailure',{storeId:vm.storeId});
                    return
                }
                vm.platformSetting = vm.platformSetting.data;
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                Product.get({id: vm.article.productId}, function (resP) {
                    Convert.xmlToDocument2({
                        identifier: vm.article.fileName,
                        provider: 'qiniu',
                        editorType:vm.article.editorType,
                        patternType:resP.data.patternType,
                    }, function (resp) {
                        vm.documentPage = resp;
                    })
                })
                ItemValue.get({id: vm.article.itemValueId}, function (res) {
                    if(res.status == 200){
                        vm.itemValue = res.data;
                        console.log(vm.itemValue)
                        $scope.price = res.data.price;
                        if (vm.article.musicIdentify) {
                            audio.src = $scope.domain + vm.article.musicIdentify;
                        } else {
                            if (res.data.musicIdentify) {
                                audio.src = $scope.domain + res.data.musicIdentify;
                            } else {
                                audio.src = '';
                                vm.isHideMusicBtn = true;
                            }
                        }

                        // autoPlayAudio2();
                    }else {
                        MessageService.error(res.message);
                    }
                });
                Play();
                Music.allByCondition({
                    items: [],
                    sort: ["id,asc"]
                }, function (resp) {
                    if(resp.status == 200){
                        $scope.Musics = resp.data;
                    }else {
                        MessageService.error(resp.message);
                    }
                });
                //获取自定义颜色
                ModulesService.getToneSettings(vm.storeId,function (res) {
                    vm.toneSettings = res;
                });
            });
        }

        //旋转屏幕
        window.addEventListener('resize', function (event) {
            var status = localStorage.getItem('Production');
            if (status == 'make') {
                return
            }
            // alert(window.location.href);
            if (window.location.href.indexOf('choose') != -1) {
                return
            }

            if (vm.bindingtype == 'None' || $scope.template.pages.page.length < 2) {
                return
            }

            if (window.orientation == 0) {
                $rootScope.orientations = false;
            }
            setReviewDoc();
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $li.width($scope.dWidth);
            $rootScope.orientation = false;
        } else {
            $rootScope.orientations = true;
        }

        function setReviewDoc() {
            $(".save-page").removeClass('hide1');
            $(".view2-article").addClass('hide1');
            $(".share-wrap").addClass('hide1');
            setTimeout(function () {
                if (window.orientation == 180 || window.orientation == 0) {
                    $rootScope.orientations = true;
                    orientationChange();

                } else if (window.orientation == 90 || window.orientation == -90) {
                    $rootScope.orientations = false;
                    orientationChange();
                }
            }, 500);
        }

        function orientationChange() {
            $scope.k = 0;
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(".back-to-customer").width($scope.dWidth);
            $li.width($scope.dWidth);
            // var newDoc = angular.copy(entity.document);
            getAll();
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                $(".view2-article").removeClass('hide1');
                $(".share-wrap").removeClass('hide1');
            }, 1500);
        }

        $scope.toggle = {
            now: false
        };

        $scope.goToCustomer = function () {
            if(vm.source){
                uni.redirectTo({
                    url: vm.returnurl1
                })
                return
            }
            window.location.href = vm.returnurl1;
        };

        $scope.gotoOrder = function(){
            if(vm.source){
                uni.redirectTo({
                    url: vm.returnurl2
                })
                return
            }
            window.location.href = vm.returnurl2;
        }

        $scope.editWork = function () {
            window.removeEventListener("resize", setReviewDoc, false);
            $state.go('editorPhotoAlbumEditM', {storeId: vm.storeId, aid: vm.article.id}, {reload: true});

            localStorage.setItem("Production", "view");
            //清除关于作品的localStorage
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("gallery");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("reviewPages");
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("saveOldPageIndex");
            localStorage.removeItem("pageAllPx");
            localStorage.removeItem("pageAllMm");
            localStorage.removeItem("view-scrollTop");
            localStorage.removeItem("work-save");
            localStorage.removeItem("delPic");
            localStorage.removeItem("picArrChoose");
            localStorage.removeItem("picArrChoose2");
            localStorage.removeItem("gallery2");
            localStorage.removeItem("picArrCopy");
            localStorage.removeItem("picArr");
        };

        $scope.deleteWork = function () {
            swal({
                title: $translate.instant("editorCommon.describe11"),
                text: $translate.instant("editorCommon.describe12"),
                imageUrl: Theme.sweetHintImg(),
                showCancelButton: true,
                confirmButtonText: $translate.instant("button.confirm"),
                cancelButtonText: $translate.instant("button.cancel"),
                closeOnConfirm: false
            }, function () {
                Article.delete({
                    id: vm.article.id
                }, function (res) {
                    if(res.status == 200){
                        swal({
                            title: $translate.instant("editorCommon.deleteSuccess"),
                            text: "",
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 2000,
                            showConfirmButton: false
                        }, function () {
                            if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                                swal({
                                    title: $translate.instant("editorCommon.describe13"),
                                    text: $translate.instant("editorCommon.describe14"),
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                            }
                            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/article';
                        });
                    }else {
                        MessageService.error(res.message);
                    }
                }, function () {
                    console.log("error")
                });

            });
        };

        $scope.toggleEditMenu = function (event) {
            $scope.selectMenuShow = false;
            event.stopPropagation();
            $('.edit-menu1').removeClass('hide1');
            $scope.showEditMenu = !$scope.showEditMenu;
        };

        $scope.purchase = function () {
            if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                swal({
                    title: $translate.instant("editorCommon.describe13"),
                    text: $translate.instant("editorCommon.describe14"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            }
            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/' + vm.article.id + '/' + $scope.copy + '/new2';
        };

        $scope.orderNow = function () {
            if(vm.source){
                uni.redirectTo({
                    url: vm.returnurl2
                })
                return
            }
            window.location.href = vm.returnurl2;
            return
            $scope.selectMenuShow = false;
            $scope.showEditMenu = false;
            $scope.action = $scope.action_orderNow;
            $scope.copy = 1;
            if (!vm.itemValue.productSaleable || vm.itemValue.cancelled || vm.itemValue.productCancelled) {
                swal({
                    title: $translate.instant("editorCommon.describe15"),
                    text: $translate.instant("editorCommon.describe16"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            } else if (vm.article.articleStatus=='NEW'||vm.article.articleStatus=='EDITING') {
                swal({
                    title: $translate.instant("editorCommon.describe15"),
                    text: $translate.instant("editorCommon.describe16"),
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }
            $scope.purchase();
        };

        var audioTest = null;
        $scope.openMusicList = function () {
            $scope.musicPage = true;
            audio.pause();
            vm.musicIdentify = vm.article.musicIdentify;
            if (vm.article.musicIdentify) {
                angular.forEach($scope.Musics, function (music, i) {
                    if (music.identify == vm.article.musicIdentify) {
                        $(".music-current").hide();
                        $(".music-current" + i).show();
                        setMusicStyle(i)
                    }
                })
            }

            audioTest = document.getElementById("audition");
            audioTest.crossOrigin = 'anonymous';
        };

        $scope.playList = function (identify, index) {
            // audioTest.pause();
            audioTest.src = $scope.domain + identify;
            vm.musicIdentify = identify;
            audioTest.play();
            setMusicStyle(index);
        };

        function setMusicStyle(index) {
            $(".music-name").removeClass('music-active');
            $(".music-name" + index).addClass('music-active');
            $(".music-icon").hide();
            $(".music-icon" + index).show();
        }

        $scope.backTo = function () {
            $scope.musicPage = false;
            audioTest.pause();
            audio.play();
        };

        $scope.saveMusic = function () {
            if (vm.musicIdentify == null || vm.musicIdentify == "") {
                $scope.musicPage = false;
            } else {
                Article.updateMusic({id: vm.article.id, musicIdentify: vm.musicIdentify}, function (res) {
                    if(res.status == 200){
                        vm.article = res.data;
                        $scope.musicPage = false;
                        audio.src = $scope.domain + vm.article.musicIdentify;
                        audioTest.pause();
                        audio.play();
                    }else {
                        MessageService.error(res.message);
                    }
                }, function () {

                })
            }

        }
    }
})();
