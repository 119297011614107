(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('PreviewController', PreviewController);

    PreviewController.$inject = ['$state', 'AuthServerProvider', '$stateParams', '$localStorage','Article','OrderArticle'];

    function PreviewController($state, AuthServerProvider, $stateParams, $localStorage, Article,OrderArticle) {
        var vm = this;
        $(".navbar").hide();
        $(".wrap").show().addClass('no-margins').css({height: '100vh', background: '#fff'});
        // {storeId}/{pid}/{vid}/{aid}
        var storeId = $stateParams.storeId;
        var pid = $stateParams.pid;
        var vid = $stateParams.vid;
        var aid = $stateParams.aid;
        var orderArticleId = $stateParams.orderArticleId;
        $localStorage.article = {id:aid};
        if($stateParams.authorization){
            $localStorage.authenticationToken = $stateParams.authorization;
        }

        //对escape()编码的字符串进行解码
        function getQueryString(name) {
            var reg = new RegExp("(^|)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.href.match(reg);
            if (r != null) {
                return window.decodeURIComponent(r[2]);
            }
            return null;//如果此处只写return;则返回的是undefined
        };
        var str = getQueryString("returnurl");
        var str1 = getQueryString("returnurl1");
        var str2 = getQueryString("returnurl2");
        var returnurl2Text = getQueryString("returnurl2Text");
        var source = getQueryString("source");
        if(str){
            localStorage.setItem('returnurl', str);
        }else {
            localStorage.removeItem('returnurl')
        }
        if(str1){
            localStorage.setItem('returnurl1', str1);
        }else {
            localStorage.removeItem('returnurl1')
        }
        if(str2){
            localStorage.setItem('returnurl2', str2);
        }else {
            localStorage.removeItem('returnurl2')
        }
        if(returnurl2Text){
            localStorage.setItem('returnurl2Text', returnurl2Text);
        }else {
            localStorage.removeItem('returnurl2Text')
        }
        if(source){
            localStorage.setItem('source', source);
        }else {
            localStorage.removeItem('source')
        }

        var pathNames = window.location.pathname.split("/");
        var lastName = pathNames[pathNames.length - 1];
        console.log(lastName)
        // var screenW = document.body.clientWidth;
        function isMobile(){
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }
        var isMobile = isMobile();
        if ($stateParams.auth) {
            AuthServerProvider.storeAuthenticationToken($stateParams.auth, true);
            init();
        }
        else if ($stateParams.ticket) {
            getToken($stateParams.ticket, function(token) {
                if (token) {
                    AuthServerProvider.storeAuthenticationToken(token, true);
                    init();
                } else {
                    // illegal
                    console.error("Cannot open article with failure of getToken");
                }
            });
        } else {
            init();
            // illegal
            console.error("Cannot open article with nothing ticket or auth");
        }

        function getToken(ticket, callback) {
            Article.getToken({ticket: ticket}, function (res) {
                if (res.status == 200) {
                    var token = res.data;
                    if (token && callback) {
                        callback(token);
                    } else {
                        console.log("Invalid token or callback, token=" + token);
                    }
                } else {
                    console.log("Error to getToken with ticket: " + ticket + ", article=" + aid);
                }
            });
        }

        function init() {
            if(orderArticleId){
                if (!isMobile) {
                    $state.go("page-view2", {storeId: storeId, orderArticleId: orderArticleId});
                } else {
                    console.log(orderArticleId)
                    OrderArticle.get({id: orderArticleId},function(res){
                        var data = res.data;
                        document.title = data.productName;
                        if (data.editorType == "PICTURE" || data.editorType == "PICTURE_PRO") {
                            $state.go('editorPhotoPreview1', {storeId: storeId, orderArticleId: orderArticleId})
                        }
                        else if(data.editorType=='BOOK_JUNIOR'||data.editorType=='BOOK_SENIOR' || data.editorType=='NEW_EDITOR' || data.editorType=='CALENDAR_EDITOR') {
                            $state.go('product-view-ec-1', {storeId: storeId, orderArticleId: orderArticleId})
                        }else{
                            $state.go("product-view5-ec-1", {storeId: storeId, orderArticleId: orderArticleId});
                        }
                    })
                }
            }
            if(aid){
                Article.get({id: aid},function (res) {
                    var data = res.data;
                    document.title = data.productName;
                    if(res.data.invalid){//作品失效
                        $state.go('articlesFailure',{storeId:storeId});
                        return
                    }
                    if (!isMobile) {
                        //预览
                        if (lastName == 'articlePreview' || !lastName) {
                            $state.go("page-view", {storeId: storeId, aid: aid});
                        } else {
                            $state.go("page-view", {storeId: storeId, aid: aid});
                            // $state.go("page-view2", {storeId: storeId, orderArticleId: aid});
                        }
                    } else {
                        if (lastName == 'articlePreview') {
                            if (data.editorType == "PICTURE" || data.editorType == "PICTURE_PRO") {
                                $state.go('editorPhotoPreviewShare', {storeId: storeId, aid: aid})
                                return
                            }
                            if(data.editorType=='BOOK_JUNIOR'||data.editorType=='BOOK_SENIOR' || data.editorType=='NEW_EDITOR' || data.editorType=='CALENDAR_EDITOR') {
                                $state.go('product-view-ec', {storeId: storeId, aid: aid})
                            }else{
                                $state.go("product-view5-ec", {storeId: storeId, aid: aid});
                            }
                        } else {
                            if (data.editorType == "PICTURE" || data.editorType == "PICTURE_PRO") {
                                $state.go('editorPhotoPreviewShare', {storeId: storeId, aid: aid})
                                return
                            }
                            if(data.editorType=='BOOK_JUNIOR'||data.editorType=='BOOK_SENIOR' || data.editorType=='NEW_EDITOR' || data.editorType=='CALENDAR_EDITOR') {
                                $state.go("product-view2-ec", {storeId: storeId, aid: aid, viewIndex: 0});
                            }else{
                                $state.go("product-view5-ec", {storeId: storeId, aid: aid});
                            }
                        }
                    }
                })
            }
        }

        // var screenH = document.body.clientHeight;

    }
})();
